import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { errorNotificationHandler } from '@moxie/shared';
import { getDealApplications } from '@crm/libs/services.api/lib/deals.api';
import dot from 'dot-object';

export const useFetchDealApplication = (dealId: string, page: number) => {
  const params = {
    page,
    'filter.dealId': dealId
  }
  const query = useQuery({
    queryKey: ['deal-applications', dealId, page],
    queryFn: async () => getDealApplications(params),
    enabled: true,
    keepPreviousData: true,
    onError: () =>
      errorNotificationHandler('Error fetching deal applications.'),
  });
  return query;
};

export const useInfiniteFetchDealApplication = (dealId: string, filterParams?: Record<string, unknown>) => {
  dot.keepArray = true
  const sortBy = 'createdAt:DESC';
  const filter = {
    dealId,
    ...filterParams,
  }
  const modifiedFilters = (filter && Object.keys(filter).length) && dot.dot({ filter });
  const query = useInfiniteQuery({
    queryKey: ['deal-applications', dealId, modifiedFilters],
    queryFn: async (arg) => {
      const params = {
        page: arg.pageParam ?? 1,
        limit: 3,
        sortBy,
        ...modifiedFilters
      }
      return getDealApplications(params)
    },
    getNextPageParam: (lastPage: any) => {
      return lastPage.meta.currentPage === lastPage.meta.totalPages ? false : lastPage.meta.currentPage + 1;
    },
  });
  return query;
}
