import React from 'react';
import {
  Spin,
} from 'antd';
import { useInfiniteFetchDealApplication } from '../../../hooks/useFetchDealApplication';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ApplicationCard } from './application-card';

interface Props {
  canViewOrAddApplication: boolean;
  dealId: string;
}

export const ApplicationLists: React.FC<Props> = ({ dealId }) => {
  const { data, hasNextPage, fetchNextPage, isFetching, isLoading } = useInfiniteFetchDealApplication(dealId, {
    'status': "In Progress"
  })

  const applicationData = data?.pages.flatMap((page) => page.data) || [];
  const totalApplications = data?.pages[0]?.meta?.totalItems;

  if (totalApplications === 0) {
    return null;
  }

  return (
    <div className="modal-application-lists">
      <Spin spinning={isLoading} >
        <div id="scrollable-modal-row" >
          <InfiniteScroll
            hasMore={Boolean(hasNextPage)}
            next={fetchNextPage}
            dataLength={totalApplications ?? 0}
            loader={isFetching ? <Spin /> : null}
            scrollableTarget="scrollable-modal-row"
          >
            {applicationData.map((item) => (
              <ApplicationCard key={item?.id} data={item} />
            ))}
          </InfiniteScroll>
        </div >
      </Spin >
    </div >
  );
};
