import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { Redirect, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import {
  contactActions,
  themeActions,
  useAppSelector,
  userActions
} from '@crm/core';

import ApplicationStages from './sections/ApplicationStages';
import DetailSection from './sections/DetailSection';
import ApplicationDetailContext from './application-detail-context';

import { canViewContactStatus } from '@crm/services.api';
import { errorNotificationHandler } from '@moxie/shared';
import { CONTACT_RESPONSE_MESSAGE, URL_DASHBOARD } from '@moxie/constants';
import { ContactProfileCard } from '../contact-details/sections/contact-overview/components/contact-profile-card';
import OverviewTabs from '../contact-details/sections/contact-overview/components/overview-tabs';
import { ApplicationFieldsSection } from './sections/ApplicationFields';
import { LoaderComponent } from '@crm/src/shared/loader';

interface RenderStagesCardProps {
  showDetail: boolean;
}
const RenderStagesCard: React.FC<RenderStagesCardProps> = ({
  showDetail,
}) => {
  if (showDetail) {
    return <OverviewTabs />;
  }
  return <ApplicationStages />
};

const ApplicationDetails = () => {
  const {
    contactId,
  }: { contactId: string; applicationId: string } = useParams();
  const dispatch = useDispatch();
  const { applicationData, isLoadingApplication, setSelectedStage, refetchApplicationDetails } = useContext(ApplicationDetailContext);

  const contact = useAppSelector(store => store.contact.singleData);

  const [showDetail, setShowDetail] = useState(false);

  const [viewStatus, setViewStatus] = React.useState(true);

  const handleShowDetail = (status: boolean) => {
    setShowDetail(status);
  };

  useEffect(() => {
    if (applicationData) {
      if (!isLoadingApplication) {
        if (applicationData?.status.toLowerCase() === 'completed') {
          setSelectedStage({
            level: null,
            stage: null,
            wfAppStageId: null,
          });
        }
        else {
          setSelectedStage({
            ...applicationData?.activeStage,
            wfAppStageId: applicationData?.activeStageId,
          });
        }
      }
    }
  }, [applicationData, isLoadingApplication]);

  useEffect(() => {
    refetchApplicationDetails();
    dispatch(contactActions.getContactDetailsRequest(contactId));
  }, []);

  useEffect(() => {
    dispatch(themeActions.toggleNavbar(true));
    return () => {
      dispatch(themeActions.toggleNavbar(false));
    };
  }, []);


  useEffect(() => {
    let isMounted = true;

    canViewContactStatus(contactId).then((res) => {
      if (isMounted) {
        setViewStatus(res)
        if (!res) {
          errorNotificationHandler(CONTACT_RESPONSE_MESSAGE.NOT_AUTHORIZED_TO_VIEW)
        }
      }
    })

    return () => {
      isMounted = false;
    };
  }, [contactId])

  useEffect(() => {
    dispatch(userActions.fetchUsers());
    return () => {
      dispatch(userActions.clearUserFetch());
    };
  }, [showDetail]);

  if (isLoadingApplication) {
    return <LoaderComponent />
  }

  if (viewStatus) {
    return (
      <div
        className="application-details-container"
      >
        <Row className="height-full">
          <Col span={5}>
            <ContactProfileCard
              allowEdit={false}
              avatarSize="large"
              contact={contact}
              handleShowDetail={handleShowDetail}
              showDetail={showDetail}
            />
            <div
              className="application-contact-profile-wrapper"
            >
              <RenderStagesCard
                showDetail={showDetail}
              />
            </div>
          </Col>
          <Col span={14} className="border-1-px bg-white">
            <DetailSection />
          </Col>
          <Col span={5} className="border-1-px bg-white">
            <ApplicationFieldsSection />
          </Col>
        </Row>
      </div>
    );
  } else {
    return <Redirect to={URL_DASHBOARD} />
  }
};

export default ApplicationDetails;
