import {
  ASSIGNEE_LABEL,
  AUTH_MESSAGE,
  EMAIL_LABEL,
  EMAIL_PLACEHOLDER,
  FIRST_NAME_LABEL,
  FIRST_NAME_PLACEHOLDER,
  GUTTER,
  LAST_NAME_LABEL,
  LAST_NAME_PLACEHOLDER,
  PHONE_LABEL,
  regex
} from '@moxie/constants';
import { IContactCrm, IForm } from '@shared-components/models';
import { Col, Row } from 'antd';
import Form, { RuleObject } from 'antd/lib/form';
import { Input, PhoneInput } from 'libs/shared/src/shared';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { SearchBox } from 'libs/shared/src/crm-modules/shared';
import { queryAssignees } from '@crm/services.api';
import Select from 'antd/es/select';
import { UserNameCard } from '../contact-add-edit/user-name-card';
import { useAppSelector } from '@crm/core';

const LeadForm: React.FC<IForm<IContactCrm>> = ({
  form,
  onSubmit,
  isView,
  initialData,
  setDisabled,
  isEdit = false,
}: IForm<IContactCrm>) => {
  const initialCountryCode = initialData?.countryCode ?? '+61';

  const [phone, setPhone] = useState('');
  const [countryCode, setCountryCode] = useState<string | any>(initialCountryCode);

  const onFieldsChange = (allFields: string | any[]) => {
    if (allFields.length > 0) {
      for (const field of allFields) {
        field.errors.length < 1 ? setDisabled(false) : setDisabled(true);
      }
    }
  };

  const loggedUserPrimaryBranchId = useAppSelector(store => store.auth.user?.branchId)

  useEffect(() => {
    if (initialData?.id && isEdit) {
      initialData.dateOfBirth = changeDateToMoment(
        initialData?.dateOfBirth
      );
      initialData.dateOfBirth = changeDateToMoment(initialData.dateOfBirth);
      initialData.visaExpiryDate = changeDateToMoment(
        initialData.visaExpiryDate
      );
      initialData.passportExpiryDate = changeDateToMoment(
        initialData.passportExpiryDate
      );
      form.setFieldsValue(initialData);
      setDisabled(true);

      if (initialData?.assigneeId === null) {
        form.setFieldsValue({
          assigneeId: '',
        });
      } else {
        form.setFieldsValue({
          assigneeId: initialData?.assigneeId,
        });
      }
    }
  }, [isEdit, initialData, form, setDisabled]);

  const changeDateToMoment = (date?: string | Moment): Moment | undefined => {
    if (date) return moment(date);
    return;
  };

  const validatePhoneNumber = (_: RuleObject, value: string) => {
    const cleanedVal = value?.replace(/[()\s-]/g, '');
    if (value && !regex.LEAD_PHONE_NUMBER.test(cleanedVal)) {
      setDisabled(true)
      return Promise.reject('Please enter a valid phone number');
    }
    return Promise.resolve();
  };

  const handleSubmit = async (data: IContactCrm) => {
    let updateData: IContactCrm = {
      ...data,
      countryCode: data.phone ? countryCode : undefined,
    };
    if (!updateData.phone) {
      const { phone, ...restData } = updateData;
      updateData = restData;
    }
    updateData.email = updateData?.email?.replace(/ /g, '');
    if (updateData.assigneeId === "") {
      updateData.assigneeId = null;
    }
    onSubmit && onSubmit(updateData);
  };

  const onPhoneNumberChange = (val: string) => {
    let phoneNumber = val
    if (val.length >= 3) {
      phoneNumber = val.replace(/^0{1,}/g, '')
    }
    setPhone(phoneNumber);
    form.setFieldsValue({ phone: phoneNumber })
  }

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      scrollToFirstError={true}
      onFieldsChange={onFieldsChange}
    >
      <Row gutter={GUTTER}>
        <Col span={12}>
          <Form.Item
            name="firstName"
            label={FIRST_NAME_LABEL}
            validateFirst={true}
            rules={[
              {
                required: true,
                whitespace: true,
                message: 'Please enter First name',
              },
              {
                pattern: new RegExp(regex.NAME_REGEX),
                message: AUTH_MESSAGE.INVALID_FIRST_NAME,
              },
              { max: 50 },
            ]}
          >
            <Input
              data-testid="crm-lead-firstname"
              className="note-title-input"
              placeholder={FIRST_NAME_PLACEHOLDER}
              disabled={isView}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="lastName"
            label={LAST_NAME_LABEL}
            validateFirst={true}
            rules={[
              {
                required: true,
                whitespace: true,
                message: 'Please enter Last name',
              },
              {
                pattern: new RegExp(regex.NAME_REGEX),
                message: AUTH_MESSAGE.INVALID_LAST_NAME,
              },
              { max: 50 },
            ]}
          >
            <Input
              data-testid="crm-lead-lastname"
              className="note-title-input"
              placeholder={LAST_NAME_PLACEHOLDER}
              disabled={isView}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={GUTTER}>
        <Col span={12}>
          <Form.Item
            name="email"
            validateFirst={true}
            label={EMAIL_LABEL}
            rules={[
              {
                required: true,
                message: AUTH_MESSAGE.INVALID_EMAIL,
              },
              {
                pattern: new RegExp(regex.EMAIL),
                message: AUTH_MESSAGE.INVALID_EMAIL,
              },
            ]}
          >
            <Input
              data-testid="crm-lead-email"
              type="email"
              className="note-title-input"
              placeholder={EMAIL_PLACEHOLDER}
              disabled={isView}
            />
          </Form.Item>
        </Col>
        <Col span={12} className="product-filter-selection">
          <Form.Item
            data-testid="crm-lead-phone"
            name="phone"
            label={PHONE_LABEL}
            className="country_dial_code"
            validateTrigger={"onChange"}
            rules={[
              {
                validator: validatePhoneNumber,
              },
            ]}
          >
            <PhoneInput
              data-testid="phone"
              disabled={isView}
              dialCode={countryCode}
              onChangeDialCode={(val) => setCountryCode(val)}
              onChange={(e) => onPhoneNumberChange(e.target.value)}
              value={phone}
            />
          </Form.Item>
        </Col>
      </Row>
      {!isEdit ? (
        <Row gutter={GUTTER}>
          <Col span={24}>
            <div id="lead_assignee_id" className="relative">
              <Form.Item
                name="assigneeId"
                data-testid="crm-lead-assignee_user_id"
                label={ASSIGNEE_LABEL}
                required={true}
                initialValue={initialData?.assigneeId ?? ""}
              >
                <SearchBox
                  fetchQuery={queryAssignees}
                  hideDefaultOptionView
                  optionLabelProp="label"
                  valueKey="id"
                  filter={{
                    contactId: initialData?.id,
                    branches: [loggedUserPrimaryBranchId],
                  }}
                  defaultOpen={false}
                >

                  {
                    (user) => (
                      <Select.Option
                        value={user.id}
                        label={`${user.firstName} ${user.lastName}`}
                        className="padding-left-1"
                      >
                        <UserNameCard
                          firstName={user.firstName}
                          lastName={user.lastName}
                          email={user.email}
                          index={user.id}
                        />
                      </Select.Option>
                    )
                  }

                </SearchBox>
              </Form.Item>
            </div>
          </Col>
        </Row>
      ) : null}
    </Form>
  );
};

export { LeadForm };
