import React, { useState } from 'react';
import { Button, Row } from 'antd';
import { CommentOutlined, ApiOutlined } from '@ant-design/icons';
import ApplicationActivityLogDrawer from './application-activity-log-drawer';
import { useForm } from 'antd/lib/form/Form';
import { usePostContactApplicationComments } from '../hooks/use-contact-application-comments';
import { errorHandler } from '@moxie/utils';
import { ApplicationDetail } from '@model/application';
import ApplicationDetailContext from '../../../application-detail-context';
import { BulkDocumentUpload } from '@crm/src/pages/contact-details/sections/details/components/documents/contact-bulk-document-upload';
import { EducationProviderDrawer } from './education-provider-drawer';
import { useAppSelector } from '@crm/core';
import { useFetchProviderStatusList } from '@crm/src/pages/education-provider/hooks';
import { useHistory } from 'react-router-dom';
import Tooltip from 'antd/es/tooltip';
import Tag from 'antd/es/tag';

interface Props {
  application: ApplicationDetail;
  service?: string;
}

type ActiveDrawer = 'none' | 'activity-log' | 'education-provider';

const providerMethod = 'studylink';
const isProduction = !['development', 'staging', 'local'].includes(process.env.NODE_ENV as string)

const EnrollmentDetail: React.FC<Props> = ({ application, service }) => {
  const { id: applicationId } = application;

  const [activeDrawer, setActiveDrawer] = useState<ActiveDrawer>('none')
  const [selectedTab, setSelectedTab] = useState('activities');
  const [commentFormButton, setCommentFormButton] = useState<any>(true);
  const commentsMutation = usePostContactApplicationComments(application);
  const [form] = useForm();

  const providerData = application?.educationProviderMetadata;
  const providerApplicationId = providerData?.applicationId;
  const isApplicationConnected = Boolean(providerData?.name);
  const history = useHistory()
  const contact = useAppSelector(state => state.contact.singleData)
  const { data: statusHistory } = useFetchProviderStatusList(
    providerMethod,
    providerApplicationId
  );
  const providerStatus = statusHistory?.items?.[0]?.status;

  const showDrawer = (drawer: ActiveDrawer) => setActiveDrawer(drawer);

  const handleCloseDrawer = () => {
    setActiveDrawer('none')
    form.resetFields();
    setCommentFormButton(true);
    setSelectedTab('activities');
  };

  const handleSubmit = (data: any) => {
    try {
      if (applicationId) {
        commentsMutation.mutate({
          comment: data.comment,
          workflowApplicationStageId: application?.activeStageId,
          applicationId: application.id,
          applicationStatus: application.status,
        });
      }
    } catch (err) {
      errorHandler(err);
    }
    form.resetFields();
    setCommentFormButton(true);
  };

  const { allRequiredDocouments: requiredDocuments } = React.useContext(ApplicationDetailContext) || {};

  const requiredDocumentsList = requiredDocuments.map((stage) => {
    return { workflowApplicationStageId: stage.workflowApplicationStageId, name: stage.name, stage: stage.stage, checkListId: stage.checkListId };
  });

  return (
    <React.Fragment>
      <Row className="enrollment-detail">
        <BulkDocumentUpload requiredDocuments={requiredDocumentsList} application={application} />
        <div>
          {!isProduction && isApplicationConnected ? (
            <>
              {providerStatus && (
                <Tooltip title="Studylink Applicaiton Status">
                  <Tag color="volcano" style={{ padding: "4px" }}>{providerStatus}</Tag>
                </Tooltip>
              )}
              <Button
                className="custom_comments_button margin-right-1"
                onClick={() => history.push(`/education-provider/${applicationId}`)}
              >
                View Studylink Details
              </Button>
            </>
          ) : null}
          {(!isProduction && service?.toLowerCase() === "education" && !isApplicationConnected) ? (
            <Button
              className="custom_comments_button margin-right-1"
              icon={<ApiOutlined />}
              onClick={() => showDrawer('education-provider')}
            >
              Connect Studylink
            </Button>
          ) : null}
          <Button
            className="custom_comments_button"
            icon={<CommentOutlined />}
            onClick={() => showDrawer('activity-log')}
            data-testid="activitybtn"
          >
            Activity Log
          </Button>
        </div>
      </Row>
      <ApplicationActivityLogDrawer
        isOpen={activeDrawer === 'activity-log'}
        form={form}
        onClose={handleCloseDrawer}
        handleSubmit={handleSubmit}
        disabled={commentFormButton}
        setDisabled={setCommentFormButton}
        applicationId={applicationId}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />

      <EducationProviderDrawer
        isOpen={activeDrawer === "education-provider"}
        onClose={handleCloseDrawer}
        application={application}
        contactBranchId={contact.educationProviderMetadata?.branchId}
        providerMethod={providerMethod}
      />
    </React.Fragment>
  );
};

export default EnrollmentDetail;
