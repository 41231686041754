import React from 'react';
import { ITableNameCardProps } from '@shared-components/models';
import { UserNameCard } from '../assignee-card/user-name-card';
import { Menu, Space } from 'antd';
import {
  AppstoreAddOutlined,
  DeleteOutlined,
  EditOutlined,
  MailOutlined,
  MessageOutlined,
  PlusOutlined
} from '@ant-design/icons';
import {
  ARCHIVE,
  ASSIGN,
  DELETE_BTN,
  EDIT_BTN,
  SEND_MAIL,
  SEND_SMS
} from '@moxie/constants';

const menu = (
  <Menu>
    <Menu.Item key={1}>
      <Space direction="horizontal">
        <MailOutlined />
        {SEND_MAIL}
      </Space>
    </Menu.Item>
    <Menu.Item key={2}>
      <Space direction="horizontal">
        <MessageOutlined />
        {SEND_SMS}
      </Space>
    </Menu.Item>
    <Menu.Item key={3}>
      <Space direction="horizontal">
        <EditOutlined />
        {EDIT_BTN}
      </Space>
    </Menu.Item>
    <Menu.Item key={4}>
      <Space direction="horizontal">
        <PlusOutlined />
        {ASSIGN}
      </Space>
    </Menu.Item>
    <Menu.Item key={5}>
      <Space direction="horizontal">
        <AppstoreAddOutlined />
        {ARCHIVE}
      </Space>
    </Menu.Item>
    <Menu.Item key={6} danger>
      <Space direction="horizontal">
        <DeleteOutlined />
        {DELETE_BTN}
      </Space>
    </Menu.Item>
  </Menu>
);
export const TableUserNameCard: React.FC<ITableNameCardProps> = ({
  firstName,
  lastName,
  email,
  link,
  index,
  actionBar,
  handleEdit,
}: ITableNameCardProps) => {
  return (
    <UserNameCard
      firstName={firstName}
      lastName={lastName}
      link={link}
      email={email}
      index={index}
      actionBar={actionBar}
      menu={menu}
      showEmailAsLink={true}
      showMailIcon={true}
      handleEdit={handleEdit}
    />
  );
};
TableUserNameCard.defaultProps = {
  actionBar: true,
};
UserNameCard.defaultProps = {
  showEmailAsLink: false,
  showMailIcon: false,
};
