import React from 'react';
import {
  Collapse,
  Form,
  Input,
  Row,
  Col,
  Divider,
  Select,
  Spin,
  Radio,
  Button
} from 'antd';
import {
  CaretRightOutlined,
  DeleteOutlined,
  PlusOutlined
} from '@ant-design/icons';
import {
  AUTH_MESSAGE,
  DELETE_BTN,
  GUTTER,
  LABEL,
  MAIN_BRANCH,
  PHONE_NUMBER_INVALID,
  PHONE_TEN_DIGIT_ONLY,
  regex,
  TEXT,
  VALIDATION_MESSAGE
} from '@moxie/constants';
import { FormProps } from 'rc-field-form';
import { FormInstance } from 'antd/es/form/Form';
const { Panel } = Collapse;

interface Branch {
  is_main_branch: boolean;
  branchIndex?: number;
}
export interface AgentFormFields {
  branches: Branch[];
  type: string | null;
}
interface Props {
  form: FormInstance<AgentFormFields>;
  formSubmit: (values: AgentFormFields) => void;
  onFieldsChange: FormProps['onFieldsChange'];
  removeBranch: React.Dispatch<React.SetStateAction<string[]>>;
  onBranchChange: (val: number) => void;
  mainBranch: number | undefined;
}

const AgentsForm: React.FC<Props> = ({
  form,
  formSubmit,
  onFieldsChange,
  removeBranch,
  onBranchChange,
  mainBranch,
}) => {
  const isMainBranch = (name: number) => {
    return mainBranch === name
  };

  return (
    <>
      <Spin spinning={false}>
        <Form
          data-testid="crm-addagent-form"
          layout="vertical"
          className="institution-add-form"
          form={form}
          onFinish={formSubmit}
          scrollToFirstError={true}
          onFieldsChange={onFieldsChange}
        >
          <Collapse
            defaultActiveKey={['1']}
            bordered={false}
            ghost={true}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
          >
            <Panel header={<span>{TEXT.PRIMARY_INFORMATION}</span>} key="1">
              <Row gutter={GUTTER}>
                <Col span={24}>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        name="name"
                        rules={[
                          {
                            required: true,
                            max: 50,
                          },
                        ]}
                        label={LABEL.NAME}
                      >
                        <Input
                          className="note-title-input"
                          data-testid="crm-addagentdrawer-name"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={GUTTER}>
                    <Col span={12}>
                      <Form.Item
                        name="taxNumber"
                        rules={[
                          {
                            max: 50,
                          },
                          {
                            pattern: new RegExp(regex.ALPHA_NUMERIC),
                            message: VALIDATION_MESSAGE.INVALID_TAX_NUMBER,
                          },
                        ]}
                        label={LABEL.TAX_NUMBER}
                      >
                        <Input
                          className="note-title-input"
                          data-testid="crm-addagentdrawer-taxnumber"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="type"
                        rules={[
                          {
                            required: true,
                            max: 50,
                          },
                        ]}
                        label={LABEL.AGENT_TYPE}
                        initialValue={'isSubAgent'}
                      >
                        <Select
                          data-testid="crm-addagentdrawer-agenttype"
                          options={[
                            { value: 'isSubAgent', label: 'Sub Agent' },
                            { value: 'isSuperAgent', label: 'Super Agent' },
                            { value: 'both', label: 'Both' },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Panel>
          </Collapse>
          <Divider />
          <Collapse
            defaultActiveKey={['1']}
            bordered={false}
            ghost={true}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
          >
            <Panel header={<span>{TEXT.BRANCHES}</span>} key="1">
              <Form.List
                initialValue={[{ is_main_branch: true }]}
                name="branches"
              >
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, fieldKey, ...restField }) => {
                      const branch = form.getFieldValue('branches')[name];
                      let branchIndex = name;

                      if (branch?.branchIndex) {
                        branchIndex = branch?.branchIndex;
                      }

                      return (
                        <div key={key}>
                          <Row gutter={24}>
                            <Col span={12}>
                              <Form.Item
                                className="display-none"
                                {...restField}
                                name={[name, 'id']}
                              />
                              <Form.Item
                                className="display-none"
                                {...restField}
                                name={[name, 'branchIndex']}
                              />

                              <Form.Item
                                {...restField}
                                label={LABEL.NAME}
                                name={[name, 'name']}
                                rules={[
                                  {
                                    required: true,
                                    max: 50,
                                  },
                                ]}
                              >
                                <Input data-testid="crm-addagentdrawer-branchname" />
                              </Form.Item>
                            </Col>
                            <Col span={8}>
                              <Form.Item
                                name="isMainBranch"
                                className="institution-add-form__checkbox"
                              >
                                <div>
                                  <Radio
                                    data-testid="crm-addagentdrawer-mainbranch"
                                    id={`${key}`}
                                    className="radio_dyno"
                                    value={branchIndex}
                                    checked={mainBranch === branchIndex}
                                    onChange={(e) =>
                                      onBranchChange(e.target.value)
                                    }
                                  />
                                  <label
                                    className="margin-left-1"
                                    htmlFor={`${key}`}
                                  >
                                    {MAIN_BRANCH}
                                  </label>
                                </div>
                              </Form.Item>
                            </Col>
                            {!isMainBranch(branchIndex) ? (
                              <Col span={4}>
                                <Button
                                  onClick={() => {
                                    const branchId: string = form.getFieldValue(
                                      'branches'
                                    )[name]?.id;
                                    if (branchId) {
                                      removeBranch((branches) => [
                                        ...branches,
                                        branchId,
                                      ]);
                                    }
                                    remove(name);
                                  }}
                                  type="link"
                                  danger
                                  icon={<DeleteOutlined />}
                                  data-testid="crm-addagentdrawer-delete"
                                >
                                  {DELETE_BTN}
                                </Button>
                              </Col>
                            ) : null}
                          </Row>
                          <Row gutter={GUTTER}>
                            <Col span={12}>
                              <Form.Item
                                {...restField}
                                label={LABEL.EMAIL}
                                name={[name, 'email']}
                                rules={[
                                  {
                                    pattern: new RegExp(regex.EMAIL),
                                    message: AUTH_MESSAGE.INVALID_EMAIL,
                                  },
                                ]}
                              >
                                <Input data-testid="crm-addagentdrawer-email" />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item
                                {...restField}
                                label={LABEL.PHONE_NUMBER}
                                name={[name, 'phone']}
                                rules={[
                                  {
                                    validator: (rule, value) => {
                                      if (value) {
                                        if (
                                          !new RegExp(regex.ONLY_NUMBER).test(
                                            value
                                          ) &&
                                          value.length > 10
                                        ) {
                                          return Promise.reject(
                                            PHONE_NUMBER_INVALID
                                          );
                                        } else if (
                                          !new RegExp(regex.ONLY_NUMBER).test(
                                            value
                                          )
                                        ) {
                                          return Promise.reject(
                                            PHONE_NUMBER_INVALID
                                          );
                                        } else if (value.length > 10) {
                                          return Promise.reject(
                                            PHONE_TEN_DIGIT_ONLY
                                          );
                                        }
                                      }
                                      return Promise.resolve();
                                    },
                                  },
                                ]}
                              >
                                <Input data-testid="crm-addagentdrawer-phonenumber" />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Divider className="institution-add-form__divider" />
                        </div>
                      );
                    })}
                    <Row justify="end">
                      <Form.Item>
                        <Button
                          type="link"
                          data-testid="crm-addagentdrawer-addanotherbranch"
                          onClick={() => {
                            add();
                            const branches = form.getFieldValue('branches');
                            form.setFields([
                              {
                                name: [
                                  'branches',
                                  branches.length - 1,
                                  'branchIndex',
                                ],
                                value: Math.floor(Math.random() * 1000),
                              },
                            ]);
                          }}
                          icon={<PlusOutlined />}
                        >
                          {TEXT.ADD_ANOTHER_BRANCH}
                        </Button>
                      </Form.Item>
                    </Row>
                  </>
                )}
              </Form.List>
            </Panel>
          </Collapse>
        </Form>
      </Spin>
    </>
  );
};

export default AgentsForm;
