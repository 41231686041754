import { useQuery } from "@tanstack/react-query";
import { errorNotificationHandler } from "@moxie/shared";
import { getAllWfDocumentsLists } from "@crm/libs/services.api/lib/workflow.api";

export const useFetchAllRequiredDocs = (workflowStageIds: string[]) => {
  const { data, isLoading: isLoadingRequiredDocuments } = useQuery({
    queryKey: ['all-stage-documents', workflowStageIds],
    enabled: Array.isArray(workflowStageIds) && workflowStageIds.length > 0,
    queryFn: async () => {
      const { data } = await getAllWfDocumentsLists(workflowStageIds as string[]);
      return data.data;
    },
    onError: () => {
      errorNotificationHandler("Error fetching all stage documents.");
    },
  });

  return { allRequiredDocouments: data ?? [], isLoadingRequiredDocuments };
};
