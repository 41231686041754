import { WorkflowMetadata } from "@model/application";
import { Input, Space, Typography } from "antd";
import { ChangeEvent, KeyboardEvent, useState } from "react";
import { UpdateApplicationPayload } from '@model/application';
import { ActivitiesInfo, IField } from "../../fields-data";

const { Text } = Typography

interface Props<T extends string> {
  field: IField<T>;
  updateData: (payload: UpdateApplicationPayload, activitiesInfo: ActivitiesInfo, field: IField<T>) => void;
  isCompleted: boolean;
  activitiesInfo: ActivitiesInfo;
  initialInput: string;
  getPayload: (workflowMetadata: WorkflowMetadata, field: string, input: string) => UpdateApplicationPayload
  workflowMetadata: WorkflowMetadata | undefined;
}

export const TextField = <T extends string,>({
  field,
  updateData,
  isCompleted,
  activitiesInfo,
  initialInput,
  getPayload,
  workflowMetadata,
}: Props<T>) => {
  const [input, setInput] = useState(initialInput)
  const payload = getPayload(workflowMetadata as WorkflowMetadata, field.field, input)
  const onCancel = () =>
    setInput(initialInput);
  const onChange = (e: ChangeEvent<HTMLInputElement>) =>
    setInput(e.target.value);

  return (
    <Space direction="vertical">
      <Text strong className="initial_capital">
        {field.label}
      </Text>
      <Input
        type={field.type}
        onChange={onChange}
        value={input}
        onBlur={() => updateData(payload, activitiesInfo, field)}
        onPressEnter={() => updateData(payload, activitiesInfo, field)}
        onKeyDown={
          (e: KeyboardEvent<HTMLInputElement>) => {
            if (e.key === "Escape") {
              onCancel();
            }
          }
        }
        disabled={isCompleted}
      />
    </Space>
  )
}
