import { WorkflowMetadata } from "@model/application";
import { Space, Typography } from "antd";
import { UpdateApplicationPayload } from '@model/application';
import { ActivitiesInfo, IField } from "../../fields-data";
import { SelectValue } from "antd/lib/select";
import { IGetPopupContainer } from "@model/data.model";
import {
  SearchableSelect
} from '@shared-components/elements';

const { Text } = Typography

interface Props<T extends string> {
  field: IField<T>;
  updateData: (payload: UpdateApplicationPayload, activitiesInfo: ActivitiesInfo, field: IField<T>) => void;
  isCompleted: boolean;
  activitiesInfo: ActivitiesInfo;
  workflowMetadata: WorkflowMetadata | undefined;
  children: any;
  optionFilter?: 'search_prop' | 'label'
  defaultValue: string | string[] | undefined
  getPayload: (workflowMetadata: WorkflowMetadata, data: SelectValue) => UpdateApplicationPayload;
}

export const SelectField = <T extends string,>({
  field,
  updateData,
  workflowMetadata,
  isCompleted,
  activitiesInfo,
  children,
  optionFilter,
  defaultValue,
  getPayload,
}: Props<T>) => {
  const elementId = field.field + '-list';

  return (
    <Space direction="vertical" id={elementId} key={field.field}>
      <Text strong className="initial_capital">
        {field.label}
      </Text>
      <SearchableSelect
        getPopupContainer={(): IGetPopupContainer =>
          document.getElementById(elementId)
        }
        defaultValue={defaultValue}
        style={{ width: '100%' }}
        onChange={(data) => {
          updateData(getPayload(workflowMetadata as WorkflowMetadata, data), activitiesInfo, field);
        }}
        disabled={isCompleted}
        optionFilter={optionFilter}
      >
        {children}
      </SearchableSelect>
    </Space>
  );
}
