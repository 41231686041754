import { ContactData } from "@model/crm/contact.model";
import React, { createContext, useState, ReactNode, useContext } from "react";

export type SheetData = Record<string, string>;

type UpdateStepFunction = (val: number) => void;


type UseStepReturnType = {
  currentStep: number;
  updateStep: (stepValue: number) => void;
};

type UseSheetDataReturnType = {
  sheetData: SheetData[];
  updateSheetData: (data: SheetData[]) => void;
}

type TransformedDataFn = (data: ContactData[]) => void;
type UseTransformedDataReturnType = {
  transformedData: ContactData[];
  updateTransformedData: (data: ContactData[]) => void;
}

const StepContext = React.createContext<number>(0);
const UpdateStepContext = createContext<UpdateStepFunction | undefined>(undefined);

const SheetDataContext = createContext<SheetData[]>([]);
const UpdateSheetDataContext = createContext<React.Dispatch<React.SetStateAction<SheetData[]>> | undefined>(undefined);

const TransformedDataContext = createContext<ContactData[]>([]);
const UpdateTransformedDataContext = createContext<TransformedDataFn | undefined>(undefined);

export const StepProvider = ({ children }: { children: ReactNode }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [sheetdata, setSheetData] = useState<SheetData[]>([]);

  const [transformedData, setTransformedData] = useState<ContactData[]>([]);

  const updateCurrentStep = (stepValue: number) => {
    setCurrentStep(stepValue);
  }

  const updateTransformedData = (updatedData: ContactData[]) => {
    setTransformedData(updatedData);
  }

  return (
    <StepContext.Provider value={currentStep} >
      <UpdateStepContext.Provider value={updateCurrentStep}>
        <SheetDataContext.Provider value={sheetdata}>
          <UpdateSheetDataContext.Provider value={setSheetData}>
            <TransformedDataContext.Provider value={transformedData}>
              <UpdateTransformedDataContext.Provider value={updateTransformedData}>
                {children}
              </UpdateTransformedDataContext.Provider>
            </TransformedDataContext.Provider>
          </UpdateSheetDataContext.Provider>
        </SheetDataContext.Provider>
      </UpdateStepContext.Provider>
    </StepContext.Provider>
  )
}

export const useStepContext = (): UseStepReturnType => {
  const currentStep = useContext(StepContext);
  const updateStepValue = useContext(UpdateStepContext);

  const updateStep = (stepValue: number) => {
    if (updateStepValue) {
      updateStepValue(stepValue);
    }
  }

  return {
    currentStep,
    updateStep,
  }
}

export const useSheetDataContext = (): UseSheetDataReturnType => {
  const sheetData = useContext(SheetDataContext);
  const updateData = useContext(UpdateSheetDataContext);

  const updateSheetData = (data: SheetData[]) => {
    if (updateData) {
      updateData(data);
    }
  }

  return {
    sheetData,
    updateSheetData,
  }
}

export const useTransformedDataContext = (): UseTransformedDataReturnType => {
  const transformedData = useContext(TransformedDataContext);
  const modifyTransformedData = useContext(UpdateTransformedDataContext);

  const updateTransformedData = (data: ContactData[]) => {
    if (modifyTransformedData) {
      modifyTransformedData(data);
    }
  }

  return {
    transformedData,
    updateTransformedData,
  }
}
