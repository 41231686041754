import React, { useState } from 'react';
import { Excel } from 'antd-table-saveas-excel';
import { useForm } from 'antd/lib/form/Form';
import {  useAppSelector } from '@crm/core';
import { getApplicationsReport } from '@crm/libs/services.api/lib/application.api';
import { APPLICATION_REPORT_TITLE, URL_FORBIDDEN_ACCESS } from '@moxie/constants';
import { PageHeaderTitle } from '@shared-components/elements';
import { useGetApplicationsReport } from '../hooks/useGetApplicationsContact';
import { exportApplicationReportColumns } from './export-application-report-columns';
import ApplicationReportDrawer from './application-report-drawer';
import ApplicationReportTable from './application-report-table';
import moment from 'moment';
import { Redirect } from 'react-router-dom';
import { useFetchUserPermission } from '@crm/libs/hooks/users/useFetchUserPermission';
import { Spin } from 'antd';
import { ICrmPermissionData } from 'libs/shared/src/crm-modules/user/user.model';
import { groupBy } from 'lodash';

const ApplicationReportComponent: React.FC = () => {
  const [drawerVisiblity, setDrawerVisibility] = useState(false);
  const [filter, setFilter] = useState<any>({});
  const [page, setPage] = useState(1);
  const [disabled, setDisabled] = useState(true);
  const [form] = useForm();
  const limit = 10;

  const { userTimeZone, userId } = useAppSelector((store) => {
    return {
      userTimeZone: store?.auth?.user?.timeZone,
      userId: store?.auth?.user?.id,
    }
  }) || '';

  const { crmPermissions, permissionLoading } = useFetchUserPermission(userId as string);
  const permissionData = groupBy(crmPermissions, ({ permissionObject }: ICrmPermissionData) => permissionObject.name);
  const reportAccess = permissionData?.Report?.map((permission: ICrmPermissionData) => permission.name)?.includes('view_reports');
  const {
    applicationsReportData,
    isLoading,
    meta,
    hasMorePage,
  } = useGetApplicationsReport(
    {
      page,
      limit,
    },
    filter
  );

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const openDrawer = () => {
    setDrawerVisibility(true);
  };

  const closeDrawer = () => {
    setDrawerVisibility(false);
    setDisabled(true);
    form.resetFields();
    const existingFilter = { ...filter };
    if (existingFilter?.createdAt?.length) {
      const [start, end] = existingFilter.createdAt;
      existingFilter.createdAt = [moment(start), moment(end)];
    }
    if (existingFilter?.startDate?.length) {
      const [start, end] = existingFilter.startDate;
      existingFilter.startDate = [moment(start), moment(end)];
    }
    if (existingFilter?.endDate?.length) {
      const [start, end] = existingFilter.endDate;
      existingFilter.endDate = [moment(start), moment(end)];
    }
    form.setFieldsValue(existingFilter);
  };

  const handleSubmit = async (values: any) => {
    if (values.createdAt?.length) {
      const [start, end] = values.createdAt;
      values.createdAt = [
        start.format('YYYY-MM-DD'),
        end.format('YYYY-MM-DD'),
      ];
    }
    if (values.startDate?.length) {
      const [start, end] = values.startDate;
      values.startDate = [
        start.format('YYYY-MM-DD'),
        end.format('YYYY-MM-DD'),
      ];
    }
    if (values.endDate?.length) {
      const [start, end] = values.endDate;
      values.endDate = [start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD')];
    }
    setFilter(values);
    setPage(1);
  };

  const handleExport = async () => {
    const response = await getApplicationsReport({
      ...filter,
      exportReport: true,
    });
    const excel = new Excel();
    excel
      .addSheet('data')
      .addColumns(exportApplicationReportColumns(userTimeZone as string))
      .addDataSource(response?.data as any)
      .saveAs('application_report.xlsx');
  };

  if (permissionLoading) {
    return (
      <div className="page-load">
        <Spin />
      </div>
    )
  }

  if (!reportAccess) {
    return (
      <Redirect to={URL_FORBIDDEN_ACCESS} />
    )
  }

  return (
    <div className="full-height">
      <PageHeaderTitle title={APPLICATION_REPORT_TITLE} />
      <ApplicationReportDrawer
        disabled={disabled}
        setDisabled={setDisabled}
        form={form}
        setFilter={setFilter}
        drawerVisibility={drawerVisiblity}
        onClose={closeDrawer}
        onSubmit={handleSubmit}
      />
      <ApplicationReportTable
        openDrawer={openDrawer}
        tableData={applicationsReportData}
        filter={filter}
        handleExport={handleExport}
        isLoading={isLoading}
        page={page}
        meta={meta}
        hasMorePage={hasMorePage}
        handlePageChange={handlePageChange}
      />
    </div>
  );
};
export { ApplicationReportComponent };
