import { ApplicationStatusChangeRemarks } from '@model/application';
import Form from 'antd/es/form';
import Modal from 'antd/es/modal/Modal';
import Select from 'antd/es/select';
import React, { ReactNode } from 'react';
import TextArea from 'antd/es/input/TextArea';
import { Row } from 'antd/es/grid';
import Button from 'antd/es/button';
import { errorNotificationHandler } from '@moxie/shared';
import { ApplicationLists } from './application-lists';
import Typography from 'antd/es/typography';
import Space from 'antd/es/space';
import { CloseOutlined } from '@ant-design/icons'

interface Props {
  dealId: string
  isOpen: boolean;
  onSubmit: (val: ApplicationStatusChangeRemarks) => void;
  title: string;
  options: string[];
  formName: string;
  onClose: () => void;
  isSubmitting: boolean;
  canViewOrAddApplication: boolean;
  modalDescription?: ReactNode;
}

const { Text } = Typography;

const DealApplicationStatusUpdateModal: React.FC<Props> = ({
  dealId,
  isOpen,
  title,
  onSubmit,
  options,
  formName,
  onClose,
  isSubmitting,
  canViewOrAddApplication,
  modalDescription,
}) => {
  const [form] = Form.useForm<ApplicationStatusChangeRemarks>();

  const isDealLostForm = formName === 'deal-lost-form';

  const onFinish = async () => {
    try {
      const values = await form.validateFields();
      onSubmit(values);
      form.resetFields();
    } catch (error) {
      errorNotificationHandler('Please fill all the necessary details');
    }
  };

  const handleClose = () => {
    form.resetFields();
    onClose();
  };
  if (!isOpen) {
    return null;
  }

  return (
    <>
      <Modal
        title={title}
        width={800}
        visible={isOpen}
        centered
        onCancel={handleClose}
        bodyStyle={{ padding: '16px 24px' }}
        maskClosable={false}
        footer={
          <Row style={{ columnGap: '0.5rem' }} justify="end">
            <Button htmlType="reset" onClick={handleClose} disabled={isSubmitting}>
              Cancel
            </Button>
            <Button
              type={isDealLostForm ? 'default' : 'primary'}
              htmlType="button"
              onClick={() => form.submit()}
              loading={isSubmitting}
              danger={isDealLostForm}
            >
              {isDealLostForm ?
                (
                  <Space>
                    <CloseOutlined />
                    <Text className="error">Mark Deal as Lost</Text>
                  </Space>
                )
                : (
                  <Text className="text-white">Mark Deal as Completed</Text>
                )}
            </Button>
          </Row>
        }
      >
        <>
          {modalDescription ?? null}

          <ApplicationLists canViewOrAddApplication={canViewOrAddApplication} dealId={dealId} />

          <Form className="margin-top-2" layout="vertical" form={form} id={formName} onFinish={onFinish}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Please select a reason',
                },
              ]}
              label="Reason"
              name="reason"
              initialValue={options[0]}
            >
              <Select
                placeholder="Select reason"
                showSearch
                allowClear
                options={options.map((value) => ({ value, label: value }))}
              />
            </Form.Item>

            <Form.Item
              name="remarks"
              label="Remarks"
              rules={[
                {
                  required: true,
                  message: 'Please add remarks',
                  whitespace: true
                },
              ]}
            >
              <TextArea placeholder="Add remarks" />
            </Form.Item>
          </Form>
        </>
      </Modal>
    </>
  );
};

export default DealApplicationStatusUpdateModal;
