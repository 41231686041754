import { TEXT } from "@moxie/constants";
import { errorNotificationHandler, successNotificationHandler } from "@moxie/shared";
import { notification } from "antd";
import Progress from "antd/es/progress";
import { DataType } from '../contact-bulk-document-upload';
import { IUser } from '@model/data.model';
import { Socket } from 'socket.io-client';
import { IContactCrm } from '@model/index';
import { Application } from '@model/application';

type Props = {
  files: DataType[];
  subjectId: string;
  addMedia: (media: any) => Promise<any>;
  application: Application;
  setClassifiedFileList: (fileList: any[]) => void;
  setFileList: (fileList: any[]) => void;
  user: IUser | null;
  socket: Socket | null;
  contact: IContactCrm | null;
  queryClient: any;
  setIsLoading: any
};

export const BulkContactDocumentUploader = async ({
  files,
  subjectId,
  addMedia,
  application,
  setClassifiedFileList,
  setFileList,
  user,
  socket,
  contact,
  queryClient,
  setIsLoading,
}: Props) => {
  const key = 'uploadProgress';

  if (files.length > 20) {
    return errorNotificationHandler(TEXT.MAX_TASK_DOCUMENTS_COUNT);
  }

  setIsLoading(true);
  let totalSize = 0;
  let uploadedSize = 0;

  files.forEach((file) => {
    totalSize += file.originFileObj.size;
  });

  notification.open({
    key,
    message: 'Uploading Documents.',
    description: (
      <div>
        <span>Contact documents...</span>
        <Progress percent={0} size="small" />
      </div>
    ),
    duration: 0,
    placement: 'bottomRight',
  });

  const updateProgress = (increment: number) => {
    uploadedSize += increment;
    const percent = Math.round((uploadedSize / totalSize) * 100);

    notification.open({
      key,
      message: 'Uploading Documents',
      description: (
        <div>
          <span>Contact documents...</span>
          <Progress percent={percent} size="small" />
        </div>
      ),
      duration: 0,
      placement: 'bottomRight',
    });
  };

  const uploadFile = async (file: any) => {
    const fileSize = file.originFileObj.size;

    if (file.documentType === 'combined') {
      for (const label of file.documentTypeLabel) {
        await addMedia({
          documentType: label,
          documentTypeCategory: file.documentTypeCategory,
          path: `contact__${contact?.internalId}/Contact-Documents`,
          file: file.originFileObj,
          subjectId: subjectId || application?.client.id,
          subjectType: 'contact',
          name: file?.name,
        });
        updateProgress(fileSize / files.length);
      }
    } else {
      await addMedia({
        documentType: file.documentTypeLabel[0],
        documentTypeCategory: file.documentTypeCategory,
        path: `contact__${contact?.internalId}/Contact-Documents`,
        file: file.originFileObj,
        subjectId: subjectId || application?.client.id,
        subjectType: 'contact',
        name: file?.name,
      });
      updateProgress(fileSize / files.length);
    }
  };

  for (const file of files) {
    try {
      await uploadFile(file);
    } catch (error) {
      errorNotificationHandler('Failed to upload document(s).');
      return;
    }
  }

  try {
    socket?.emit('server::bulk-document-updated', {
      activitiesTypeId: contact?.id,
      activitiesType: 'bulk-document',
      activitiesAction: 'updated',
      userId: user?.id,
      contactId: contact?.id,
      companyId: user?.company_id,
    });

    queryClient.invalidateQueries({
      queryKey: ['documents-by-category'],
    });

    successNotificationHandler('Bulk contact document(s) uploaded successfully.');
  }
  catch (error) {
    errorNotificationHandler('Failed to emit socket or invalidate queries.');
  }
  finally {
    notification.close(key);
    setClassifiedFileList([]);
    setFileList([]);
    setIsLoading(false)
  }


};
