import { useQuery } from '@tanstack/react-query';
import { fetchStatusListFromProvider } from '@crm/libs/services.api/lib/education-provider.api';

export const useFetchProviderStatusList = (
  method: string,
  providerApplicationId: string
) => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['fetch-provider-status-list', method, providerApplicationId],
    queryFn: async () =>
      fetchStatusListFromProvider(method, providerApplicationId),
    enabled: Boolean(method) && Boolean(providerApplicationId),
  });

  return { data, isLoading, refetch };
};
