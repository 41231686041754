import { CANCEL_BTN, LABEL, SAVE_BTN } from '@moxie/constants';
import { DrawerElem as Drawer } from '@shared-components/elements';
import React, { useEffect, useState } from 'react';
import { Form, Space, Button, Select, Spin } from 'antd';
import { useFetchEducationProviderBranches } from '../hooks/use-fetch-education-provider-branches';
import {
  ProviderInstitution,
  CreateEducationProviderPayload
} from '@model/crm/education-provider.model';
import { useFetchEducationProviderCourses } from '../hooks/use-fetch-education-provider-courses';
import { useCreateEducationProviderApplication } from '../hooks/use-create-education-provider-application-mutation';
import { ApplicationDetail } from '@model/application';
import { LoaderComponent } from '@crm/src/shared/loader';

interface Props {
  onClose: () => void;
  isOpen: boolean;
  application: ApplicationDetail;
  providerMethod: string;
  contactBranchId?: string;
}

interface FormFields {
  branch: string;
  course: string;
  institution: string;
}

export const EducationProviderDrawer: React.FC<Props> = ({
  isOpen,
  onClose,
  application,
  contactBranchId,
  providerMethod,
}) => {
  const [form] = Form.useForm<FormFields>();
  const [institutionList, setInstitutionList] = useState<
    ProviderInstitution[]
  >();
  const [providerInstitutionId, setProviderInstitutionId] = useState<number>();
  const providerData = application?.educationProviderMetadata;
  const isApplicationConnected = Boolean(
    providerData?.name
  );
  const {
    data: branches,
    isFetching: branchesFetching,
  } = useFetchEducationProviderBranches(providerMethod, isApplicationConnected);
  const {
    data: courses,
    isFetching: coursesFetching,
  } = useFetchEducationProviderCourses(providerMethod, providerInstitutionId);
  const {
    mutate,
    isLoading: postLoading,
  } = useCreateEducationProviderApplication();

  useEffect(() => {
    if (contactBranchId) {
      const initialInstitution = branches?.items.find(
        (item) => item.id === contactBranchId.toString()
      )?.institutions;
      setInstitutionList(initialInstitution);
    }
  }, [branches?.items, contactBranchId]);

  const handleClose = () => {
    onClose();
    form.resetFields();
  };

  const handleSubmit = (data: FormFields) => {
    const payload: CreateEducationProviderPayload = {
      method: providerMethod,
      providerBranchId: data.branch,
      applicationId: application.id,
      providerInstitutionId: data.institution,
      providerCourseId: data.course,
    };
    mutate(payload, {
      onSuccess: () => {
        handleClose();
      },
    });
  };

  return (
    <Drawer
      title="Sync With Study Link"
      placement="right"
      onClose={handleClose}
      visible={isOpen}
      width={600}
      footer={
        <div className="padding-bottom-2 text-align-right">
          <Space direction="horizontal">
            <Button
              className="lead-margin-right"
              onClick={onClose}
              disabled={postLoading}>
              {CANCEL_BTN}
            </Button>
            <Button
              type="primary"
              onClick={() => form.submit()}
              loading={postLoading}
              disabled={postLoading}
            >
              {SAVE_BTN}
            </Button>
          </Space>
        </div>
      }
    >
      <>
        <div>
          {branchesFetching ? (
            <LoaderComponent />
          ) : (
            <Form layout="vertical" form={form} onFinish={handleSubmit}>
              <Spin spinning={postLoading}>
                <div>
                  <Form.Item
                    label={LABEL.BRANCH}
                    name="branch"
                    rules={[{ required: true, message: 'Please enter branch' }]}
                    initialValue={contactBranchId?.toString()}
                  >
                    <Select
                      disabled={Boolean(contactBranchId) || postLoading}
                      showSearch
                      options={branches?.items?.map((branch) => ({
                        label: branch.name,
                        value: branch.id,
                      }))}
                      placeholder={'Select Branch'}
                      onChange={(branchId: string) => {
                        form.setFieldsValue({ institution: undefined });
                        const result = branches?.items.find(
                          (branch) => branch.id === branchId
                        )?.institutions;
                        setInstitutionList(result);
                        setProviderInstitutionId(undefined);
                        form.setFieldsValue({ course: undefined });
                      }}
                      filterOption={(input, option) =>
                        ((option?.label as string) ?? '')
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    />
                  </Form.Item>
                </div>

                <div>
                  <Form.Item
                    label={LABEL.INSTITUTION}
                    name="institution"
                    rules={[
                      { required: true, message: 'Please enter institution' },
                    ]}
                  >
                    <Select
                      disabled={postLoading}
                      showSearch
                      options={institutionList?.map((institution) => ({
                        label: institution.name,
                        value: institution.id,
                      }))}
                      placeholder={'Select Institution'}
                      onChange={(id: number) => {
                        setProviderInstitutionId(id);
                      }}
                      filterOption={(input, option) =>
                        ((option?.label as string) ?? '')
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    />
                  </Form.Item>
                </div>

                <div>
                  <Spin spinning={coursesFetching} size="small">
                    <Form.Item
                      label={LABEL.COURSE}
                      name="course"
                      rules={[{ required: true, message: 'Please enter course' }]}
                    >
                      <Select
                        disabled={postLoading}
                        showSearch
                        options={courses?.items?.map((course) => ({
                          label: course.name,
                          value: course.id,
                        }))}
                        placeholder={'Select Course'}
                        filterOption={(input, option) =>
                          ((option?.label as string) ?? '')
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      />
                    </Form.Item>
                  </Spin>
                </div>
              </Spin>
            </Form>
          )}
        </div>
      </>
    </Drawer>
  );
};
