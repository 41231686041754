import React, { useState } from "react";
import { UploadOutlined, LoadingOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";
import { SheetData, useSheetDataContext, useStepContext } from "../import-context";
import { errorNotificationHandler } from "@moxie/shared";
import { Row, Spin, Typography } from "antd";
import { IMPORT_CONTACT } from "@moxie/constants";
import { isValidFile } from "@crm/src/shared/contact-document-dragger";

const UploadSheet = () => {
  const { updateSheetData } = useSheetDataContext();
  const { currentStep, updateStep } = useStepContext();

  const [loading, setLoading] = useState(false);

  const readExcelFile = async (file: File) => {
    try {
      setLoading(true);
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target!.result;
        try {
          const wb = XLSX.read(bufferArray, { type: "buffer", cellDates: true, dateNF: 'dd/mm/yyyy' });
          const wsName = wb.SheetNames[0];
          const ws = wb.Sheets[wsName];
          const data = XLSX.utils.sheet_to_json(ws, {
            raw: false,
            dateNF: "dd/mm/yyyy",
            defval: '',
          });

          if (data.length === 0) {
            errorNotificationHandler("File cannot be empty");
            return
          }
          updateSheetData(data as SheetData[]);
          setLoading(false);
          updateStep(currentStep + 1);
        } catch (error) {
          if (error instanceof Error) {
            errorNotificationHandler(error.message);
          }
        }
      }
    } catch (error: any) {
      if (error instanceof Error) {
        errorNotificationHandler(error.message);
      }
    } finally {
      setLoading(false)
    }
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files ?? [];
    const file = files[0];
    const fileSize = file?.size / (1024 * 1024);
    const validFileExtensions = ".xlsx, .xls"

    if (!isValidFile(file, validFileExtensions)) {
      errorNotificationHandler("Invalid file type")
    } else if (fileSize > 2) {
      errorNotificationHandler("File must be less than 2 MB");
    } else {
      readExcelFile(file)
    }
  }

  return (
    <>
      <div className="custom-file-upload">
        <label className="upload-input-label">
          <input type="file" accept=".xls, .xlsx" onChange={handleFileChange} />
          <Row justify="center" align="middle">
            <UploadOutlined style={{ fontSize: "30px", color: "#4a2362" }} />
          </Row>
          <Row justify="center" align="middle">
            <Typography.Text className="upload-primary-text"> Upload File {loading ? <Spin indicator={<LoadingOutlined className="margin-left-1" spin />} /> : null}
            </Typography.Text>
          </Row>
          <Row style={{ width: '350px', display: "flex", justifyContent: "center", textAlign: "center" }}>
            <Typography.Paragraph className="text-grey"> {IMPORT_CONTACT.UPLOAD_DESCRIPTION}</Typography.Paragraph>
          </Row>
        </label>
      </div>
      <Row align="middle" className="margin-top-1">
        <Typography.Text className="text-grey text-bold"> {IMPORT_CONTACT.SUPPORTED_FILE_FORMAT}:</Typography.Text>
        <Typography.Text className="text-grey">&nbsp; {IMPORT_CONTACT.UPLOAD_FILE_FORMAT}</Typography.Text>
      </Row>
      <Row align="middle">
        <Typography.Text className="text-grey text-bold"> {IMPORT_CONTACT.MAXIMUM_SIZE}:</Typography.Text>
        <Typography.Text className="text-grey">&nbsp; 2MB</Typography.Text>
      </Row>
    </>
  )
}
export { UploadSheet };
