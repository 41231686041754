import React, { ReactElement, ReactNode, useMemo } from 'react';
import { Button, CrmTable } from '@moxie/shared';
import { AuthUser, ContactListRouteParam, IOfficeBranch, IUser } from '@shared-components/models';
import {
  ADD_LEAD,
  ASSIGNEE_LABEL,
  CONTACT,
  IMPORT,
  LABEL,
  TEXT,
  URL_DETAIL_LINK
} from '@moxie/constants';
import { PlusOutlined, ImportOutlined } from '@ant-design/icons';
import { Col, Row, Spin, Typography } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { ContactTableNameCard } from './contact-table-name-card';
import { UserCard } from './user-card';
import { TableUserNameCard } from '../table-user-name-card';
import { UnassignedCard } from '../assignee-card/user-name-card';
import { getcontactList } from '@crm/services.api';
import { IUserData } from 'libs/shared/src/crm-modules/user/user.model';
import { colSorter } from 'libs/shared/src/elements/crm-table/colSorter';
import { DateFormat } from '@crm/shared';
import { IContactCrm } from '@model/crm/contact.model';
import SelectBranch from '../../select-branch';
import { useAppSelector } from '@crm/core';
import ActiveEnquiriesDealsStatus from './ActiveEnquiriesDealsStatus';

interface ContactColProps {
  currentUser: IUserData | (IUser & AuthUser) | null;
  triggerRefresh: () => void;
  setInitialData: React.SetStateAction<IContactCrm | undefined>;
  setIsDrawerOpen: React.SetStateAction<boolean>;
  setIsEditMode: React.SetStateAction<boolean>;
  name: string;
}

interface IModifiedObj {
  [key: string]: string;
}

const { Text } = Typography;

const useColumns = ({ currentUser, triggerRefresh, setInitialData, setIsDrawerOpen, setIsEditMode, name }: ContactColProps) => {
  const cols = [
    {
      title: TEXT.INTERNAL_ID,
      dataIndex: 'internalId',
      key: 'internalId',
      render: (value: string, data: IContactCrm): ReactNode => {
        return (
          // TODO:: ADD COMPANY CODE
          <Link to={`${URL_DETAIL_LINK}${data?.id}/deals`}>
            <Text className="text-primary-color initial_capital">
              {data?.company?.companyCode ? data?.company?.companyCode + '-' + value : value}
            </Text>
          </Link>
        );
      },
    },
    {
      title: LABEL.NAME,
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: () => 0,
      render: (_text: string, row: IContactCrm, index: number): ReactElement => (
        <ContactTableNameCard
          id={row.id}
          applicationData={row.applications}
          firstName={row.firstName || ''}
          email={row.email || ''}
          lastName={row.lastName}
          data={row}
          index={index.toString()}
          userProp={currentUser}
          triggerRefresh={triggerRefresh}
          setInitialData={setInitialData}
          setIsDrawerOpen={setIsDrawerOpen}
          setIsEditMode={setIsEditMode}
        />
      ),
    },
    ...(name !== 'archived' ? [
      {
        title: LABEL.STATUS,
        key: 'status',
        render: (_text: string, row: IContactCrm): ReactNode => {
          return (
            <ActiveEnquiriesDealsStatus activeEnquiriesCount={row?.activeEnquiries} activeDealsCount={row?.activeDeals} />
          );
        },
      }
    ] : []),
    {
      title: LABEL.PHONE_NUMBER,
      dataIndex: 'phone',
      key: 'phone',
      render: (_text: string, row: IContactCrm): ReactNode => {
        const phoneNumber = row?.countryCode && row?.phone ? `${row?.countryCode} ${row?.phone}` : '-';
        return (
          <Row justify={phoneNumber === '-' ? 'center' : 'start'} className="lead-table__phone">
            <Col>
              {phoneNumber}
            </Col>
          </Row>
        );
      },
    },
    {
      title: LABEL.PRIMARY_BRANCH,
      dataIndex: 'branch',
      key: 'branch',
      render: (branch: IOfficeBranch): ReactNode => {
        return (
          <div className="lead-table__city">
            <span>{branch ? branch?.name : '-'}</span>
          </div>
        );
      },
    },
    {
      title: ASSIGNEE_LABEL,
      dataIndex: 'assignee',
      key: 'assigneeId',
      width: 300,
      render: (text: string, data: IContactCrm): ReactElement => {
        return data?.assigneeId !== null ? (
          <UserCard
            firstName={data?.assignee?.firstName || ''}
            lastName={data?.assignee?.lastName || ''}
            email={data?.assignee?.email || ''}
            showEmailAsLink={true}
          />
        ) : (
          <UnassignedCard />
        )
      },
    },
    {
      title: name !== "archived" ? LABEL.LAST_UPDATED : "Archived On",
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sorter: () => 0,
      render: (text: string) => {
        return <DateFormat date={text} />
      },
    },
    ...(name === "archived" ? [{
      title: LABEL.ARCHIVED_BY,
      dataIndex: 'archivedBy',
      key: 'archivedBy',
      render: (archivedBy: IUserData | undefined): ReactElement | null => {
        if (!archivedBy) {
          return null;
        }
        return (
          <div className="lead-table__archived-by">
            <TableUserNameCard
              index={archivedBy.id || ''}
              actionBar={false}
              firstName={archivedBy.firstName || ''}
              lastName={archivedBy.lastName}
              email={archivedBy.email || ''}
              id={archivedBy.id || ''}
            />
          </div>
        );
      },
    }] : [])
  ]

  return React.useMemo(() => {
    return cols.map((c) => colSorter(c as any))
  }, [name])
}
const ContactTableList: React.FC<any> = ({
  branch,
  setBranch,
  refresh,
  handleAddContact,
  triggerRefresh,
  setInitialData,
  setIsDrawerOpen,
  setIsEditMode,
  handleContactImport,
}: any) => {
  const { name } = useParams<ContactListRouteParam>();

  const modifiedObj: IModifiedObj = {
    "all-contacts": "all-contacts",
    "my-contacts": "my-contacts",
    "archived": "archived-contacts",
  }
  const currentUser = useAppSelector((store) => store.auth.user);

  const filter = useMemo(() => {
    return { branch };
  }, [branch])

  const columns = useColumns({ currentUser, triggerRefresh, setInitialData, setIsDrawerOpen, setIsEditMode, name });

  if (!branch) {
    return (
      <div className="page-load">
        <Spin />
      </div>
    )
  }

  return (
    <div className="bg-white padding-top-2">
      <CrmTable
        key={name}
        tableName={CONTACT}
        filterContent={
          <SelectBranch
            branch={branch}
            handleBranchChange={(val: string) => setBranch(val)}
          />
        }
        type={modifiedObj[name] as string}
        filter={filter}
        action={getcontactList}
        columns={columns}
        size={'small'}
        refresh={refresh}
        tableButtons={
          <>
            {/* Can(currentUser, PermissionAction.Create, PermissionObjects.User) && ( */}
            <Button
              type="primary"
              onClick={handleAddContact}
              data-testid="crm-addleadbutton"
            >
              <PlusOutlined />
              {ADD_LEAD}
            </Button>
            {/* // ) */}
            <Button type="primary" onClick={handleContactImport}>
              <ImportOutlined />
              {IMPORT}
            </Button>
          </>
        }
      />
    </div>
  );
};

export { ContactTableList };
