import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Col,
  Dropdown,
  Form,
  Input,
  Menu,
  Row,
  Space,
  Typography
} from 'antd';
import {
  MoreOutlined,
  CheckOutlined,
  CloseOutlined,
  DownloadOutlined,
  FolderViewOutlined,
  EditOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import {
  CONFIRM_MESSAGE,
  CONFIRM_TITLE,
  FILE_NAME_MAXIMUM,
  FILE_NAME_REQUIRED,
  INVALID_FILE_NAME,
  regex
} from '@moxie/constants';
import { useForm } from 'antd/lib/form/Form';
import {
  useDocumentMutation
} from '../../../../hooks/useDocumentMutation';
import { popConfirm, successNotificationHandler } from '@moxie/shared';
import { DocumentsFileIcon } from 'apps/crm/src/pages/document/document-file-icon';
import { leadActions, useAppSelector } from '@crm/core';
import useContactParam from '@crm/libs/hooks/contacts/useContactParam';
import ApplicationDetailContext from '../../../../application-detail-context';
import { Media } from '@model/document';
import { SelectedStage } from '@model/application';
import { Checklist } from '@model/workflow';
import { downloadFile, getMediaUrl } from '@crm/services.api';
import { useQueryClient } from '@tanstack/react-query';
import { FormInstance } from 'antd/es/form';
import { DocumentPreview, useDocumentPreview } from 'libs/shared/src/crm-modules/shared';
import { useDispatch } from 'react-redux';

const documentActionMenu = (documentMenuActions: any, document: any) => {
  const {
    handleDownload,
    handlePreview,
    handleRename,
    handleDelete,
  } = documentMenuActions;

  return (
    <>
      <Menu selectable={false}>
        <Menu.Item
          className="crm-doc-upload-type-item"
          key="1"
          icon={<DownloadOutlined />}
          onClick={() => handleDownload(document)}
        >
          Download
        </Menu.Item>
        <Menu.Item
          className="crm-doc-upload-type-item"
          key="2"
          icon={<FolderViewOutlined />}
          onClick={() => handlePreview(document)}
        >
          Preview
        </Menu.Item>
        <Menu.Item
          className="crm-doc-upload-type-item"
          key="3"
          icon={<EditOutlined />}
          onClick={handleRename}
        >
          Rename
        </Menu.Item>
        <Menu.Item
          className="crm-doc-upload-type-item-delete"
          style={{ color: 'red' }}
          key="4"
          icon={<DeleteOutlined color="red" />}
          onClick={() => handleDelete(document)}
        >
          Delete
        </Menu.Item>
      </Menu>
    </>
  );
};

interface DocumentNameEditCardProps {
  handleSubmit: (data: any) => void;
  handleCancel: () => void;
  form: FormInstance<any>;
  documentData: Media
}
const DocumentNameEditCard = ({
  handleSubmit,
  handleCancel,
  form,
  documentData,
}: DocumentNameEditCardProps) => {
  useEffect(() => {
    form.setFieldsValue({
      name: documentData.name,
    });
  }, [form, documentData]);

  return (
    <Form
      layout="inline"
      form={form}
      onFinish={(value) => handleSubmit(value)}
    >
      <Row justify="space-between">
        <Col span={18}>
          <Form.Item
            name="name"
            className="document-name show_text_overflow"
            rules={[
              { required: true, message: FILE_NAME_REQUIRED },
              { max: 50, message: FILE_NAME_MAXIMUM },
              {
                pattern: new RegExp(regex.FILE_NAME),
                message: INVALID_FILE_NAME,
              },
            ]}
          >
            <Input className="document-name-edit-card-input" />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item>
            <Button
              size="small"
              type="text"
              icon={<CheckOutlined />}
              onClick={() => form.submit()}
            />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item>
            <Button
              size="small"
              type="text"
              icon={<CloseOutlined />}
              onClick={handleCancel}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

interface DocumentProps {
  document: Media;
  selectedStage: SelectedStage;
  selectedChecklist: Checklist | undefined;
  applicationId: string;
}
const DocumentNameCard: React.FC<DocumentProps> = ({
  document,
  selectedStage,
  selectedChecklist,
  applicationId,
}) => {
  const queryClient = useQueryClient();
  const docMutation = useDocumentMutation(document.id);
  const [isEdit, setIsEdit] = useState(false);
  const [form] = useForm();
  const socket = useAppSelector((state) => state.socket.wss);
  const user = useAppSelector((state) => state.auth.user);
  const contactId = useContactParam();
  const { applicationData: application } = useContext(ApplicationDetailContext);
  const { clearDocsPreview, docPreview, fetchUrlAndPreviewDocs } = useDocumentPreview();
  const dispatch = useDispatch();

  const handleDownload = () => {
    popConfirm(
      CONFIRM_TITLE.DOWNLOAD_DOCUMENT,
      `${CONFIRM_MESSAGE.DOWNLOAD_DOCUMENT}`,
      async () => {
        dispatch(leadActions.downloading(true))
        const url = await getMediaUrl(document.id);
        if (url) {
          dispatch(leadActions.downloading(false));
          downloadFile(url);
        }
      }
    );
  };

  const handleDelete = () => {
    popConfirm(
      CONFIRM_TITLE.DELETE_DOCUMENT,
      `${CONFIRM_MESSAGE.DELETE_DOCUMENT}`,
      () => {
        docMutation.mutate(
          {
            isDeletedFromApplication: true,
          },
          {
            onSuccess() {
              queryClient.invalidateQueries({
                queryKey: ['docs-count', applicationId],
              });
              queryClient.invalidateQueries({
                queryKey: ['application-checklist-docs', applicationId, selectedChecklist?.name],
              });

              socket?.emit('server::application-updated', {
                activitiesTypeId: applicationId,
                userId: user?.id as string,
                companyId: user?.companyId as string,
                contactId: contactId,
                data: {
                  data: {
                    ...application,
                    id: applicationId,
                    currentStage: {
                      stage: selectedStage.stage,
                    },
                    documentType: selectedChecklist?.name,
                  },
                },
                activitiesAction: 'deleted',
                activitiesType: 'application-document',
              });
            },
          }
        );
      }
    );
  };

  const handleRename = () => setIsEdit(true);

  const handleRenameSubmit = async (data: any) => {
    docMutation.mutate({ name: data.name },
      {
        onSuccess() {
          setIsEdit(false);
          successNotificationHandler('Document updated successfully.', 1.5);
          socket?.emit('server::application-updated', {
            activitiesTypeId: applicationId,
            userId: user?.id as string,
            companyId: user?.companyId as string,
            contactId: contactId,
            data: {
              data: {
                ...application,
                id: applicationId,
                currentStage: {
                  stage: selectedStage.stage,
                },
                documentType: selectedChecklist?.name,
              },
            },
            activitiesAction: 'updated',
            activitiesType: 'application-document',
          });
        },
      }
    );
  };

  const documentMenuActions = {
    handleDownload,
    handlePreview: fetchUrlAndPreviewDocs,
    handleRename,
    handleDelete,
  };
  return (
    <>
      <Row align="middle" justify="space-between">
        <Space>
          <DocumentsFileIcon mimetype={document?.mimetype} />
          {isEdit ? (
            <DocumentNameEditCard
              handleSubmit={handleRenameSubmit}
              handleCancel={() => setIsEdit(false)}
              form={form}
              documentData={document}
            />
          ) : (
            <Typography.Text
              style={{ width: '18rem', textOverflow: 'ellipsis' }}
              ellipsis={true}
              className="text-primary-color initial_capital"
            >
              {document?.name}
            </Typography.Text>
          )}
        </Space>
        <Col span={1}>
          <Dropdown
            overlay={documentActionMenu(documentMenuActions, document)}
            placement={window.screen.width > 1366 ? 'bottomLeft' : 'topLeft'}
          >
            <Button
              type="text"
              className="table-cell-action-btn"
              icon={<MoreOutlined />}
            />
          </Dropdown>
        </Col>
      </Row>
      <DocumentPreview
        doc={docPreview}
        onCancel={clearDocsPreview}
        onOk={clearDocsPreview}
      />
    </>
  );
};

export default DocumentNameCard;
