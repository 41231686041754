import { getContactEducationLevels } from "@crm/services.api"
import { useQuery } from "@tanstack/react-query"

export const useFetchDegreeLevelsQuery = () => {
  const { data: degreeLevels, isLoading } = useQuery({
    queryKey: ['degreeLevels'],
    queryFn: async () => await getContactEducationLevels(),
  })

  return { degreeLevels, isLoading }
}
