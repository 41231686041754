import React, { useEffect } from 'react';
import {
  ADDRESS1_LABEL,
  ADDRESS2_LABEL,
  COUNTRIES,
  GUTTER,
  LABEL,
  VALIDATION_MESSAGE,
  regex,
} from '@moxie/constants';
import { SearchableSelect, Option } from '@shared-components/elements';
import {
  IContactDetailsInfoForm,
  IGetPopupContainer,
} from '@shared-components/models';
import { Col, Form, Input, Row } from 'antd';

const AddressInfoForm: React.FC<IContactDetailsInfoForm> = ({
  form,
  handleSubmit,
  setDisabled,
  initialData,
}: IContactDetailsInfoForm) => {
  const handleFieldsChange = (allFields: string | any[]) => {
    if (allFields.length > 0) {
      for (const fields of allFields) {
        fields.errors.length < 1 ? setDisabled(false) : setDisabled(true);
      }
    }
  };
  useEffect(() => {
    form.setFieldsValue({
      country: initialData?.country,
      city: initialData?.city,
      state: initialData?.state,
      zipCode: initialData?.zipCode,
      postalCode: initialData?.postalCode,
      street: initialData?.street,
      addressLineOne: initialData?.addressLineOne,
      addressLineTwo: initialData?.addressLineTwo,
    });
  }, [initialData]);
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      onFieldsChange={handleFieldsChange}
      scrollToFirstError={true}
    >
      <Row gutter={GUTTER}>
        <Col span={24}>
          <Form.Item name="addressLineOne" label={ADDRESS1_LABEL}>
            <Input
              className="note-title-input"
              data-testid="crm-editaddressinfo-addressone"
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="addressLineTwo" label={ADDRESS2_LABEL}>
            <Input
              className="note-title-input"
              data-testid="crm-editaddressinfo-addresstwo"
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="city"
            label={LABEL.CITY}
            rules={[
              { required: true },
            ]}
          >
            <Input
              className="note-title-input"
              data-testid="crm-editaddressinfo-city"
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="state"
            label={LABEL.STATE}
          >
            <Input
              className="note-title-input"
              data-testid="crm-editaddressinfo-state"
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <div id="country_list" className="relative">
            <Form.Item
              name="country"
              label={LABEL.COUNTRY_OF_RESIDENCE}
              rules={[{ required: true }]}
            >
              <SearchableSelect
                className="note-title-input"
                allowClear={false}
                optionFilter="search_prop"
                getPopupContainer={(): IGetPopupContainer =>
                  document.getElementById('country_list')
                }
                data-testid="crm-editaddressinfo-country"
              >
                {COUNTRIES.map((item) => {
                  return (
                    <Option
                      value={item.name}
                      key={item.code}
                      search_prop={`${item.name} ${item.code}`}
                    >
                      {item.name}
                    </Option>
                  );
                })}
              </SearchableSelect>
            </Form.Item>
          </div>
        </Col>
        <Col span={24}>
          <Form.Item
            name="zipCode"
            label={LABEL.ZIP_CODE}
          >
            <Input
              className="note-title-input"
              data-testid="crm-editaddressinfo-zipcode"
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export { AddressInfoForm };
