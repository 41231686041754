import React, { useEffect, useState } from "react";
import { useSheetDataContext, useStepContext, useTransformedDataContext } from "../import-context";
import { IMPORT_CONTACT } from "@moxie/constants";
import { Button, Card, Col, Form, Row, Select, Spin, Typography } from "antd";
import { useForm } from "antd/lib/form/Form";
import { errorNotificationHandler } from "@moxie/shared";
import { LoadingOutlined, ArrowLeftOutlined, RightCircleOutlined } from "@ant-design/icons";
import { ExistingEmailModal } from "./existing-email-modal";
import { verifyEmailExists } from "@crm/services.api";
import { DuplicateEmailModal } from "./duplicate-email-modal";
import { ContactData } from "@model/crm/contact.model";

const { Title, Text } = Typography;

const { Option } = Select;

const layout = {
  labelCol: { span: 12 },
  wrapperCol: { span: 12 },
}

const MatchColumns = () => {
  const [form] = useForm();

  const { sheetData } = useSheetDataContext();
  const { currentStep, updateStep } = useStepContext();
  const { updateTransformedData } = useTransformedDataContext();
  const headerKeys = Object.keys(sheetData[0]);

  const [selectedHeaders, setSelectedHeaders] = useState<string[]>([]);
  const [sampleData, setSampleData] = useState<Record<string, any[]>>(initializeSampleData(headerKeys));
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [emailList, setEmailList] = useState<{isDeleted: boolean, email: string}[]>([]);
  const [duplicateEmails, setDuplicateEmails] = useState<string[]>([]);
  const [showDupModal, setShowDupModal] = useState<boolean>(false);

  function initializeSampleData(keys: string[]) {
    return headerKeys.reduce((acc, key) => {
      acc[key] = [];
      return acc;
    }, {} as Record<string, any[]>);
  }


  const requiredFields = [
    { label: 'First Name', name: 'firstName', index: 0 },
    { label: 'Last Name', name: 'lastName', index: 1 },
    { label: 'Email', name: 'email', index: 2 },
  ];

  const phoneField = { label: 'Phone', name: 'phone', index: 3 };
  const countryField = { label: 'Country Code', name: 'countryCode', index: 4 };


  const optionalFields = [
    { label: 'Nationality', name: 'nationality', index: 5 },
    { label: 'City', name: 'city', index: 6 },
    { label: 'State', name: 'state', index: 7 },
    { label: 'Date Of Birth', name: 'dateOfBirth', index: 8 },
    { label: 'Zip Code', name: 'zipCode', index: 9 },
    { label: 'Country Of Residence', name: 'country', index: 10 },
    { label: 'Country Of Passport', name: 'countryOfPassport', index: 11 },
    { label: 'Visa Expiry Date', name: 'visaExpiryDate', index: 12 },
  ]

  const handleFieldsChange = (allFields: string | any[]) => {
    if (allFields.length > 0) {
      for (const field of allFields) {
        field.errors.length < 1 ? setDisabled(false) : setDisabled(true);
      }
    }
  };

  const cleanPhoneNumber = (phoneNumber: string) => {
    phoneNumber = phoneNumber.replace(/-/g, '')
      .replace(/\s/g, '')
      .replace(/\(/g, '')
      .replace(/\)/g, '')
    return phoneNumber;
  }

  const addPlusInCountryCode = (countryCode: string) => {
    if (countryCode.startsWith('00')) {
      countryCode = '+' + countryCode.slice(2);
    }
    if (!countryCode.startsWith('00') && !countryCode.startsWith('+')) {
      countryCode = '+' + countryCode;
    }
    return countryCode;
  }


  const hasDuplicateValues = (obj: Record<keyof ContactData, string | undefined>) => {
    const values = Object.entries(obj)
      .filter(([key]) => key !== 'key')
      .map(([, value]) => value)
      .filter(elem => elem !== undefined)
    const uniqueValues = new Set(values);

    return values.length !== uniqueValues.size;
  }

  const handleSubmitClick = () => {
    setLoading(true);
    form.validateFields();
    form.submit();
  }

  const handleFormSubmit = async (values: Record<keyof ContactData, string | undefined>) => {
    try {
      setLoading(true);
      setDisabled(true);

      const duplicateStatus = hasDuplicateValues(values);

      if (duplicateStatus) {
        errorNotificationHandler("Duplicate column match found");
        return;
      }
      const transformedData: ContactData[] = sheetData.map((entry, index: number) => {
        const transformedEntry: Partial<Record<keyof ContactData, string>> = {};
        const entries = Object.entries(values) as [keyof ContactData, string | undefined][]

        for (const [newKey, oldKey] of entries) {
          if (oldKey) {
            transformedEntry[newKey] = entry[oldKey];
          }

        }
        transformedEntry.key = `contact${index}`;

        return transformedEntry as ContactData;
      })
      transformedData.forEach(obj => {
        if (obj.phone) {
          obj.phone = cleanPhoneNumber(obj.phone)
        }
        if (obj.countryCode) {
          obj.countryCode = addPlusInCountryCode(obj.countryCode)
        }
      })
      updateTransformedData(transformedData);
      const uniqueEmails = [...new Set(transformedData.map(data => data.email))];

      const repeatedEmails = transformedData.reduce((acc: { allEmails: string[], repeatedEmails: string[] }, data: ContactData) => {
        const email = data.email.toLowerCase().trim();
        if (email.length > 0) {
          if (acc.allEmails.includes(email)) {
            if (!acc.repeatedEmails.includes(email)) {
              acc.repeatedEmails.push(email);
            }
          } else {
            acc.allEmails.push(email);
          }
        }
        return acc;
      }, { allEmails: [], repeatedEmails: [] }).repeatedEmails;


      const uniqueRepeatedEmails = [...new Set(repeatedEmails)];

      if (uniqueRepeatedEmails.length === 0) {
        const emailData = await verifyEmailExists(uniqueEmails);
        if (emailData.length > 0) {
          setEmailList(emailData)
          setShowModal(true);
        } else {
          updateStep(currentStep + 1);
        }
      } else {
        setDuplicateEmails(uniqueRepeatedEmails);
        setShowDupModal(true);
      }

    } finally {
      setLoading(false);
      setDisabled(true);
    }
  }

  useEffect(() => {
    const updatedSampleData = { ...sampleData };
    selectedHeaders.forEach((selectedHeader) => {
      updatedSampleData[selectedHeader] = [];
      if (selectedHeader) {
        for (let i = 0; i < Math.min(sheetData.length, 3); i++) {
          updatedSampleData[selectedHeader].push(sheetData[i][selectedHeader]);
        }
      }
    });
    setSampleData(updatedSampleData);
  }, [sheetData, selectedHeaders]);

  const handleOptionChange = (value: string, fieldIndex: number) => {
    const updatedSelectedHeaders = [...selectedHeaders];
    updatedSelectedHeaders[fieldIndex] = value;
    setSelectedHeaders(updatedSelectedHeaders);
  };

  const handlePrevClick = () => {
    updateStep(currentStep - 1);
    updateTransformedData([]);
  }


  return (
    <>
      <div>
        <h4>{IMPORT_CONTACT.MATCH_COLUMNS}</h4>
        <p>{IMPORT_CONTACT.MATCH_STEP_DESCRIPTION}</p>
      </div>
      <div>
        <Row className="margin-top-3">
          <Col span={8}><Title level={5}>{IMPORT_CONTACT.CONTACT_ATTRIBUTE}</Title></Col>
          <Col span={8}><Title level={5}>{IMPORT_CONTACT.FILE_HEADER}</Title></Col>
          <Col span={8}><Title level={5}>{IMPORT_CONTACT.SAMPLE_DATA_FROM_FILE}</Title></Col>
        </Row>
        <Row>
          <Card style={{ width: '100%' }}>
            <Form {...layout} form={form}
              onFinish={handleFormSubmit} labelAlign="left" requiredMark={false} onFieldsChange={handleFieldsChange}>
              <Text><sup style={{ color: '#f5222d', paddingRight: "2px" }}>*</sup>Required Fields</Text>

              {requiredFields.map((field) => (
                <Row key={field.name}>

                  <Card style={{ width: "100%", backgroundColor: "#fcfcfc", marginTop: field.index > 0 ? '8px' : '0' }} >
                    <Row align="middle" className="w-100">
                      <Col span={14}>

                        <Form.Item colon={false} name={field.name} label={field.label} rules={[{ required: true, message: `Please Select column name for ${field.label}` }]}>
                          <Select style={{ width: "100%" }} allowClear={true} onChange={(value) => handleOptionChange(value as any, field.index)}>
                            {headerKeys?.map((item: string) => (
                              <Option key={item} value={item}>
                                {item}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>

                      </Col>
                      <Col span={7} offset={3}>
                        {selectedHeaders[field.index] && (
                          <div>
                            <ul>
                              {sampleData[selectedHeaders[field.index]].map((dataItem, dataIndex) => (
                                <li key={dataIndex}>{dataItem}</li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </Col>
                    </Row>
                  </Card>

                </Row>
              ))}
              <div className="margin-top-2">
                <Text>Optional Fields</Text>
              </div>
              <Card style={{ width: "100%", backgroundColor: "#fcfcfc", marginTop: '8px' }} >

                <Row align="middle" className="w-100">
                  <Col span={14}>
                    <Form.Item colon={false} name={phoneField.name} label={phoneField.label}>
                      <Select style={{ width: "100%" }} allowClear={true} onChange={(value) => handleOptionChange(value as any, phoneField.index)}>
                        {headerKeys?.map((item: string) => (
                          <Option key={item} value={item}>
                            {item}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>

                  </Col>
                  <Col span={7} offset={3}>
                    {selectedHeaders[phoneField.index] && (
                      <div>
                        <ul>
                          {sampleData[selectedHeaders[phoneField.index]].map((dataItem, dataIndex) => (
                            <li key={dataIndex}>{dataItem}</li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </Col>
                </Row>
                {form.getFieldValue([`${phoneField.name}`]) ?
                  <Row align="middle" className="w-100">
                    <Col span={14}>

                      <Form.Item colon={false} name={countryField.name} label={countryField.label} rules={[{ required: true, message: `Please Select column name for ${countryField.label}` }]}>
                        <Select style={{ width: "100%" }} allowClear={true} onChange={(value) => handleOptionChange(value as any, countryField.index)}>
                          {headerKeys?.map((item: string) => (
                            <Option key={item} value={item}>
                              {item}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>

                    </Col>
                    <Col span={7} offset={3}>
                      {selectedHeaders[countryField.index] && (
                        <div>
                          <ul>
                            {sampleData[selectedHeaders[countryField.index]].map((dataItem, dataIndex) => (
                              <li key={dataIndex}>{dataItem}</li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </Col>
                  </Row>
                  : null}
              </Card>
              {optionalFields.map((field) => (
                <Row key={field.name}>
                  <Card style={{ width: "100%", backgroundColor: "#fcfcfc", marginTop: '8px' }} >
                    <Row align="middle" className="w-100">
                      <Col span={14}>
                        <Form.Item colon={false} name={field.name} label={field.label}>
                          <Select style={{ width: "100%" }} allowClear={true} onChange={(value) => handleOptionChange(value as any, field.index)}>
                            {headerKeys?.map((item: string) => (
                              <Option key={item} value={item}>
                                {item}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      {selectedHeaders[field.index] && (
                        <Col span={7} offset={3}>
                          <div>
                            <ul>
                              {sampleData[selectedHeaders[field.index]].map((dataItem, dataIndex) => (
                                <li key={dataIndex}>{dataItem}</li>
                              ))}
                            </ul>
                          </div>
                        </Col>
                      )}
                    </Row>
                  </Card>
                </Row>
              ))}

              <Row className="margin-top-2" align="middle" justify="space-between">
                <Button type="ghost" onClick={handlePrevClick}><ArrowLeftOutlined />Previous</Button>
                <Button type="primary" onClick={handleSubmitClick} disabled={disabled}>
                  Next {loading && disabled ? <Spin indicator={<LoadingOutlined style={{ fontSize: '16px', color: '#fff', marginLeft: '0.5rem' }} spin />} /> : <RightCircleOutlined />}
                </Button>
              </Row>
            </Form>
          </Card>
        </Row>
      </div>
      {duplicateEmails?.length > 0 ? <DuplicateEmailModal list={duplicateEmails} updateStep={updateStep} show={showDupModal} setShow={setShowDupModal} /> : null}
      {emailList?.length > 0 ?
        <ExistingEmailModal emailList={emailList} showModal={showModal} setShowModal={setShowModal} setDisabled={setDisabled} />
        : null}
    </>
  )
}


export { MatchColumns };
