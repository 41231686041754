import { UpdateApplicationPayload, WorkflowMetadata } from '@model/application';
import { COUNTRIES } from '@moxie/constants';
import {
  Option
} from '@shared-components/elements';
import { useContext } from 'react';
import ApplicationDetailContext from '../../../application-detail-context';
import useUpdateApplicationMutation from '../../../hooks/useUpdateApplicationMutation';
import { isEqual } from "lodash";
import { successNotificationHandler } from '@moxie/shared';
import { TextField, SelectField, DateField, RmaField } from './form-fields';
import { ActivitiesInfo, IField, IFormInitialInput, VisaFormInput } from '../fields-data';
import { parseDate } from './application-details-tab';
import { Moment } from 'moment';
import { SelectValue } from 'antd/lib/select';

interface Props {
  fields: IField<IFormInitialInput>[]
}

export const VisaForm: React.FC<Props> = ({ fields }) => {
  const { applicationData } = useContext(ApplicationDetailContext);
  const { workflowMetadata } = applicationData;
  const updateApplicationMutation = useUpdateApplicationMutation(
    applicationData,
    applicationData?.dealId
  );
  const initialInput:VisaFormInput= {
    visaTRN: workflowMetadata?.visaTRN ?? '',
    bridgingVisaType: workflowMetadata?.bridgingVisaType ?? '',
    HAPID: workflowMetadata?.HAPID ?? '',
    assessmentOccupation: workflowMetadata?.assessmentOccupation ?? '',
    skillsAssessmentBody: workflowMetadata?.skillsAssessmentBody ?? '',
  };
  const isCompleted = applicationData?.status === 'Completed';

  function updateData(
    payload: UpdateApplicationPayload,
    activitiesInfo: ActivitiesInfo,
    field: IField<IFormInitialInput>
  ) {
    if (payload.workflowMetadata && !(isEqual(payload.workflowMetadata[field.field as keyof WorkflowMetadata], initialInput[field.field as keyof VisaFormInput]))) {
      updateApplicationMutation.mutate(
        { payload, activitiesInfo },
        {
          onSuccess: () => {
            successNotificationHandler(`${field.label} updated successfully.`, 1.5);
          },
        }
      );
    }
  }

  return (
    <div className="form-container">
      {fields.map((field) => {
        const activitiesInfo: ActivitiesInfo = {
          activitiesType: 'application-misc',
          activitesLabel: field.label,
        };

        if (field.type === 'text' || field.type === 'number') {
          const getPayload = (workflowMetadata: WorkflowMetadata, field: string, input: string): UpdateApplicationPayload => {
            return {
              workflowMetadata: {
                ...workflowMetadata,
                [field]: input,
              },
            };
          }

          return (
            <TextField
              key={field.field}
              field={field}
              updateData={updateData}
              isCompleted={isCompleted}
              activitiesInfo={activitiesInfo}
              initialInput={workflowMetadata?.[field.field as keyof WorkflowMetadata] as string ?? ''}
              getPayload={getPayload}
              workflowMetadata={workflowMetadata}
            />
          )
        }

        if (field.type === 'date') {
          const getPayload = (workflowMetadata: WorkflowMetadata, data: Moment | null) => {
            return {
              workflowMetadata: {
                ...workflowMetadata,
                [field.field]: data?.format('DD/MM/YYYY') as string,
              },
            };
          };
          return (
            <DateField
              key={field.field}
              field={field}
              updateData={updateData}
              isCompleted={isCompleted}
              activitiesInfo={activitiesInfo}
              defaultValue={parseDate(workflowMetadata?.[field.field as keyof WorkflowMetadata] as string, "DD/MM/YYYY")}
              picker="date"
              getPayload={getPayload}
              workflowMetadata={workflowMetadata}
            />
          )
        }

        if (field.type === 'select-options') {
          const getPayload = (workflowMetadata: WorkflowMetadata, data: SelectValue) => {
            const payload = {
              workflowMetadata: {
                ...workflowMetadata,
                [field.field]: data as string,
              },
            };
            return payload
          };
          return (
            <SelectField
              key={field.field}
              field={field}
              isCompleted={isCompleted}
              activitiesInfo={activitiesInfo}
              optionFilter="label"
              updateData={updateData}
              defaultValue={workflowMetadata?.[field.field as keyof WorkflowMetadata]}
              getPayload={getPayload}
              workflowMetadata={workflowMetadata}
            >
              {COUNTRIES.map((item) => {
                return (
                  <Option
                    value={item.name}
                    key={item.code}
                    search_prop={`${item.name} ${item.code}`}
                  >
                    {item.name}
                  </Option>
                );
              })}
            </SelectField>
          )

        }

        if (field.type === 'rma-field') {
          return (
            <RmaField
              key={field.field}
              field={field}
              workflowMetadata={workflowMetadata}
              isCompleted={isCompleted}
              activitiesInfo={activitiesInfo}
              applicationAssignees={applicationData.assignees}
              updateData={updateData}
            />
          )
        }

        return null;
      })}
    </div>
  );
};
