import {
  fetchAppReportProductSubTypes,
  fetchAppReportProductTypes,
  fetchAppReportSubAgents,
  fetchAppReportSuperAgents,
  fetchCurrentStage,
  fetchInstitutionCountries,
  fetchInstitutionServices,
  fetchInstitutions,
  fetchWorkFlows,
  getApplicationContactState,
  getApplicationsReport,
  getApplicationsReportAssignees,
  getApplicationsReportBranches,
  getApplicationsReportFilters
} from '@crm/libs/services.api/lib/application.api';
import { PaginationMeta } from '@model/api-response.model';
import { errorNotificationHandler } from '@moxie/shared';
import { useQuery } from '@tanstack/react-query';

export const useGetApplicationsReport = (params?: Record<string, unknown>, filter?: any) => {
  const { data, isLoading } = useQuery({
    queryKey: ['contact-report', params, filter],
    queryFn: async () => await getApplicationsReport({ ...params, ...filter }),
    onError: () =>
      errorNotificationHandler('Error fetching Applications Report'),
  });
  return {
    applicationsReportData: data?.data?.data ?? [],
    isLoading,
    meta: data?.data?.meta as PaginationMeta,
    hasMorePage: Number(data?.data?.meta?.totalItems) <= 10,
  };
};

export const useGetApplicationsReportFilters = () => {
  const { data } = useQuery({
    queryKey: ['contact-report-filters'],
    queryFn: async () => await getApplicationsReportFilters(),
  });
  return {
    contactFiltersData: data?.data,
  };
};

export const useGetContactState = () => {
  const { data } = useQuery({
    queryKey: ['contact-report-state'],
    queryFn: async () => await getApplicationContactState(),
  });
  return {
    filtersData: data?.data,
  };
};

export const useGetApplicationsReportAssignees = () => {
  const { data } = useQuery({
    queryKey: ['contact-report-assignees'],
    queryFn: async () => await getApplicationsReportAssignees(),
  });
  return {
    assigneesData: data?.data?.data ?? [],
  };
};

export const useGetApplicationsReportBranches = () => {
  const { data, isLoading } = useQuery({
    queryKey: ['contact-report-branches'],
    queryFn: async () => await getApplicationsReportBranches(),
  });
  return {
    branchesData: data?.data,
    isLoading: isLoading,
  };
};

export const useFetchWorkFlows = () => {
  const { data } = useQuery({
    queryKey: ['contact-report-workflows'],
    queryFn: async () => await fetchWorkFlows(),
  });
  return {
    workFlows: data?.data ?? [],
    stages: [],
  };
};

export const useFetchInstitutions = () => {
  const { data } = useQuery({
    queryKey: ['contact-report-institutions'],
    queryFn: async () => await fetchInstitutions(),
  });
  return {
    institutions: data?.data ?? [],
  };
};

export const useFetchCurrentStages = () => {
  const { data } = useQuery({
    queryKey: ['contact-report-current-stages'],
    queryFn: () => fetchCurrentStage(),
  });
  return {
    currentStage: data?.data ?? [],
  };
}

export const useFetchProductTypes = () => {
  const { data } = useQuery({
    queryKey: ['contact-report-product-types'],
    queryFn: () => fetchAppReportProductTypes(),
  })
  return {
    productTypes: data?.data ?? [],
  }
}

export const useFetchProductSubTypes = () => {
  const { data } = useQuery({
    queryKey: ['contact-report-product-sub-types'],
    queryFn: () => fetchAppReportProductSubTypes(),
  })
  return {
    productSubTypes: data?.data ?? [],
  }
}

export const useFetchInstitutionServices = () => {
  const { data, isLoading } = useQuery({
    queryKey: ['contact-report-institution-services'],
    queryFn: () => fetchInstitutionServices(),
  });
  return {
    institutionServices: data?.data ?? [],
    isLoading: isLoading,
  };
};

export const useFetchInstitutionCountries = () => {
  const { data } = useQuery({
    queryKey: ['contact-report-institution-countries'],
    queryFn: () => fetchInstitutionCountries(),
  });
  return {
    institutionCountries: data?.data ?? [],
  };
};

export const useFetchAppReportSuperAgents = () => {
  const { data } = useQuery({
    queryKey: ['contact-report-super-agents'],
    queryFn: () => fetchAppReportSuperAgents(),
  });
  return {
    superAgents: data?.data ?? [],
  };
};

export const useFetchAppReportSubAgents = () => {
  const { data } = useQuery({
    queryKey: ['contact-report-sub-agents'],
    queryFn: () => fetchAppReportSubAgents(),
  });
  return {
    subAgents: data?.data ?? [],
  };
};
