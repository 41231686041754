import React from "react";
import Form, { FormItemProps } from "antd/es/form";
import { Input } from "antd";
import Select from "antd/es/select";
import { COUNTRIES, LABEL } from "@moxie/constants";
//@ts-ignore
import ReactCountryFlag from 'react-country-flag';
import Row from "antd/es/row";
import Col from "antd/es/col";
import { RequiredFields } from "@model/module.props";

const { Option } = Select;

interface IProps {
  labelClassName?: string;
  initialCountryCode?: string;
  requiredMark?: boolean;
  countryFormItemProps: RequiredFields<FormItemProps, 'name'>;
  phoneFormItemProps: RequiredFields<FormItemProps, 'name'>;
}

export const PhoneWithCountryCodeFormItem = ({
  labelClassName,
  requiredMark = false,
  countryFormItemProps = {
    name: 'countryCode',
    initialValue: '+61',
    noStyle: true,
  },
  phoneFormItemProps = {
    name: 'phone',
  },
}: IProps) => {
  return (
    <>
      <Form.Item label={LABEL.PHONE} className={labelClassName} required={requiredMark}>
        <Row align="top">
          <Input.Group compact>
            <Col span={10}>
              <Form.Item {...countryFormItemProps}>
                <Select
                  className="input_country_select"
                  showSearch
                  optionFilterProp="search_prop"
                >
                  {COUNTRIES.map((item) => {
                    return (
                      <Option
                        value={item.dial_code}
                        key={item.code}
                        className="input_country_select"
                        search_prop={`${item.dial_code} ${item.name}`}
                      >
                        <ReactCountryFlag countryCode={item.code} svg />
                        <span className="margin-left-5-pixel">{item.dial_code}</span>
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>

            <Col span={14}>
              <Form.Item {...phoneFormItemProps}>
                <Input />
              </Form.Item>
            </Col>
          </Input.Group>
        </Row >
      </Form.Item>
    </>
  )
}
