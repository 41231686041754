import { useAppSelector } from '@crm/core';
import useContactParam from '@crm/libs/hooks/contacts/useContactParam';
import {
  getCRMApplicationComments,
  postCRMApplicationComments
} from '@crm/libs/services.api/lib/application.api';
import { CrmPaginatedResponse } from '@model/api-response.model';
import { ApplicationComment, ApplicationCommentPayload, ApplicationDetail } from '@model/application';
import {
  errorNotificationHandler,
  successNotificationHandler
} from '@moxie/shared';
import {
  useInfiniteQuery,
  useMutation,
  useQueryClient
} from '@tanstack/react-query';

export const usePostContactApplicationComments = (application: ApplicationDetail) => {
  const queryClient = useQueryClient();
  const socket = useAppSelector(state => state.socket.wss);
  const user = useAppSelector(state => state.auth.user);
  const contactId = useContactParam();
  const id = application.id as string;
  return useMutation({
    mutationFn: (body: ApplicationCommentPayload) => postCRMApplicationComments(body),
    onSuccess: ({ data }: any) => {
      successNotificationHandler("Comment added successfully.");
      queryClient.invalidateQueries({
        queryKey: ['CRMApplicationComments'],
      });

      socket?.emit("server::application-updated", {
        activitiesType: 'application-comment',
        activitiesAction: 'created',
        data: {
          data: {
            currentStage: application.activeStage,
            id: application.id,
            appIdentifier: application.appIdentifier,
            contactId: application.contactId,
            applicationStatus: application.status,
          },
        },
        activitiesTypeId: id,
        userId: user?.id as string,
        companyId: user?.companyId as string,
        contactId: contactId,
      })
    },
    onError: () => {
      errorNotificationHandler('Error adding comments');
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['CRMApplicationComments', id],
      });
    },
  });
};

export const useGetContactApplicationComments = (id: string) => {
  const query = useInfiniteQuery<CrmPaginatedResponse<ApplicationComment>>({
    queryKey: ['CRMApplicationComments', id],
    queryFn: async (args) => {
      const res = await getCRMApplicationComments({
        'filter.applicationId': id,
        page: args.pageParam ?? 1,
        limit: 10,
      });
      return res.data;
    },
    enabled: true,
    onError: () => {
      errorNotificationHandler('Error fetching comments');
    },
    getNextPageParam: (lastPage) => {
      return lastPage.data.meta.currentPage === lastPage.data.meta.totalPages
        ? false
        : lastPage.data.meta.currentPage + 1;
    },
  });
  return {
    data: query.data?.pages.map((p) => p.data.data).flat() ?? [],
    hasMore: Boolean(query.hasNextPage),
    next: query.fetchNextPage,
    currentPage: query.data?.pages.at(-1)?.data.meta.currentPage,
    isLoading: query.isLoading,
  };
};
