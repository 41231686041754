import React, { useMemo } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import {
  ADD_BTN,
  ENGLISH_LANGUAGE_REQUIREMENT,
  ENGLISH_LANGUAGE_REQUIREMENT_CONTENT
} from '@moxie/constants';
import { Button, Col, Dropdown, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { languageRequirementMenu } from './language-requirement-menu';
import ProductLanguageRequirementsTable from './product-language-requirement-table';
import ProductLanguageRequirementsDrawer from './product-language-requirements-drawer';
import { useGetAllCRMProductLanguageRequirements } from '../../../hooks/useGetProductLanguageRequirement';
import { useCanAccess } from '@crm/libs/privilege/useCanAccess';

const ProductLanguageRequirements = () => {
  const { productId } = useParams<{ productId: string }>();

  const [isEdit, setIsEdit] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [languageMode, setLanguageMode] = useState<string>();
  const [requirementRef, setRequirementRef] = useState<string | undefined>();
  const canAccess = useCanAccess();
  const [languageForm] = useForm();

  const {
    data: languageRequirementData,
  } = useGetAllCRMProductLanguageRequirements(productId);

  const {
    data: requirements,
    isLoading,
  } = useGetAllCRMProductLanguageRequirements(productId);

  const data = useMemo(() => {
    return requirements?.find((req: any) => req?.id === requirementRef);
  }, [requirementRef, requirements]);

  const handleShowDrawer = (name: string) => {
    setLanguageMode(name);
    setIsDrawerOpen(!isDrawerOpen);
    setIsEdit(Boolean(requirementRef));
  };

  const handleLanguageDrawerClose = () => {
    setIsDrawerOpen(false);
    setIsEdit(false);
    languageForm.resetFields();
    setRequirementRef(undefined);
  };

  const openEditDrawer = (id: string) => {
    setRequirementRef(id);
    setIsEdit(true);
    setIsDrawerOpen(true);
  };
  return (
    <>
      <Row justify="space-between" className="margin-top-2 margin-left-2">
        <Col>
          <h1>{ENGLISH_LANGUAGE_REQUIREMENT}</h1>
          <div>{ENGLISH_LANGUAGE_REQUIREMENT_CONTENT}</div>
        </Col>
        {canAccess('product-requirement','create') && <Col>
          <Dropdown
            overlay={languageRequirementMenu(
              handleShowDrawer,
              languageRequirementData && languageRequirementData
            )}
            overlayClassName="z-index-zero"
            trigger={['click']}
            disabled={
              languageRequirementData &&
              languageRequirementData?.length >= 3 &&
              true
            }
          >
            <Button type="primary" icon={<PlusOutlined />}>
              {ADD_BTN}
            </Button>
          </Dropdown>
        </Col>}
      </Row>
      <Row className="margin-top-2 margin-left-1" justify="start">
        <Col span={24}>
          <ProductLanguageRequirementsTable
            requirements={requirements}
            isLoading={isLoading}
            openEditDrawer={openEditDrawer}
          />
        </Col>
      </Row>
      <ProductLanguageRequirementsDrawer
        isEdit={isEdit}
        name={isEdit ? data?.testName : languageMode}
        isOpen={isDrawerOpen}
        form={languageForm && languageForm}
        initialData={data}
        onClose={handleLanguageDrawerClose}
      />
    </>
  );
};

export default ProductLanguageRequirements;
