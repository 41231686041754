import React, { useCallback, useEffect, useState } from 'react';
import {
  SLUG_ALL_CONTACTS,
  SLUG_ARCHIVED,
  SLUG_MY_CONTACTS,
  TEXT
} from '@moxie/constants';
import { PageHeaderTitle } from '@shared-components/elements';
import { useLocation, useParams } from 'react-router-dom';
import { LeadAddEdit } from './components/contact-add-edit';
import { IContactCrm } from '@shared-components/models';
import { useDispatch } from 'react-redux';
import { themeActions, useAppSelector, userActions } from '@crm/core';
import { ContactTableList } from './components/contact-add-edit/contact-table';
import { PageHeaderMenu } from './components/page-header-menu';
import { useAssignedBranch, useAssigneeChanged } from '@crm/libs/hooks/contacts';
import { ImportDrawer } from './components/import/import-drawer';
import { StepProvider } from './components/import/import-context';
import ForbiddenAccess from '../forbidden-access/forbidden-access';
import { BranchPermission } from '@model/permission.model';

const useMenus = (branchPermission: BranchPermission | null) => {
  const menus = [
    {
      name: 'All Contacts',
      id: SLUG_ALL_CONTACTS,
      url: `contacts/${SLUG_ALL_CONTACTS}`,
    },
    {
      name: 'My Contacts',
      id: SLUG_MY_CONTACTS,
      url: `contacts/${SLUG_MY_CONTACTS}`,
      canAccess: !branchPermission?.payload.permissions.contact?.includes('unassigned'),
    },
    {
      name: 'Archived',
      id: SLUG_ARCHIVED,
      url: `contacts/${SLUG_ARCHIVED}`,
    },
  ];

  return menus.filter(menu => !menu.canAccess)
}
const ContactsPage = () => {
  const { name } = useParams<{ name: string }>();

  const [branch, setBranch] = useState<string>();
  const [disabled, setDisabled] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [initialData, setInitialData] = useState<IContactCrm>();
  const [importDrawerOpen, setImportDrawerOpen] = useState<boolean>(false);

  const dispatch = useDispatch();
  const { refresh, triggerRefresh } = useAssigneeChanged();
  const currentUser = useAppSelector((store) => store.auth.user);

  const handleAddContact = () => {
    dispatch(userActions.fetchUsers());
    setIsDrawerOpen(true);
    setIsEditMode(false);
  };

  const handleContactImport = () => {
    setImportDrawerOpen(true);
    dispatch(themeActions.toggleNavbar(true));
  };

  const onClose = () => {
    setIsDrawerOpen(false);
    setImportDrawerOpen(false);
    setDisabled(true);
    setIsSubmitted(false);
    dispatch(themeActions.toggleNavbar(false));
  };

  const { branches } = useAssignedBranch();
  const location = useLocation();
  const branchPermission = useAppSelector(store => store.auth.authUserPermission);

  useEffect(() => {
    if (branches.length > 0 && branches.find(b => b.id === currentUser?.branchId)) {
      const params = new URLSearchParams(location.search.replace('?', ''));
      const preSelectedBranch = params.get('filter.branch');
      if (preSelectedBranch) {
        setBranch(preSelectedBranch);
      } else {
        setBranch(currentUser?.branchId)
      }
    }
  }, [branches, currentUser])

  const menus = useMenus(branchPermission);
  const setDisabledMemoized = useCallback((state: boolean) => {
    setDisabled(state);
  }, []);

  if (!branchPermission?.payload.permissions.contact?.length) {
    return <ForbiddenAccess />
  }

  return (
    <div className="full-height">
      <PageHeaderTitle title={TEXT.CONTACTS} />
      <PageHeaderMenu menu={menus} defaultActiveKey={name} />
      <LeadAddEdit
        setDisabled={setDisabledMemoized}
        submitted={isSubmitted}
        setSubmitted={setIsSubmitted}
        disabled={disabled}
        isOpen={isDrawerOpen}
        onClose={onClose}
        initialData={initialData}
        triggerRefresh={triggerRefresh}
        isEdit={isEditMode}
      />
      <ContactTableList
        branch={branch}
        setBranch={setBranch}
        handleAddContact={handleAddContact}
        refresh={refresh}
        triggerRefresh={triggerRefresh}
        setInitialData={setInitialData}
        setIsDrawerOpen={setIsDrawerOpen}
        setIsEditMode={setIsEditMode}
        handleContactImport={handleContactImport}
      />
      <StepProvider>
        <ImportDrawer drawerOpen={importDrawerOpen} handleClose={onClose} />
      </StepProvider>
    </div>
  );
};

export { ContactsPage };
