import { LABEL } from '@moxie/constants';
import { capitalizeFirstLetter } from '@moxie/shared';
import { DatePicker, Form, FormInstance, Select } from 'antd';
import React from 'react';
import {
  useFetchInstitutionCountries,
  useFetchInstitutionServices,
  useFetchInstitutions,
  useFetchProductSubTypes,
  useFetchProductTypes,
  useGetApplicationsReportBranches,
  useGetApplicationsReportFilters
} from '../hooks/useGetApplicationsContact';
import { IReportWorkflow } from '@model/crm/application-report.model';
import { fetchAppReportSubAgents, fetchAppReportSuperAgents, getApplicationsReportAssignees } from '@crm/libs/services.api/lib/application.api';
import { SearchBox } from 'libs/shared/src/crm-modules/shared';

export interface IApplicationReportForm {
  form: FormInstance<any>;
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: (values: any) => void;
  workFlows: IReportWorkflow[];
  stages: { id: string; stage: string }[];
}

const ApplicationReportForm: React.FC<IApplicationReportForm> = ({
  form,
  setDisabled,
  onSubmit,
  workFlows,
  stages,
}) => {
  const { RangePicker } = DatePicker;
  const { contactFiltersData: filtersData } = useGetApplicationsReportFilters();
  const { institutions } = useFetchInstitutions();
  const { branchesData } = useGetApplicationsReportBranches();
  const { productTypes } = useFetchProductTypes();
  const { productSubTypes } = useFetchProductSubTypes();
  const { institutionServices: services } = useFetchInstitutionServices();
  const { institutionCountries } = useFetchInstitutionCountries();

  const statusList = ['In Progress', 'Discontinued', 'Completed'];


  const handleFieldsChange = (allFields: string | any[]) => {
    if (allFields.length > 0) {
      for (const field of allFields) {
        field.errors.length < 1 ? setDisabled(false) : setDisabled(true);
      }
    }
  };

  return (
    <div>
      <Form
        form={form}
        name="application-report-form"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        onFinish={onSubmit}
        layout="horizontal"
        onFieldsChange={handleFieldsChange}
        initialValues={{
          createdAt: [],
          startDate: [],
          endDate: [],
        }}
      >
        <Form.Item name="state" label={LABEL.CONTACT_STATE}>
          <Select
            mode="multiple"
            placeholder="Select Contact State"
            getPopupContainer={(trigger) => trigger.parentNode}
            allowClear
            options={filtersData?.states?.map((state: string) => ({
              value: capitalizeFirstLetter(state),
            }))}
          />
        </Form.Item>
        <Form.Item name="country" label={LABEL.COUNTRY_OF_RESIDENCE}>
          <Select
            mode="multiple"
            placeholder="Select Contact Country of Residence"
            getPopupContainer={(trigger) => trigger.parentNode}
            allowClear
            options={filtersData?.countries?.map((country: string) => ({
              value: country,
            }))}
          />
        </Form.Item>
        <Form.Item name="countryOfPassport" label={LABEL.COUNTRY_OF_PASSPORT}>
          <Select
            mode="multiple"
            placeholder="Select Country of Passport"
            getPopupContainer={(trigger) => trigger.parentNode}
            allowClear
            options={filtersData?.passportCountries?.map(
              (countryOfPassport: string) => ({
                value: countryOfPassport,
              })
            )}
          />
        </Form.Item>
        <Form.Item name="createdAt" label={LABEL.ADDED_DATE}>
          <RangePicker
            placeholder={['Start Date', 'End Date']}
            className="full-width"
          />
        </Form.Item>
        <Form.Item name="assignees" label={LABEL.ASSIGNEE}>
          <SearchBox
            fetchQuery={getApplicationsReportAssignees}
            valueKey="id"
            hideDefaultOptionView
            optionLabelProp="label"
            defaultOpen={false}
            allowClear
            showArrow
            mode="multiple"
            placeholder="Select Assignee"
          >
            {
              (option) =>
                <Select.Option value={option.id} label={`${capitalizeFirstLetter(option.firstName)}  ${capitalizeFirstLetter(option.lastName)}`}>
                  {capitalizeFirstLetter(option.firstName) + " " + capitalizeFirstLetter(option.lastName)}
                </Select.Option>
            }
          </SearchBox>
        </Form.Item>
        <Form.Item name="branch" label={LABEL.PROCESSING_BRANCH}>
          <Select
            mode="multiple"
            placeholder="Select Processing Branch"
            getPopupContainer={(trigger) => trigger.parentNode}
            allowClear
            options={branchesData?.map((branch: { id: string; name: string }) => ({
              value: branch?.id,
              label: capitalizeFirstLetter(branch?.name),
            }))}
          />
        </Form.Item>
        <Form.Item name="service" label={LABEL.SERVICE}>
          <Select
            mode="multiple"
            placeholder="Select Service"
            getPopupContainer={(trigger) => trigger.parentNode}
            allowClear
            options={services?.map((service) => ({
              value: service?.id,
              label: service?.name,
            }))}
          />
        </Form.Item>
        <Form.Item name="workflow" label={LABEL.WORKFLOW}>
          <Select
            mode="multiple"
            placeholder="Select Workflow"
            getPopupContainer={(trigger) => trigger.parentNode}
            allowClear
            options={workFlows.map((w) => ({
              value: w.id,
              label: capitalizeFirstLetter(w.workflowType.name) ?? '-',
            }))}
          />
        </Form.Item>
        <Form.Item name="institution" label={LABEL.APPLIED_INSTITUTION}>
          <Select
            mode="multiple"
            placeholder="Select Applied Institution"
            getPopupContainer={(trigger) => trigger.parentNode}
            allowClear
            options={institutions?.map((data) => ({
              value: data?.id,
              label: data?.name,
            }))}
          />
        </Form.Item>
        <Form.Item name="institutionCountry" label={LABEL.INSTITUTION_COUNTRY}>
          <Select
            mode="multiple"
            placeholder="Select Institution Country"
            getPopupContainer={(trigger) => trigger.parentNode}
            allowClear
            options={institutionCountries?.map((data: { country: string }) => ({
              value: data?.country,
              label: data?.country,
            }))}
          />
        </Form.Item>
        <Form.Item name="productType" label={LABEL.PRODUCT_TYPE}>
          <Select
            mode="multiple"
            placeholder="Select Product Type"
            getPopupContainer={(trigger) => trigger.parentNode}
            allowClear
            options={productTypes.map((name) => ({
              value: name,
              label: name,
            }))}
          />
        </Form.Item>
        <Form.Item name="productSubType" label={LABEL.PRODUCT_SUB_TYPE}>
          <Select
            mode="multiple"
            placeholder="Select Product Sub Type"
            getPopupContainer={(trigger) => trigger.parentNode}
            allowClear
            options={productSubTypes.map((name) => ({
              value: name,
              label: name,
            }))}
          />
        </Form.Item>
        <Form.Item name="status" label={LABEL.STATUS}>
          <Select
            mode="multiple"
            placeholder="Select Status"
            getPopupContainer={(trigger) => trigger.parentNode}
            allowClear
            options={statusList?.map((item) => ({
              value: item,
              label: item,
            }))}
          />
        </Form.Item>
        <div id="institution_currency" className="relative">
          <Form.Item name="currentStage" label={LABEL.CURRENT_STAGE}>
            <Select
              mode="multiple"
              optionFilterProp="search_prop"
              placeholder="Select Current Stage"
              getPopupContainer={(trigger) => trigger.parentNode}
              allowClear
              options={stages?.map((item) => ({
                value: item?.id,
                label: capitalizeFirstLetter(item?.stage),
              }))}
            />
          </Form.Item>
        </div>
        <Form.Item name="startDate" label={LABEL.START_DATE}>
          <RangePicker
            className="full-width"
            placeholder={['Start Date', 'End Date']}
          />
        </Form.Item>
        <Form.Item name="endDate" label={LABEL.END_DATE}>
          <RangePicker
            className="full-width"
            placeholder={['Start Date', 'End Date']}
          />
        </Form.Item>
        <Form.Item name="superAgent" label={LABEL.SUPER_AGENT}>
          <SearchBox
            fetchQuery={fetchAppReportSuperAgents}
            valueKey="id"
            showArrow
            mode="multiple"
            placeholder="Select Super Agent"
            allowClear
            optionLabelProp="label"
            hideDefaultOptionView
            defaultOpen={false}
          >
            {
              (option) =>
                <Select.Option value={option.id} label={capitalizeFirstLetter(option.name)} >
                  {capitalizeFirstLetter(option.name)}
                </Select.Option>
            }
          </SearchBox>
        </Form.Item>
        <Form.Item name="subAgent" label={LABEL.SUB_AGENT}>
          <SearchBox
            fetchQuery={fetchAppReportSubAgents}
            valueKey="id"
            showArrow
            mode="multiple"
            optionLabelProp="label"
            placeholder="Select Sub Agent"
            allowClear
            hideDefaultOptionView
            defaultOpen={false}
          >
            {
              (option) =>
                <Select.Option value={option.id} label={capitalizeFirstLetter(option.name)} >
                  {capitalizeFirstLetter(option.name)}
                </Select.Option>
            }
          </SearchBox>
        </Form.Item>
      </Form>
    </div>
  );
};
export default ApplicationReportForm;
