import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import Form from 'antd/es/form';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Select from 'antd/es/select';
import Input from 'antd/es/input';

import type { FormItemProps } from 'antd';

import { COUNTRIES, COUNTRY_PLACEHOLDER, FEE_NAME_MAX_LENGTH, FEE_NAME_REQUIRED, LABEL, PRODUCT_FEE_RESPONSE_MESSAGE, TEXT } from '@moxie/constants';

import { validateProductFeeName } from '@crm/libs/services.api/lib';
import { IProductFeeCrm } from '../../product.model';

interface Props {
    fee: IProductFeeCrm | undefined
}

const ProductFeeForm: React.FC<Props> = ({ fee }) => {
  const { productId }: { productId: string } = useParams();

  const [, setFeeValidationStatus] = useState<
        FormItemProps['validateStatus']
    >('');

  const handleFeeNameChange = async (rule: unknown, value: string): Promise<any> => {
    value = value?.trim();
    value = value?.toLowerCase().replace(/  + /g, ' ');

    if (!value) {
      setFeeValidationStatus('error');
      return Promise.resolve(true);
    }

    if (value?.length > 50) {
      setFeeValidationStatus('error');
      return Promise.resolve(true);
    }
    if (value?.length === 0) {
      setFeeValidationStatus('error');
      return Promise.resolve(true);
    }
    else {
      setFeeValidationStatus('validating');
      if (value !== '' || null || undefined) {
        const result = await validateProductFeeName({
          name: value,
          productId,
          id: fee?.id,
        });
        if (result.status === 204) {
          setFeeValidationStatus('success');
          return Promise.resolve(true);
        } else {
          setFeeValidationStatus('error');
          return Promise.reject(
            new Error(PRODUCT_FEE_RESPONSE_MESSAGE.FEE_NAME_ALREADY_EXISTS)
          );
        }
      }
    }
  };

  const handleCountryFilter = (input: string, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  return (
    <Row gutter={14}>
      <Col span={12}>
        <Form.Item
          label={LABEL.FEE_OPTION_NAME}
          rules={[
            { required: true },
            { max: 50, message: FEE_NAME_MAX_LENGTH },
            { whitespace: true, type: 'string', message: FEE_NAME_REQUIRED },
            { validator: handleFeeNameChange },
          ]}
          name="feeName"
        >
          <Input disabled={fee?.feeName === TEXT.DEFAULT_FEE} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="country"
          label={LABEL.COUNTRY_OF_RESIDENCE}
          rules={[{required: true}]}
          initialValue={TEXT.ALL_COUNTRIES}
        >
          <Select
            placeholder={COUNTRY_PLACEHOLDER}
            showSearch
            filterOption={handleCountryFilter}
            disabled
            options={COUNTRIES.map(c => ({ label: c.name, value: c.code }))}
          />
        </Form.Item>
      </Col>
    </Row>
  )
}

export default ProductFeeForm
