import React, { useMemo, useState } from "react";
import Modal from "antd/es/modal";
import { Form, Typography } from "antd";
import Select from "antd/es/select";
import Row from "antd/es/row";
import QRCode from "react-qr-code";
import CopyableInputText from "@crm/src/shared/copyable-input-text/copyable-input-text";
import { srcOptions } from "../../constants";
import { ShareModalType } from "../enquiry-forms-table";
import { useFetchBranchCountriesList } from "@crm/libs/hooks/branches/useFetchBranchesStatesByCountry";
import { IAddressBranch } from "@model/crm/enquiry-form.model";

interface ShareUrlModalProps {
  shareModal: ShareModalType;
  setShareModal: React.Dispatch<React.SetStateAction<ShareModalType>>;
}
interface UrlAddress {
  country?: string;
  branchName?: string;
  state?: string;
  branch?: string;
}

const sortAlphabetically = (a: string, b: string) => a.localeCompare(b);

const getQueryStrings = (address: UrlAddress): string => {
  let result = ''
  for (const a in address) {
    const key = a as keyof UrlAddress;
    if (address[key]) {
      result = result.concat('&' + key + '=' + address[key]);
    }
  }
  return result
}

const ShareUrlModal = ({ shareModal, setShareModal }: ShareUrlModalProps) => {
  const [source, setSource] = useState(shareModal !== "none" ? srcOptions[shareModal][0] : "");
  const [address, setAddress] = useState<UrlAddress>({
    country: undefined,
    branchName: undefined,
    state: undefined,
    branch: undefined,
  })

  const { countriesList, isLoading, branchesByCountry } = useFetchBranchCountriesList();

  const url = `${window.location.origin}/enquiry-form?source=${source}${getQueryStrings(address)}`;

  const branchDataList = useMemo(() => {
    if (address?.country && branchesByCountry[address?.country]) {
      const sortedBranchList = branchesByCountry[address.country].sort((a, b) => sortAlphabetically(a?.branch?.name ?? '', b?.branch?.name ?? ''))
      return sortedBranchList;
    }
    return [];
  }, [address.country, branchesByCountry])

  return (
    <Modal visible={shareModal !== "none"}
      centered
      title={<Typography.Text style={{ fontWeight: '700' }}>Sharing Options</Typography.Text>}
      onCancel={() => setShareModal('none')}
      footer={null}
    >
      <Form layout="vertical" className="padding-1">
        <Form.Item
          label="Country"
        >
          <Select
            value={address.country}
            placeholder="Please select country"
            showSearch
            allowClear
            loading={isLoading}
            optionFilterProp="label"
            onChange={(country: string) => setAddress(prev => ({ ...prev, country, branchName: undefined, state: undefined, branch: undefined }))}
          >
            {countriesList.map((country, index) => (
              <Select.Option value={country} key={index} label={country}>
                {country}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Branch"
        >
          <Select
            value={address.branchName}
            placeholder="Please select branch"
            showSearch
            allowClear
            optionFilterProp="label"
            onChange={(value: string | undefined) => {
              if (value) {
                const addressObj = JSON.parse(value) as IAddressBranch;
                setAddress(prev => ({ ...prev, branchName: addressObj?.branch?.name ?? '', state: addressObj?.state, branch: addressObj?.branch?.id ?? '' }))
              } else {
                setAddress(prev => ({ ...prev, branchName: undefined, branch: undefined }))
              }
            }}
          >
            {branchDataList.map((address) => (
              <Select.Option value={JSON.stringify(address)} key={address?.branch?.id} label={address?.branch?.name ?? ''}>
                {address?.branch?.name}
              </Select.Option>
            ))
            }
          </Select>
        </Form.Item>

        <Form.Item label="Source">
          <Select value={source} showArrow onChange={setSource}>
            {shareModal !== "none" && srcOptions[shareModal]?.map((src, index) =>
              (<Select.Option key={index} value={src}>{src}</Select.Option>)
            )}
          </Select>
        </Form.Item>
        <Row align="middle" justify="center" className="margin-bottom-2">
          <QRCode value={url} size={150} />
        </Row>
        <Form.Item label="Shareable Link">
          <CopyableInputText key={`text-${source}`} value={url} />
        </Form.Item>
        <Form.Item label="Embed Code">
          <CopyableInputText key={`iframe-${source}`} value={`<iframe src="${url}" title="${url}"></iframe>`} />
        </Form.Item>
      </Form>
    </Modal >
  )
}

export default ShareUrlModal;
