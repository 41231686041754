import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Badge, Row } from 'antd';
import { useAppSelector } from '@crm/core';
import { LABEL, TEXT, URL_APPLICATION } from '@moxie/constants';
import {
  capitalizeFirstLetter,
  formatDateWithTimezone
} from '@moxie/shared';
import { IApplicationReportCrm, IReportAgentPartner, IReportClient, IReportInstitution } from '@model/crm/application-report.model';
import { IUserData } from 'libs/shared/src/crm-modules/user/user.model';
import { ApplicationReportAssignee } from './application-report-assignee';
import { Workflow, WorkflowApplicationStage } from '@model/workflow';

export const ApplicationReportColumns = () => {
  const userTimeZone =
    useAppSelector((store: any) => store?.auth?.user?.time_zone) || '';

  return [
    {
      title: LABEL.APPLICATION_ID,
      width: 130,
      dataIndex: 'id',
      ellipsis: true,
      render(id: string, value: IApplicationReportCrm): ReactNode {
        return (
          <Link
            to={`${URL_APPLICATION}/detail/${value?.client?.id}/${id}`}
            className="primary-id-color"
          >
            {`${value?.appIdentifier}`}
          </Link>
        );
      },
    },
    {
      title: LABEL.CONTACT_ID,
      width: 120,
      dataIndex: 'client',
      ellipsis: true,
      render: (client: IReportClient): ReactNode => {
        return (
          <div className="lead-table__city">
            <Link
              to={`/contact/detail/${client?.id}/deals`}
              className="text-primary-color"
            >
              {`${client?.internalId}`}
            </Link>
          </div>
        );
      },
    },
    {
      title: LABEL.CONTACT_NAME,
      width: 180,
      dataIndex: 'client',
      ellipsis: true,
      render(client: IReportClient): React.ReactElement {
        return (
          <Link
            to={`/contact/detail/${client?.id}/deals`}
            className="text-primary-color initial_capital"
          >
            {`${client?.firstName} ${client?.lastName}`}
          </Link>
        );
      },
    },
    {
      title: LABEL.PHONE,
      width: 140,
      ellipsis: true,
      dataIndex: 'client',
      render: (data: IReportClient): ReactNode => {
        return data?.phone ? `${data?.countryCode} ${data?.phone}` : '-';
      },
    },
    {
      title: LABEL.EMAIL,
      width: 150,
      dataIndex: 'client',
      ellipsis: true,
      render: (data: IReportClient): ReactNode => {
        return (
          <a className="text-primary-color" href={`mailto:${data?.email}`}>
            {data?.email ?? '-'}
          </a>
        );
      },
    },
    {
      title: LABEL.DATE_OF_BIRTH,
      width: 120,
      dataIndex: 'client',
      ellipsis: true,
      render: (value: IReportClient): ReactNode => {
        return value?.dateOfBirth
          ? moment(value?.dateOfBirth).format('MMM DD, YYYY')
          : '-';
      },
    },
    {
      title: LABEL.CONTACT_STATE,
      width: 140,
      dataIndex: 'client',
      ellipsis: true,
      render: (value: IReportClient): ReactNode => {
        return value?.state ? capitalizeFirstLetter(value?.state) : '-';
      },
    },
    {
      title: LABEL.COUNTRY_OF_RESIDENCE,
      width: 180,
      dataIndex: 'client',
      ellipsis: true,
      render: (value: IReportClient): ReactNode => {
        return value?.country ?? '-';
      },
    },
    {
      title: LABEL.COUNTRY_OF_PASSPORT,
      width: 170,
      dataIndex: 'client',
      ellipsis: true,
      render: (value: IReportClient): ReactNode => {
        return value?.countryOfPassport ?? '-';
      },
    },
    {
      title: LABEL.VISA_EXPIRY_DATE,
      width: 150,
      dataIndex: 'client',
      ellipsis: true,
      render: (value: IReportClient): ReactNode => {
        return (
          <div className="lead-table__visa-expiry-date">
            {value?.visaExpiryDate
              ? moment(value?.visaExpiryDate).format('MMM DD, YYYY')
              : '-'}
          </div>
        );
      },
    },
    {
      title: LABEL.ADDED_DATE,
      width: 120,
      dataIndex: 'createdAt',
      ellipsis: true,
      render: (value: string): ReactNode => {
        return formatDateWithTimezone(value, userTimeZone);
      },
    },
    {
      title: LABEL.ASSIGNEE,
      width: 100,
      dataIndex: 'assignees',
      ellipsis: true,
      render(data: IUserData[]): React.ReactElement {
        return <ApplicationReportAssignee data={data} />;
      },
    },
    {
      title: LABEL.PROCESSING_BRANCH,
      width: 150,
      dataIndex: 'processingBranch',
      ellipsis: true,
      render: (data: { id: string; name: string; }): ReactNode => {
        return data?.name ?? '-';
      },
    },
    {
      title: LABEL.SERVICE,
      width: 100,
      dataIndex: 'institution',
      ellipsis: true,
      render: (data: IReportInstitution): ReactNode => {
        return data?.service?.name ?? '-';
      },
    },
    {
      title: LABEL.WORKFLOW,
      width: 100,
      dataIndex: 'workflow',
      ellipsis: true,
      render: (data: Pick<Workflow, 'country' | 'workflowType'>): ReactNode => {
        return data?.workflowType?.name ?? '-';
      },
    },
    {
      title: LABEL.APPLIED_INSTITUTION,
      width: 170,
      dataIndex: 'institution',
      ellipsis: true,
      render: (data: IReportInstitution): ReactNode => {
        return data?.name ?? '-';
      },
    },
    {
      title: LABEL.APPLIED_PRODUCT,
      width: 150,
      dataIndex: 'product',
      ellipsis: true,
      render: (data: { id: string; name: string }): ReactNode => {
        return capitalizeFirstLetter(data?.name) ?? '-';
      },
    },
    {
      title: LABEL.INSTITUTION_COUNTRY,
      width: 170,
      dataIndex: 'institution',
      ellipsis: true,
      render: (data: IReportInstitution): ReactNode => {
        return data?.country ?? '-';
      },
    },
    {
      title: LABEL.INSTITUTION_BRANCH,
      width: 150,
      dataIndex: 'institutionBranch',
      ellipsis: true,
      render: (data: { id: string; name: string }): ReactNode => {
        return data?.name ?? '-';
      },
    },
    {
      title: LABEL.PRODUCT_TYPE,
      width: 120,
      dataIndex: 'productType',
      ellipsis: true,
      render: (data: string): ReactNode => {
        return data ?? '-';
      },
    },
    {
      title: LABEL.PRODUCT_SUB_TYPE,
      width: 150,
      dataIndex: 'productSubType',
      ellipsis: true,
      render: (data: string): ReactNode => {
        return data ?? '-';
      },
    },
    {
      title: LABEL.STATUS,
      width: 120,
      ellipsis: true,
      dataIndex: 'status',
      render: (value: string): ReactNode => {
        return value ? (
          <>
            <Row>
              {value === 'Completed' ? (
                <Badge
                  status="success"
                  color="green"
                  text={value}
                  style={{ color: 'green' }}
                />
              ) : value === 'In Progress' ? (
                <Badge status="processing" text={value} />
              ) : (
                <Badge color="red" text={value} style={{ color: 'red' }} />
              )}
            </Row>
          </>
        ) : (
          '-'
        );
      },
    },
    {
      title: LABEL.CURRENT_STAGE,
      width: 120,
      ellipsis: true,
      dataIndex: 'activeStage',
      render: (value: WorkflowApplicationStage): ReactNode => {
        return capitalizeFirstLetter(value?.stage) ?? '-';
      },
    },
    {
      title: LABEL.START_DATE,
      dataIndex: 'startDate',
      width: 120,
      ellipsis: true,
      render: (data: string): ReactNode => {
        return data ?? '-';
      },
    },
    {
      title: LABEL.END_DATE,
      dataIndex: 'endDate',
      width: 120,
      ellipsis: true,
      render: (data: string): ReactNode => {
        return data ?? '-';
      },
    },
    {
      title: TEXT.FEE,
      width: 100,
      ellipsis: true,
      dataIndex: 'productFeeAmount',
      render: (productFeeAmount: string, value: IApplicationReportCrm): ReactNode => {
        return (
          `${value?.productFeeCurrency} ${productFeeAmount}` ?? '-'
        );
      },
    },
    {
      title: TEXT.SUPER_AGENT,
      width: 120,
      ellipsis: true,
      dataIndex: 'agentPartner',
      render: (data: IReportAgentPartner): ReactNode => {
        return data?.superAgentPartner
          ? capitalizeFirstLetter(data?.superAgentPartner?.name)
          : '-';
      },
    },
    {
      title: TEXT.SUB_AGENT,
      width: 120,
      ellipsis: true,
      dataIndex: 'agentPartner',
      render: (data: IReportAgentPartner): ReactNode => {
        return data?.subAgentPartner
          ? capitalizeFirstLetter(data?.subAgentPartner?.name)
          : '-';
      },
    },
  ];
};
