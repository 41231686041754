import { useContext, useState } from 'react';
import ApplicationDetailContext from '../../../application-detail-context';
import { Input, Space, Typography } from 'antd';
import { useApplicationFeeMutation } from '../../DetailSection/hooks/use-contact-application-fees';
import useUpdateApplicationMutation from '../../../hooks/useUpdateApplicationMutation';
import { successNotificationHandler } from '@moxie/shared';
import { InputButtonWrapper } from '@crm/src/shared/input-button-wrapper';

const { Text } = Typography;

export const PaymentsTab = () => {
  const { applicationData } = useContext(ApplicationDetailContext);
  const feeMutation = useApplicationFeeMutation(applicationData);
  const updateApplicationMutation = useUpdateApplicationMutation(
    applicationData,
    applicationData.dealId
  );
  const currency = applicationData?.institution?.currency;
  const initialInput = {
    fees: applicationData?.productFeeAmount,
    discount: applicationData?.discount,
    remarks: applicationData.remarks ?? '',
  };
  const [input, setInput] = useState(initialInput);

  const isApplicationCompleted = applicationData?.status === "Completed";

  return (
    <div className="form-container">
      <InputButtonWrapper
        readOnly={isApplicationCompleted}
        onSubmit={() => {
          feeMutation.mutate(input.fees, {
            onSuccess() {
              successNotificationHandler('Fees updated successfully.', 1.5);
            },
          });
        }}
        onCancel={() =>
          setInput((input) => ({
            ...input,
            fees: initialInput.fees,
          }))
        }
      >
        <Space direction="vertical">
          <Text strong>{`Fees (${currency})`}</Text>
          <Input
            placeholder="Fees"
            type="number"
            onChange={(e) => {
              if (e.target.value.startsWith('0')) {
                e.target.value = e.target.value.substring(1);
              }
              setInput((input) => ({
                ...input,
                fees: Number(e.target.value),
              }))
            }
            }
            disabled={isApplicationCompleted}
            value={Number(input.fees)}
          />
        </Space>
      </InputButtonWrapper>

      <InputButtonWrapper
        readOnly={isApplicationCompleted}
        onSubmit={() => {
          updateApplicationMutation.mutate(
            {
              payload: { discount: input.discount, remarks: input.remarks },
              activitiesInfo: { activitiesType: 'application-discount' },
            },
            {
              onSuccess() {
                successNotificationHandler(
                  'Discount updated successfully.',
                  1.5
                );
              },
            }
          );
        }}
        onCancel={() =>
          setInput((input) => ({
            ...input,
            discount: initialInput.discount,
            remarks: initialInput.remarks,
          }))
        }
      >
        <Space direction="vertical">
          <Text strong>{`Discount (${currency})`}</Text>
          <Input
            type="number"
            placeholder="Discount"
            onChange={(e) => {
              if (e.target.value.startsWith('0')) {
                e.target.value = e.target.value.substring(1);
              }
              setInput((input) => ({
                ...input,
                discount: Number(e.target.value),
              }))
            }
            }
            disabled={isApplicationCompleted}
            value={input.discount}
          />
          <Input
            placeholder="Discount Remarks"
            onChange={(e) =>
              setInput((input) => ({
                ...input,
                remarks: e.target.value,
              }))
            }
            disabled={!Number(input.discount) || isApplicationCompleted}
            value={input.remarks}
          />
        </Space>
      </InputButtonWrapper>
    </div>
  );
};
