import {
  MailOutlined,
  PhoneOutlined,
  MoreOutlined,
  EditOutlined,
  IdcardFilled,
  IdcardOutlined
} from '@ant-design/icons';
import { contactActions, leadActions, useAppSelector } from '@crm/core';
import { bulkDiscontinueCrmApplications } from '@crm/libs/services.api/lib/application.api';
import { editClientAction } from '@crm/src/core/contact/contact.action';
import { useApplicationDetailsBycontactId } from '@crm/src/pages/application-details/hooks/useApplicationDetailsByContactId';
import { IContactCrm } from '@model/crm/contact.model';
import {
  ARCHIVE,
  CONFIRM_MESSAGE,
  CONFIRM_TITLE,
  RESTORE
} from '@moxie/constants';
import { popConfirm } from '@shared-components/elements';
import { Avatar, Col, Row, Tooltip, Typography, Menu, Dropdown } from 'antd';
import Button from 'antd/es/button';
import { AvatarSize } from 'antd/lib/avatar/SizeContext';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

const { Text } = Typography;

interface MenuProps {
  onEdit?: () => void;
  onArchive: () => void;
  onDelete: () => void;
  contact: IContactCrm;
}
const ContactMenu: React.FC<MenuProps> = ({
  onEdit,
  onArchive,
  onDelete,
  contact,
}) => {
  return (
    <Menu selectable={false} className="margin-0 contact-menu-option">
      {contact?.archived || (
        <Menu.Item
          key={1}
          className="margin-0"
          data-testid="crm-editmenuitem"
          onClick={() => {
            if (onEdit) {
              onEdit();
            }
          }}
        >
          <EditOutlined /> Edit
        </Menu.Item>
      )}
      {/* Note: Temporary halt of the feature */}
      {/* {contact?.status !== 'Client' && (
        <Menu.Item
          key={2}
          className="margin-0"
          icon={<AppstoreAddOutlined />}
          onClick={onArchive}
        >
          {contact?.archived ? RESTORE : ARCHIVE}
        </Menu.Item>
      )} */}
      {/* {canAccess('contact', 'delete') && <Menu.Item
        key={3}
        className="margin-0"
        icon={<DeleteOutlined />}
        danger
        onClick={onDelete}
      >
        {DELETE_BTN}
      </Menu.Item>} */}
    </Menu>
  )
};

interface RequiredProps {
  contact: IContactCrm;
  avatarSize: AvatarSize;
  onEdit?: () => void;
}
interface ContactDetailProps {
  allowEdit: true;
}

interface ApplicationContactDetailProps {
  allowEdit: false;
  showDetail: boolean;
  handleShowDetail: (val: boolean) => void;
}

type ContactProfileCardProps = RequiredProps & (ContactDetailProps | ApplicationContactDetailProps);

const ContactProfileCard: React.FC<ContactProfileCardProps> = ({
  avatarSize,
  contact,
  allowEdit,
  onEdit,
  ...props
}) => {
  const history = useHistory();
  const user = useAppSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const socket = useAppSelector((state) => state.socket.wss);
  const triggerRefresh = () => {
    dispatch(contactActions.getContactDetailsRequest(contact.id));
  };

  const { applicationData } = useApplicationDetailsBycontactId(contact.id);

  const phoneNumber = contact?.countryCode && contact?.phone ? `${contact?.countryCode} ${contact?.phone}` : 'N/A';

  const onArchive = () => {
    if (contact?.archived) {
      dispatch(
        leadActions.archiveLeadRequest(
          contact.id,
          {
            archived: false,
            assigneeId: contact?.assigneeId,
            archivedBy: null,
          },
          () => {
            socket?.emit('server::archive-status-updated', {
              userId: user?.id as string,
              contactId: contact?.id as string,
              companyId: user?.companyId as string,
              archived: false,
              activitiesType: 'archive',
              activitiesAction: 'updated',
              data: {
                archived: false,
              },
            });
            dispatch(contactActions.getContactDetailsRequest(contact.id));
          }
        )
      );
    }
    else {
      dispatch(
        leadActions.archiveLeadRequest(
          contact.id,
          {
            archived: true,
            archivedBy: user?.id,
            assigneeId: contact?.assigneeId,
          },
          () => {
            socket?.emit('server::archive-status-updated', {
              userId: user?.id as string,
              contactId: contact?.id as string,
              companyId: user?.companyId as string,
              archived: true,
              activitiesType: 'archive',
              activitiesAction: 'updated',
              data: {
                archived: true,
              },
            });
            dispatch(contactActions.getContactDetailsRequest(contact.id));
          }
        )
      );
    }
  };
  const handleArchive = () => {
    popConfirm(
      !contact?.archived ? ARCHIVE : RESTORE,
      !contact?.archived
        ? `${CONFIRM_MESSAGE.ARCHIVE_LEAD} ${contact?.firstName} ${contact?.lastName}?`
        : `${CONFIRM_MESSAGE.RESTORE_LEAD} ${contact?.firstName} ${contact?.lastName}?`,
      onFinalArchiveConfirmation
    );
  };

  const onFinalArchiveConfirmation = async () => {
    if (applicationData && applicationData.length > 0) {
      finalArchiveConfirmation(contact.id, applicationData);
    }
    onArchive()
  }

  const finalArchiveConfirmation = (userId: string, applications: any) => {
    popConfirm(ARCHIVE, CONFIRM_MESSAGE.CONTACT_ARCHIVE_FINAL, () => {
      archive(userId, applications);
    });
  };

  const archive = async (id: string, applications: any) => {
    const applicationIds = applications.map(
      (d: any) => d.id
    );
    await bulkDiscontinueCrmApplications(applicationIds);

    const payload =
    {
      id: contact.id,
      firstName: contact.firstName,
      lastName: contact.lastName,
      phone: contact.phone,
      countryCode: contact.countryCode,
      email: contact.email,
      crmAssigneeId: contact.assigneeId,
      avatar: contact.avatar,
    }

    dispatch(
      editClientAction(payload, triggerRefresh)
    );

    onArchive()
  };

  const openDeleteConfirmation = () => {
    popConfirm(
      CONFIRM_TITLE.DELETE_LEAD,
      `${CONFIRM_MESSAGE.DELETE_LEAD} ${contact?.firstName} ${contact?.lastName}?`,
      onDelete,
      true
    );
  };
  const onDelete = () => {
    const updateData = { isDeleted: true, deletedBy: user!.id, contactId: contact.id };

    if (applicationData && applicationData.length > 0) {
      dispatch(
        contactActions.deleteClientRequest(contact.id, updateData, () =>
          history.push('/contacts/all-contacts')
        )
      );
      return;
    }

    dispatch(
      leadActions.deleteLeadRequest(contact.id, updateData, () =>
        history.push('/contacts/all-contacts')
      )
    );
  }

  if (!allowEdit) {
    const { handleShowDetail, showDetail } = props as ApplicationContactDetailProps;
    return (
      <div
        style={{ height: '6rem', marginBottom: '0' }}
        className="application-contact-profile-container contact-profile-container"
      >
        <Row align="middle">
          <Col span={3} xl={4} xxl={3} className="margin-left-2">
            <div className="user-name-card__avatar">
              <Avatar size={avatarSize} className="initial_capital">
                {contact.firstName?.substring(0, 1).toUpperCase()}
                {contact.lastName?.substring(0, 1).toUpperCase()}
              </Avatar>
            </div>
          </Col>
          <Col span={19} xl={18} xxl={19} className="profile-detail-container">
            <Row justify="space-between" align="middle">
              <Col span={20}>
                <Text
                  className="application-contact-profile-card initial_capital"
                  ellipsis={
                    String(`${contact.firstName} ${contact.lastName}`).length >= 25
                      ? { tooltip: `${contact.firstName} ${contact.lastName}` }
                      : false
                  }
                >
                  {contact.firstName} {contact.lastName}
                </Text>
              </Col>
              <Col
                span={2}
                className="justify-content-center cursor-pointer"
              >
                {showDetail ? (
                  <Tooltip title={'Hide details'}>
                    <IdcardFilled
                      className="contact-profile-card-icon"
                      onClick={() => handleShowDetail(false)}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip title={'Show details'}>
                    <IdcardOutlined
                      className="contact-profile-card-icon"
                      onClick={() => handleShowDetail(true)}
                    />
                  </Tooltip>
                )}
              </Col>
            </Row>
            <Row align="middle">
              <Col span={1} xl={2} xxl={1}>
                <a href={`mailto:${contact.email}`}>
                  <MailOutlined className="table-mail-icon contact-profile-icon-padding" />
                </a>
              </Col>
              <Col span={21} xl={20} xxl={21}>
                {contact.email && contact.email!.length > 30 ? (
                  <Tooltip title={contact.email} placement="bottom">
                    <Text
                      className="contact-profile-email padding-left-1"
                      ellipsis={true}
                    >
                      {contact.email?.toLowerCase()}
                    </Text>
                  </Tooltip>
                ) : (
                  <Text className="contact-profile-text padding-left-1">
                    {contact.email?.toLowerCase()}
                  </Text>
                )}
              </Col>
            </Row>
            <Row align="middle">
              <Col span={1} xl={2} xxl={1}>
                <PhoneOutlined
                  className="table-mail-icon contact-profile-phone-icon-padding "
                  rotate={90}
                />
              </Col>
              <Col span={21} xl={20} xxl={21}>
                <Text className="contact-profile-text padding-left-1">
                  {contact.phone ?? 'N/A'}
                </Text>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    )
  }
  return (
    <div className="contact-profile-container">
      <Row align="middle">
        <Col span={3} xl={4} xxl={3} className="margin-left-2">
          <div className="user-name-card__avatar">
            <Avatar size={avatarSize} className="initial_capital">
              {contact.firstName?.substring(0, 1).toUpperCase()}
              {contact.lastName?.substring(0, 1).toUpperCase()}
            </Avatar>
          </div>
        </Col>
        <Col span={19} xl={17} xxl={19} className="profile-detail-container">
          <Row justify="space-between" align="middle">
            <Col span={20}>
              <Text
                className="contact-profile-card-name initial_capital"
                ellipsis={
                  String(`${contact.firstName} ${contact.lastName}`).length >= 20
                    ? { tooltip: `${contact.firstName} ${contact.lastName}` }
                    : false
                }
              >
                {contact.firstName} {contact.lastName}
              </Text>
            </Col>
            <Col span={1}>
              <Dropdown
                overlay={
                  <ContactMenu
                    onEdit={onEdit}
                    contact={contact}
                    onArchive={handleArchive}
                    onDelete={openDeleteConfirmation}
                  />
                }
                overlayClassName="contact-name-dropdown"
                placement="bottomRight"
              >
                <Button
                  data-testid="crm-editcontact-btn"
                  type="text"
                  className="table-cell-action-btn"
                  icon={<MoreOutlined />}
                />
              </Dropdown>
            </Col>
          </Row>
          <Row align="middle">
            <Col span={1} xl={2} xxl={1}>
              <a href={`mailto:${contact.email}`}>
                <MailOutlined className="table-mail-icon contact-profile-icon-padding" />
              </a>
            </Col>
            <Col span={21} xl={20} xxl={21}>
              {contact.email && contact.email!.length > 30 ? (
                <Tooltip title={contact.email} placement="bottom">
                  <Text
                    className="contact-profile-email padding-left-1"
                    ellipsis={true}
                  >
                    {contact.email.toLowerCase()}
                  </Text>
                </Tooltip>
              ) : (
                <Text className="contact-profile-text padding-left-1">
                  {contact.email.toLowerCase()}
                </Text>
              )}
            </Col>
          </Row>
          <Row align="middle">
            <Col span={1} xl={2} xxl={1}>
              <PhoneOutlined
                className="table-mail-icon contact-profile-phone-icon-padding "
                rotate={90}
              />
            </Col>
            <Col span={21} xl={20} xxl={21}>
              <Text className="contact-profile-text padding-left-1">
                {phoneNumber}
              </Text>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export { ContactProfileCard };
