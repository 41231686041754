import {
  findAllMediaBySubjectIdAndMediaType
} from '@crm/services.api';
import { useQuery } from '@tanstack/react-query';


export const useGetDocumentsByCategory = (
  contactId: string,
  subCategoryName: string | undefined,
  isContact?: boolean | undefined
) => {
  const { isLoading, data, refetch } = useQuery({
    queryKey: ['documents-by-category', contactId, subCategoryName, isContact],
    queryFn: () => findAllMediaBySubjectIdAndMediaType({
      subjectId: contactId,
      mediaType: subCategoryName,
      isContact,
    }),
    enabled: !!subCategoryName,
  });

  return {
    refetch,
    isLoading,
    documents: data?.data ?? [],
  };
};
