import { DownOutlined, RedoOutlined } from '@ant-design/icons';
import { fetchServiceCountry, getBranchesByUserContactPermission, getWorkflow } from '@crm/services.api';
import { UserPopoverSearch } from '@crm/src/shared/search';
import { PLACEHOLDER, TEXT } from '@moxie/constants';
import { capitalizeFirstLetter } from '@moxie/shared';
import { Badge, Button, Col, Empty, Input, Menu, Row, Select, Space } from 'antd';
import Form from 'antd/es/form';
import { SelectValue } from 'antd/lib/select';
import { SearchBox } from 'libs/shared/src/crm-modules/shared';
import { OptionData, OptionGroupData, OptionsType } from 'rc-select/lib/interface';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ApplicationContext } from '../application-context';

export interface ApplicationsMenu {
  name: JSX.Element;
  id: string;
  url: string;
  onClick?: () => void;
}

export interface ApplicationFilterProps {
  status: string[];
  assignees?: string[];
  branch?: string;
  service?: string;
  serviceName?: string;
  workflow?: string[];
  workflowType?: string;
  country?: string;
}

interface Props {
  defaultBranch: string | undefined;
  menu: ApplicationsMenu[];
  defaultActiveKey?: string;
  search: string | undefined;
  setSearch: (str: string) => void;
}

const allTabs = ['all', 'in-progress', 'complete', 'discontinued', 'stages'];

const PageHeader: React.FC<Props> = ({ defaultBranch, menu, defaultActiveKey, search, setSearch }) => {
  const history = useHistory();
  const { services, form, isServiceLoading, countryList, setCountryList, workflows, setWorkflows, setFilter, filter } = useContext(ApplicationContext);

  const [current, setCurrent] = useState(defaultActiveKey || menu[0].id);
  const [inputSearch, setInputSearch] = useState(search);
  const location = useLocation();
  const queryString = location.search;
  const params = new URLSearchParams(queryString);
  const searchValue = params.get('search');

  const resetFilter = async () => {
    setInputSearch('');
    setSearch('');

    const resetCountry = await fetchCountriesForService(services[0].id as string);
    const workflow = await getWorkflow(services[0].id, resetCountry);

    form.setFieldsValue({
      serviceId: services[0].id,
      country: resetCountry,
      workflow: 'all',
    });

    setFilter((prev: any) => { return { ...prev, status: [], stage: undefined, branch: defaultBranch, assignees: [], service: services[0].id, country: resetCountry, workflow: workflow.data.data.map((workflowIds: { id: any; }) => workflowIds.id), workflowType: 'All Workflows' } });
    history.push(`/application/all`);
  }

  useEffect(() => {
    if (searchValue) {
      setInputSearch(searchValue);
      setSearch(searchValue);
    }
    if (defaultActiveKey) {
      if (defaultActiveKey !== current) setCurrent(defaultActiveKey);
    }
  }, [defaultActiveKey, current, searchValue]);

  const handleChange = (e: any) => {
    const selected = menu.find((item) => item.id === e.key);
    setCurrent(e?.key);
    if (selected?.url) history.push(`/${selected?.url}`);
  };

  const handleCountryChange = (country: string) => {
    fetchWorkflows(form.getFieldValue('serviceId'), country as string);
    form.setFieldsValue({
      serviceId: form.getFieldValue('serviceId'),
      country: country,
      workflow: 'all',
    });
  }

  const fetchCountriesForService = async (serviceId: string) => {
    const countries = await fetchServiceCountry(serviceId);
    const defaultCountry = countries.data[0];

    fetchWorkflows(serviceId, defaultCountry);

    setFilter((prev: any) => { return { ...prev, service: serviceId } });

    form.setFieldsValue({
      serviceId: serviceId,
      country: defaultCountry,
      workflow: 'all',
    });

    if (countries?.data?.length) {
      setCountryList(countries.data);
    } else {
      setCountryList([]);
    }

    return defaultCountry;
  };

  const fetchWorkflows = async (serviceId: string, country: string) => {
    const workflow = await getWorkflow(serviceId, country);
    setFilter((filter) => ({
      ...filter,
      country,
      workflow: workflow.data.data.map((workflowIds: { id: any; }) => workflowIds.id), workflowType: 'All Workflows', stage: undefined,
    }));
    setWorkflows(workflow.data.data.map(wo => ({
      value: wo.id,
      label: wo.workflowType.name,
    })));
    history.push(`/application/all`);
    return workflow.data.data;
  };

  const handleWorkflowChange = (value: SelectValue, option: OptionsType | OptionData | OptionGroupData) => {
    if (value as string === 'all') {
      const allWorkflowIds = Array.isArray(workflows) ? workflows.map((workflow) => workflow.value) : [];
      setFilter(filter => ({
        ...filter,
        workflow: allWorkflowIds,
        workflowType: "All Workflows",
        stage: undefined,
      }));
    } else {
      setFilter(filter => ({
        ...filter,
        workflow: [value as string],
        workflowType: (option as any)?.label as string,
        stage: undefined,
      }));
    }
    history.push(`/application/all`);
  }

  useEffect(() => {
    if (!allTabs.includes(current)) {
      setCurrent(current);
    }
  }, [current])

  return (
    <>
      <Row justify="space-between" className="page-title" align="middle">
        <Col>
          <h3>{TEXT.APPLICATIONS}</h3>
        </Col>

        <Col>
          <Row align="middle">
            <Col>
              <Input.Search
                value={inputSearch}
                style={{ width: '264px', paddingTop: '8px', marginRight: "8px" }}
                className="table-column-search-input"
                placeholder="Search in applications"
                onChange={(e) => setInputSearch(e.target.value)}
                onSearch={(value: string) => setSearch(value)}
                allowClear
              />
            </Col>
            <Col>
              <Form form={form}>
                <Row align="middle" justify="center">
                  <Space>
                    <Col style={{ width: "200px" }}>
                      <Form.Item name="branchId">
                        <SearchBox
                          fetchQuery={getBranchesByUserContactPermission}
                          valueKey="id"
                          labelKey="name"
                          size="middle"
                          showArrow
                          className="table__branch-search-width"
                          placeholder={PLACEHOLDER.SELECT_BRANCH}
                          optionFilterProp="label"
                          defaultOpen={false}
                          onChange={(value) => setFilter((filter) => ({ ...filter, branch: value as unknown as string }))}
                        >
                          {
                            (option) =>
                              <Select.Option value={option.id} label={capitalizeFirstLetter(option.name)}>
                                {option.name}
                              </Select.Option>
                          }
                        </SearchBox>
                      </Form.Item>
                    </Col>

                    <Col>
                      <UserPopoverSearch
                        isMultiSelect
                        assignees={filter.assignees ?? []}
                        onReset={() => setFilter((filter) => ({ ...filter, assignees: [] }))}
                        onChange={(assignees) => {
                          if (Array.isArray(assignees)) {
                            setFilter((filter) => ({ ...filter, assignees }))
                          }
                        }}
                      >
                        <Button
                          type="text"
                          icon={<Badge className="table-filter__badge" count={(filter?.assignees?.length)} style={{ marginRight: '10px' }} />}
                        >
                          Assignee <DownOutlined />
                        </Button>
                      </UserPopoverSearch>
                    </Col>

                    <Col>
                      <Form.Item name="serviceId">
                        <Select
                          loading={isServiceLoading}
                          showArrow={true}
                          style={{ width: '200px' }}
                          showSearch
                          placeholder="Select Service "
                          allowClear
                          onChange={(value) => {
                            fetchCountriesForService(value as string);
                          }}
                          options={services?.map(service => ({
                            value: service.id,
                            label: service.name,
                          }))}
                          notFoundContent={
                            <Empty
                              image={Empty.PRESENTED_IMAGE_SIMPLE}
                              description={TEXT.NO_DATA}
                            />
                          }
                          optionFilterProp="label"
                          filterOption={(input, option) => {
                            const label = option?.label;
                            return typeof label === 'string' && label.toLowerCase().includes(input.toLowerCase());
                          }}
                        />
                      </Form.Item>
                    </Col>

                    <Col>
                      <Form.Item name="country">
                        <Select
                          showArrow={true}
                          style={{ width: '200px' }}
                          showSearch
                          placeholder="Select Country"
                          allowClear
                          onChange={(value) => {
                            handleCountryChange(value as string);
                          }}
                          options={countryList?.map((data: string) => ({
                            value: data,
                            label: data,
                          }))}
                          notFoundContent={
                            <Empty
                              image={Empty.PRESENTED_IMAGE_SIMPLE}
                              description={TEXT.NO_DATA}
                            />
                          }
                          optionFilterProp="label"
                          filterOption={(input, option) => {
                            const label = option?.label;
                            return typeof label === 'string' && label.toLowerCase().includes(input.toLowerCase());
                          }}
                        />
                      </Form.Item>
                    </Col>

                    <Col>
                      <Form.Item name="workflow">
                        <Select
                          showArrow={true}
                          style={{ width: '200px' }}
                          showSearch
                          placeholder="Select workflows"
                          options={[
                            {
                              value: 'all',
                              label: 'All Workflows',
                            },
                            ...(Array.isArray(workflows) ? workflows.map((workflow) => ({
                              value: workflow?.value,
                              label: workflow?.label,
                              disabled: filter.workflow?.includes('all'),
                            })) : [])
                          ]}
                          optionFilterProp="label"
                          filterOption={(input, option) => {
                            const label = option?.label;
                            return typeof label === 'string' && label.toLowerCase().includes(input.toLowerCase());
                          }}
                          loading={false}
                          onChange={(value, option) => {
                            handleWorkflowChange(value, option);
                          }}
                          notFoundContent={
                            <Empty
                              image={Empty.PRESENTED_IMAGE_SIMPLE}
                              description={TEXT.NO_DATA}
                            />
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Space>
                </Row>
              </Form>
            </Col>
            <Col className="padding-right-1">
              <Button
                onClick={() => resetFilter()}
                className="enquiry__filter-btn"
                icon={<RedoOutlined />}
                type="link"
              >
                <Space size={8}>
                  <span>{TEXT.CLEAR_FILTERS}</span>
                </Space>
              </Button>
            </Col>
          </Row >
        </Col >
      </Row >

      <Row className="page-header">
        <Col className="page-header__menu">
          <Menu
            onClick={handleChange}
            selectedKeys={[current]}
            mode="horizontal"
          >
            {menu.map((menuItem) => {
              return (
                <Menu.Item key={menuItem.id} onClick={menuItem?.onClick || undefined}>
                  <p>{menuItem.name}</p>
                </Menu.Item>
              );
            })}
          </Menu>
        </Col>
      </Row>
    </>
  )
}

export default PageHeader;
