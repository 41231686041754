import React, { useState } from 'react';
import {
  CANCEL_BTN,
  CONTACT_DETAILS_TITLE,
  UPDATE_BTN
} from '@moxie/constants';
import { DrawerElem } from '@shared-components/elements';
import { Button, Space } from 'antd';
import { AddressInfoForm } from 'libs/shared/src/crm-modules/forms/contact-details';
import { useForm } from 'antd/lib/form/Form';
import { useDispatch } from 'react-redux';
import { contactActions, useAppSelector } from '@crm/core';

const EditAddressInfoDrawer = ({
  showDrawer,
  setShowDrawer,
  initialData,
}: {
  showDrawer: boolean;
  setShowDrawer: any;
  initialData: any;
}) => {
  const [form] = useForm();
  const [disabled, setDisabled] = useState(true);
  const socket = useAppSelector((state) => state.socket.wss);
  const user = useAppSelector((state) => state.auth.user);

  const handleClose = () => {
    setDisabled(true);
    setShowDrawer(false);
    form.resetFields();
  };

  const handleSubmitBtnClick = () => {
    form.submit();
  };

  const dispatch = useDispatch();

  const handleSubmit = async (data: any) => {
    if (data) {
      const dataToUpdate = {
        id: initialData?.id,
        ...data,
      };
      if (user) {
        dispatch(
          contactActions.updateContactAddressInfoRequest(
            dataToUpdate,
            (response) => {
              socket?.emit('server::profile-updated', {
                activitiesTypeId: initialData.id,
                activitiesType: 'address-information',
                activitiesAction: 'updated',
                userId: user?.id,
                contactId: initialData.id,
                companyId: user?.companyId,
                data: response,
              });
            }
          )
        );
      }

      handleClose();
    }
  };

  return (
    <DrawerElem
      title={CONTACT_DETAILS_TITLE.EDIT_ADDRESS_INFO}
      width={720}
      onClose={handleClose}
      visible={showDrawer}
      footer={
        <div className="padding-bottom-2 text-align-right">
          <Space direction="horizontal">
            <Button onClick={handleClose} className="lead-margin-right">
              {CANCEL_BTN}
            </Button>
            <Button
              onClick={handleSubmitBtnClick}
              type="primary"
              disabled={disabled}
            >
              {UPDATE_BTN}
            </Button>
          </Space>
        </div>
      }
      data-testid="crm-editaddressinformation"
    >
      <AddressInfoForm
        form={form}
        handleSubmit={handleSubmit}
        setDisabled={setDisabled}
        initialData={initialData}
      />
    </DrawerElem>
  );
};

export { EditAddressInfoDrawer };
