import React from 'react';
import { Row, Col, Tooltip, Avatar, Space } from 'antd';
import Form from 'antd/lib/form';
import { UserOutlined } from '@ant-design/icons';
import UserSearchBox from '@crm/src/shared/user-select-box/user-search-box';
import Select from 'antd/es/select';
import { UnassignedCard } from '@shared-components/elements';

interface Props {
  contactId: string;
  handleSubmit: (payload: string) => void;
  assigneeId: string | null;
  handleCancel: () => void;
}

export const TableAssignee: React.FC<Props> = ({
  handleSubmit,
  assigneeId,
  contactId,
  handleCancel,
}) => {
  return (
    <Row className="lead-table__name user-name-card" style={{ width: '8rem' }}>
      <Col className="align-items-center user-name-card__avatar">
        <Tooltip title="unassigned">
          <Avatar icon={<UserOutlined />} alt="unassigned" />
        </Tooltip>
      </Col>
      <Col>
        <Form
          layout="inline"
        // onFinish={({ assigneeId }) => handleSubmit(assigneeId)}
        >
          <Space direction="horizontal">
            <Form.Item
              name="assigneeId"
              className="inline_input_width"
            >
              <UserSearchBox
                filter={{ contactId }}
                currentValue={assigneeId}
                value={assigneeId}
                emptyOption={
                  <Select.Option value="">
                    <UnassignedCard />
                  </Select.Option>
                }
                onChange={handleSubmit}
                onDropdownVisibleChange={handleCancel}
              />
            </Form.Item>
          </Space>
        </Form>
      </Col>
    </Row>
  );
};
