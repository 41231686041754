import { API_URL } from '@moxie/constants';
import crmAxios from './crm-api-axios';
import {
  ProviderBranch,
  ProviderCourse,
  CreateEducationProviderPayload,
  ProviderMessage,
  ProviderStatus,
  ProviderMessageDetail
} from '@model/crm/education-provider.model';

export const fetchEducationProviderBranches = async (
  params?: Record<string, unknown>
) => {
  const { data } = await crmAxios.get<{ items: ProviderBranch[] }>(
    `${API_URL.CRM_EDUCATION_PROVIDER}/branches`, { params }
  );
  return data;
};

export const fetchEducationProviderCourses = async (
  params?: Record<string, unknown>
) => {
  const { data } = await crmAxios.get<{ items: ProviderCourse[] }>(
    `${API_URL.CRM_EDUCATION_PROVIDER}/courses`, { params }
  );
  return data;
};

export const createEducationProviderApplication = (
  payload: CreateEducationProviderPayload,
  signal?: AbortSignal
) => crmAxios.post<{ data: any }>(API_URL.CRM_EDUCATION_PROVIDER, payload, { params: { signal } });

export const fetchMessageListFromProvider = async (
  method: string,
  providerApplicationId: string,
  params?: Record<string, unknown>
) => {
  const { data } = await crmAxios.get<{ items: ProviderMessage[] }>(
    `${API_URL.CRM_EDUCATION_PROVIDER}/message?method=${method}&providerApplicationId=${providerApplicationId}`, { params }
  );
  return data;
};

export const fetchStatusListFromProvider = async (
  method: string,
  providerApplicationId: string,
  params?: Record<string, unknown>
) => {
  const { data } = await crmAxios.get<{ items: ProviderStatus[] }>(
    `${API_URL.CRM_EDUCATION_PROVIDER}/status-history?method=${method}&providerApplicationId=${providerApplicationId}`, { params }
  );
  return data;
};

export const fetchMessageFromProvider = async (
  method: string,
  providerApplicationId: string,
  providerMessageId: string,
  params?: Record<string, unknown>
) => {
  const { data } = await crmAxios.get<{ data: ProviderMessageDetail }>(
    `${API_URL.CRM_EDUCATION_PROVIDER}/message/${providerMessageId}?method=${method}&providerApplicationId=${providerApplicationId}&providerMessageId=${providerMessageId}`, { params }
  );
  return data.data;
};
