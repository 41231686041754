import { useQuery } from '@tanstack/react-query';
import { fetchMessageFromProvider } from '@crm/libs/services.api/lib/education-provider.api';

export const useFetchProviderMessage = (
  method: string,
  providerApplicationId: string,
  providerMessageId: string | null
) => {
  const { data, isLoading } = useQuery({
    queryKey: [
      'fetch-provider-message',
      method,
      providerApplicationId,
      providerMessageId,
    ],
    queryFn: async () =>
      fetchMessageFromProvider(
        method,
        providerApplicationId,
        providerMessageId as string
      ),
    enabled: Boolean(method && providerApplicationId && providerMessageId),
  });

  return { data, isLoading };
};
