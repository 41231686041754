import { EditOutlined, MinusCircleTwoTone } from '@ant-design/icons';
import {
  DocumentFileIcons,
  DocumentNameEditCard,
  getExtension,
  getFileName
} from '@moxie/shared';
import {
  IDocNameForm,
  IDocumentItem,
  IFileNameComponent
} from '@shared-components/models';
import { Col, List, Row, Space } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useState } from 'react';

const DocumentFileNameComponent: React.FC<IFileNameComponent> = ({
  item,
  documents,
  onSetDocument,
  onRemove,
}: IFileNameComponent) => {
  const [isEdit, setIsEdit] = useState(false);
  const [form] = useForm();

  const handleSubmit = async (data: IDocNameForm) => {
    const [filterDocument] = await Promise.all([
      documents.filter((document: IDocumentItem) => {
        const newDocument = document;
        if (item.id) {
          if (document?.id === item?.id) {
            newDocument.fileName = data.fileName;
          }
        } else if (document?.uid === item?.uid) {
          newDocument.fileName = data.fileName;
          newDocument.extension = getExtension(item.name);
        }
        return newDocument;
      }),
    ]);
    setIsEdit(false);
    onSetDocument(filterDocument);
  };

  const handleCancel = () => {
    setIsEdit(false);
  };

  const handleEdit = () => {
    setIsEdit(true);
  };

  return (
    <List.Item key={item?.uid}>
      <List.Item.Meta
        avatar={<DocumentFileIcons mimetype={item?.type || item?.mimetype} />}
        title={
          <Space>
            {isEdit ? (
              <DocumentNameEditCard
                handleSubmit={handleSubmit}
                handleCancel={handleCancel}
                form={form}
                documentData={item}
              />
            ) : (
              <Row>
                <Col>
                  <div className="margin-top-1 document-file-name ">
                    {item?.fileName || getFileName(item?.name) || ''}
                  </div>
                </Col>
                <Col>
                  <EditOutlined
                    className="document-edit-btn margin-top-1 margin-left-1"
                    onClick={handleEdit}
                    title="Edit"
                  />
                </Col>
              </Row>
            )}
          </Space>
        }
      />
      <Space>
        {!isEdit ? (
          <>
            <MinusCircleTwoTone
              twoToneColor="red"
              className="action__button__icon margin-left-1"
              onClick={() => {
                onRemove(item);
              }}
              title="Remove"
            />
          </>
        ) : null}
      </Space>
    </List.Item>
  );
};

export { DocumentFileNameComponent };
