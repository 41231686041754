import React, { Key, useEffect, useState } from "react";
import { checkValidation } from "./check-validation";
import { popConfirm } from "@shared-components/elements";
import { Button, Row, Space, Switch, Table, Typography } from "antd";
import { IMPORT_CONTACT } from "@moxie/constants";
import { WarningOutlined, CheckCircleFilled, DeleteOutlined } from "@ant-design/icons";
import { EditableCell, EditableRow } from "./editable-row-cell";
import { ContactData } from "@model/crm/contact.model";


interface EditableColumn {
  title: React.ReactNode;
  dataIndex: string;
  editable: boolean;
  width?: string;
  ellipsis?: boolean;
}

interface IModifiedDataSource extends ContactData {
  errors?: (string | null)[];
}

interface EditableTableProps {
  columns: Array<EditableColumn>;
  errorDataSource: IModifiedDataSource[];
  onChange: (newData: ContactData) => void;
  setDataSource: React.Dispatch<React.SetStateAction<ContactData[]>>;
  showErrStat: boolean;
  setShowErrStat: any;
  selectedRowList: Key[];
  setSelectedRowList: (val: Key[]) => void;
  handleErrorListToggle: (val: boolean) => void;
}

const EditableTable: React.FC<EditableTableProps> = ({ columns, errorDataSource, onChange, setDataSource, showErrStat, handleErrorListToggle, selectedRowList, setSelectedRowList }) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [paginatedData,setPaginatedData] = useState<ContactData[]>([])

  useEffect(() => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const newPaginatedData = errorDataSource.slice(startIndex, endIndex);
    if (newPaginatedData.length === 0 && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else {
      setPaginatedData(newPaginatedData);
    }
  }, [currentPage, pageSize, errorDataSource]);

  const handleSave = (row: ContactData) => {
    onChange(row);

  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const modifiedColumns = columns.map(col => {
    return {
      ...col,
      onCell: (record: ContactData) => {
        return {
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          key: record.key,
          handleSave,
          checkValidation: (dataIndex: keyof ContactData, record: ContactData) => {
            return checkValidation(dataIndex, record);
          },
        }
      },
    };
  });

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedRowList(selectedRowKeys);
    },
    selectedRowKeys: selectedRowList,
  }

  const handleDelete = () => {
    const errorDataSourceKeys = errorDataSource.map(item => item.key);
    const validSelectedKeys = selectedRowList.filter(key => errorDataSourceKeys.includes(key as string));
    setSelectedRowList(validSelectedKeys);

    popConfirm("Delete",
      `Do you want to delete ${validSelectedKeys.length > 1 ? `selected ${validSelectedKeys.length} rows` : 'selected row'} ?`,
      () => {
        setDataSource((oldRows) => oldRows.filter((item: ContactData) => !selectedRowList?.includes(item.key)));
        setSelectedRowList([]);
      },
      true)
  }

  return (
    <>
      <Row justify="space-between" align="middle" className="margin-top-1">
        <p>
          <Typography.Text strong>{IMPORT_CONTACT.VALIDATE_DATA}</Typography.Text><br />
          <Typography.Text>{IMPORT_CONTACT.VALIDATE_MESSAGE}</Typography.Text>
        </p>
        <Space>
          <Button type="ghost" onClick={handleDelete} disabled={selectedRowList.length === 0}><DeleteOutlined className="required" style={{ fontSize: '20px' }} />{IMPORT_CONTACT.DELETE_SELECTED_ROWS}</Button>
          <Row align="middle" className="margin-left-2"><Switch checked={showErrStat} defaultChecked={false} onChange={handleErrorListToggle} /> &nbsp; {IMPORT_CONTACT.SHOW_ONLY_ERROR_ROWS}</Row>
        </Space>
      </Row>
      <Row className="margin-top-2 margin-bottom-2">
        {errorDataSource?.filter(r => r.errors?.length).length > 0 ?
          <Typography.Text className="required"><Space><WarningOutlined /> {`There are ${errorDataSource?.filter(r => r.errors?.length).length} rows with error`}</Space></Typography.Text>
          : <Typography.Text className="validation-success"><Space><CheckCircleFilled />{IMPORT_CONTACT.NO_VALIDATE_ERROR}</Space></Typography.Text>}
      </Row>
      <Table
        className="import-contact-table"
        components={components}
        rowClassName={() => 'editable-row'}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        scroll={{ x: 1200, y: window.screen.height >= 768 ? '55vh' : "45vh" }}
        bordered={false}
        dataSource={paginatedData}
        columns={modifiedColumns as any}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: errorDataSource.length,
          onChange: (page: number, pageSize?: number) => setCurrentPage(page),
          onShowSizeChange: (current: number, size: number) => setPageSize(size),
          hideOnSinglePage: true,
          showSizeChanger: false,
        }}
      />
    </>
  );
};

export { EditableTable };
