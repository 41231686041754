/* eslint-disable no-unused-vars */
import {
  AppstoreAddOutlined,
  DeleteOutlined,
  EditOutlined,
  LinkOutlined,
  MailOutlined,
  MoreOutlined,
  UserOutlined
} from '@ant-design/icons';
import { leadActions, useAppSelector } from '@lyra/core';
import {
  AM_PERMISSIONS,
  ARCHIVE,
  CONFIRM_MESSAGE,
  CONFIRM_TITLE,
  DELETE_BTN,
  EDIT_BTN,
  RESTORE,
  TEXT,
  UNASSIGNED_LABEL,
  URL_DETAIL_LINK
} from '@moxie/constants';
import { Button, popConfirm } from '@shared-components/elements';
import { IContactCrm, ITableNameCardProps } from '@shared-components/models';
import { LeadContext } from '@shared-components/modules';
import {
  bulkDiscontinueApplications,
  getActiveContactApplications
} from '@lyra/services.api';
import { Col, Dropdown, Menu, Row, Space, Tooltip, Typography } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
// import Can, {
//   ContactsEntity,
//   PermissionAction,
//   PermissionObjects,
// } from 'apps/lyra/src/libs/privilege/can';
import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import isAssignedFollowed from '@lyra/assigned-followed';
import permit from '@lyra/permit';

const { Text, Paragraph } = Typography;

const userMenu = (
  archived: boolean,
  {
    onEdit,
    onArchive,
    onDelete,
  }: {
    onEdit: () => void;
    onArchive: () => void;
    onDelete: () => void;
  },
  data: IContactCrm,
  loggedInUser: any,
  rolePerms: any
) => {
  return (
    (isAssignedFollowed('contacts', data, loggedInUser?.id) ||
      permit([AM_PERMISSIONS.CONTACTS.MANAGE], rolePerms)) && (
      <Menu>
        {!archived && (
          <Menu.Item key={1} onClick={onEdit} icon={<EditOutlined />}>
            {EDIT_BTN}
          </Menu.Item>
        )}
        {/* {Can(PermissionAction.Archive, PermissionObjects.LeadsEntity) && ( */}
        <Menu.Item key={2} icon={<AppstoreAddOutlined />} onClick={onArchive}>
          {archived ? RESTORE : ARCHIVE}
        </Menu.Item>
        {/* )}
        {Can(PermissionAction.Delete, PermissionObjects.LeadsEntity) && ( */}
        <Menu.Item key={3} icon={<DeleteOutlined />} danger onClick={onDelete}>
          {DELETE_BTN}
        </Menu.Item>
        {/* )} */}
      </Menu>
    )
  );
};
export const LeadTableNameCard: React.FC<ITableNameCardProps | any> = ({
  firstName,
  lastName,
  email,
  index,
  id,
  data,
  userProp,
}: ITableNameCardProps | any) => {
  const dispatch = useDispatch();
  const [, setInitialData, , setOpen, , triggerRefresh] = useContext(
    LeadContext
  );
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const loggedUser = useAppSelector((state) => state.auth.user);
  const rolePerms: string[] = useAppSelector(
    (state: any) => state?.auth?.user?.am_role?.permissions
  );
  const handleEdit = () => {
    setInitialData(data);
    setOpen(true);
  };

  const onArchive = async () => {
    if (data?.archived) {
      dispatch(
        leadActions.editLeadAction({
          id,
          archived: false,
        })
      );
    } else {
      const { data } = await getActiveContactApplications(id);
      if (data?.data.length > 0) {
        finalArchiveConfirmation(id, data?.data);
      } else {
        dispatch(
          leadActions.editLeadAction({
            id,
            archived: true,
            archived_by: loggedUser?.id,
          })
        );
      }
    }
    triggerRefresh();
  };

  const finalArchiveConfirmation = (userId: string, applications: any) => {
    popConfirm(ARCHIVE, CONFIRM_MESSAGE.CONTACT_ARCHIVE_FINAL, () => {
      archive(userId, applications);
    });
  };

  const archive = async (id: string, applications: any) => {
    const applicationIds = applications.map(
      (d: any) => d.client_applications_id
    );
    await bulkDiscontinueApplications(applicationIds);
    dispatch(
      leadActions.editLeadAction({
        id,
        archived: true,
        archived_by: loggedUser?.id,
      })
    );
  };

  const onDelete = () => {
    if (data?.is_deleted)
      dispatch(leadActions.editLeadAction({ id, is_deleted: false }));
    else {
      dispatch(
        leadActions.editLeadAction({ id, is_deleted: true, softDelete: true })
      );
    }
    triggerRefresh();
  };

  const handleArchive = () => {
    popConfirm(
      !data?.archived ? ARCHIVE : RESTORE,
      !data?.archived
        ? `${CONFIRM_MESSAGE.ARCHIVE_LEAD} ${data?.first_name} ${data?.last_name}?`
        : `${CONFIRM_MESSAGE.RESTORE_LEAD} ${data?.first_name} ${data?.last_name}?`,
      onArchive
    );
  };

  const handleSingleLeadDelete = () => {
    popConfirm(
      CONFIRM_TITLE.DELETE_LEAD,
      `${CONFIRM_MESSAGE.DELETE_LEAD} ${data?.first_name} ${data?.last_name}?`,
      onDelete,
      true
    );
  };

  const menuActions = {
    onEdit: handleEdit,
    onArchive: handleArchive,
    onDelete: handleSingleLeadDelete,
  };

  return (
    <UserNameCard
      firstName={firstName}
      lastName={lastName}
      id={id}
      email={email}
      link={`${URL_DETAIL_LINK}${id}/profile-section`}
      actionBar={true}
      index={index}
      menu={userMenu(
        data?.archived || false,
        menuActions,
        data,
        loggedUser,
        rolePerms
      )}
      showEmailAsLink={true}
      showMailIcon={true}
    />
  );
};

export const UnassignedCard: React.FC = () => {
  return (
    <Space className="lead-table__name user-name-card">
      <Col className="align-items-center  user-name-card__avatar">
        <Avatar icon={<UserOutlined />} alt="unassigned" />
      </Col>
      <Col className="user-name-card__name ">
        <Typography.Text type="secondary">{UNASSIGNED_LABEL}</Typography.Text>
      </Col>
    </Space>
  );
};
export const UserNameCard: React.FC<ITableNameCardProps> = ({
  firstName,
  lastName,
  email,
  index,
  actionBar,
  link,
  menu,
  showEmailAsLink,
  showMailIcon,
  size,
  typeOption,
  handleEdit,
}: ITableNameCardProps) => {
  const [ellipsis] = useState(true);
  const [isCopied, setIsCopied] = useState(false);
  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
  };
  return (
    <Row className="lead-table__name user-name-card">
      <div className="align-items-center user-name-card__avatar initial_capital">
        {link ? (
          <Link to={link}>
            <Avatar size={size}>
              {firstName?.substring(0, 1)}
              {lastName?.substring(0, 1).toUpperCase()}
            </Avatar>
          </Link>
        ) : (
          <div onClick={handleEdit && handleEdit}>
            <Avatar size={size}>
              {firstName?.substring(0, 1)}
              {lastName?.substring(0, 1).toUpperCase()}
            </Avatar>
          </div>
        )}
      </div>
      <Col className="user-name-card__name">
        <div key={index}>
          <Col className="initial_capital">
            {link ? (
              <Link to={link}>
                <Text className="text-primary-color">{`${firstName} ${lastName}`}</Text>
              </Link>
            ) : (
              <>
                {showMailIcon ? (
                  <Col
                    onClick={handleEdit && handleEdit}
                    className="lead__hover-color"
                  >
                    <Text>{`${firstName} ${lastName}`}</Text>
                  </Col>
                ) : (
                  <Col onClick={handleEdit && handleEdit}>
                    <Text>{`${firstName} ${lastName}`}</Text>
                  </Col>
                )}
              </>
            )}
          </Col>
          <Col>
            <Row className="email_div">
              {showEmailAsLink === true ? (
                <>
                  {showMailIcon && (
                    <Col>
                      <a href={`mailto:${email}`}>
                        {' '}
                        <MailOutlined className="table-mail-icon" />{' '}
                      </a>
                    </Col>
                  )}
                  <Col lg={19}>
                    <small className="table-elements-email-container">
                      <a
                        className="text-primary-color"
                        href={`mailto:${email}`}
                      >
                        <Paragraph
                          ellipsis={
                            ellipsis ? { tooltip: `${email}` } : undefined
                          }
                          className="text-primary-color"
                        >
                          {email?.toLowerCase()}
                        </Paragraph>
                      </a>
                    </small>
                  </Col>
                </>
              ) : (
                <Col lg={18}>
                  <small>
                    <Paragraph
                      className="assigned-user-email text-primary-color"
                      ellipsis={ellipsis ? { tooltip: `${email}` } : undefined}
                    >
                      {email?.toLowerCase()}
                    </Paragraph>
                  </small>
                </Col>
              )}
              {showEmailAsLink && (
                <Col className="hide">
                  <Tooltip
                    className=" margin-left-1 "
                    placement="right"
                    color={isCopied ? '#8EAF74' : '#9B9D9A '}
                    title={
                      isCopied ? `${TEXT.COPIED_SUCCESS}` : `${TEXT.COPY_EMAIL}`
                    }
                  >
                    <LinkOutlined
                      className="copy_email_icon"
                      onClick={() => copyToClipboard(`${email}`)}
                    />
                  </Tooltip>
                </Col>
              )}
            </Row>
          </Col>
        </div>
      </Col>
      {actionBar && menu !== null && (
        <Col className="user-name-card__action">
          {menu && (
            <Dropdown overlay={menu}>
              <Button
                type="text"
                className="table-cell-action-btn"
                icon={<MoreOutlined />}
              />
            </Dropdown>
          )}
        </Col>
      )}
    </Row>
  );
};
