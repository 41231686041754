import React, { useMemo } from 'react';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row, Select } from 'antd';
import { FormInstance } from 'antd/lib/form/Form';
import { useEffect, useState } from 'react';
import { usePartnerAgentsMutation } from '../../hooks/use-contact-partner-agents';
import { SearchBox } from 'libs/shared/src/crm-modules/shared';
import { queryAgentPartner } from '@crm/libs/services.api/lib/agent-partner.api';
import { Agent } from '@model/data.model';
import { ApplicationDetail } from '@model/application';
import { successNotificationHandler } from '@moxie/shared';
import { useAppSelector } from '@crm/core';
import uniqBy from 'lodash/uniqBy';

interface IEditSuperAgent {
  form: FormInstance<any>;
  closeSuperAgentEdit: () => void;
  application: ApplicationDetail;
  setEditSuperAgent: React.Dispatch<React.SetStateAction<boolean>>;
}

export const EditSuperAgentComponent = ({
  closeSuperAgentEdit,
  application,
  setEditSuperAgent,
  form,
}: IEditSuperAgent) => {

  const socket = useAppSelector(store => store.socket.wss);
  const user = useAppSelector(store => store.auth.user);

  const [disabled, setDisabled] = useState(true);
  const superAgentMutation = usePartnerAgentsMutation(application.id);
  const [options, setOptions] = useState<Agent[]>([]);
  const [selectedAgent, setSelectedAgent] = useState<string | undefined>(application.agentPartner.superAgentPartnerId ?? undefined);
  const handleUpdateSuperAgent = async (body: any) => {
    superAgentMutation.mutate(
      {
        superAgentPartnerId: body?.superAgent ?? null,
        superAgentBranchId: body?.superAgentBranch ?? null,
      },
      {
        onSuccess() {
          setEditSuperAgent(false);
          successNotificationHandler('Super-Agent updated successfully.', 1.5);
          socket?.emit('server::application-updated', {
            activitiesAction: 'updated',
            activitiesType: 'application-super-agent',
            data: {
              data: application,
            },
            activitiesTypeId: application.id,
            userId: user?.id as string,
            companyId: user?.companyId as string,
            contactId: application.contactId,
          });
        },
      }
    );
  };

  const branches = useMemo(() => {
    if (!selectedAgent) {
      return []
    }
    const agent = options.find(o => o.id === selectedAgent);
    if (!agent) {
      return []
    }
    return agent.branches.map(branch => ({ label: branch.name, value: branch.id }))
  }, [selectedAgent, options])

  const fetcher = async (options: Record<string, unknown>) => {
    const res = await queryAgentPartner(options)
    setOptions(opts => uniqBy([...opts, ...res.data.data], 'id'))
    return res
  }

  const onValueChange = (data: any) => {
    if (!data) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  const onChange = (value: string | undefined) => {
    setSelectedAgent(value);
    if (!value) {
      form.setFieldsValue({ superAgent: null, superAgentBranch: null });
      setDisabled(false);
      return;
    }
    const agent = options.find(o => o.id === value);
    form.setFieldsValue({
      superAgentBranch: agent?.branches.find(branch => branch.isMainBranch)?.id ?? null,
    });
    setDisabled(false);
  }

  useEffect(() => {
    if (
      application?.agentPartner &&
      application?.agentPartner?.superAgentPartnerId !== null
    ) {
      form.setFieldsValue({
        superAgent: application?.agentPartner?.superAgentPartnerId,
        superAgentBranch:
          application?.agentPartner?.superAgentBranchId,
      });
    }
  }, []);

  return (
    <Form
      layout="inline"
      form={form}
      onFinish={handleUpdateSuperAgent}
      onValuesChange={onValueChange}
    >
      <Row>
        <Col span={10}>
          <Form.Item
            data-testid="superagentname"
            name="superAgent"
            className="initial_capital agent-partner-edit"
          >
            <SearchBox
              // TODO:: replace with dot object
              filter={{ 'filter.isSuperAgent': true }}
              fetchQuery={fetcher}
              labelKey={'name'}
              valueKey="id"
              value={selectedAgent}
              onChange={onChange}
              allowClear
              onClear={() => onChange(undefined)}
            />
          </Form.Item>
        </Col>

        <Col span={10}>
          <Form.Item
            data-testid="superagentbranch"
            name="superAgentBranch"
            className="initial_capital agent-partner-edit"
          >
            <Select disabled={!selectedAgent} options={branches} />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item>
            <Button
              icon={<CheckOutlined data-testid="superagentcheck" />}
              type="text"
              size="small"
              onClick={() => form.submit()}
              disabled={disabled}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item>
            <Button
              icon={<CloseOutlined data-testid="superagentcross" />}
              type="text"
              danger
              size="small"
              onClick={closeSuperAgentEdit}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
