import { crmAxios } from '@crm/services.api';
import { AGENT_PARTNER_MESSAGE, API_URL } from '@moxie/constants';
import {
  errorNotificationHandler,
  successNotificationHandler
} from '@moxie/shared';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const addAgent = (payload: any) => {
  return crmAxios.post(API_URL.CRM_API_AGENT, payload);
};
const updateAgent = (id: string,payload: any) => crmAxios.put(`${API_URL.CRM_API_AGENT}/${id}`, payload);

export const useAgentMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (payload: any) => addAgent(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['agents'] });
      successNotificationHandler(AGENT_PARTNER_MESSAGE.CREATED);
    },
    onError: () => {
      errorNotificationHandler('Error adding agents.');
    },
  });
};

export const useAgentUpdateMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({id, payload}: { id: string,payload: unknown }) => updateAgent(id,payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['agents'] });
      successNotificationHandler(AGENT_PARTNER_MESSAGE.UPDATED);
    },
    onError: () => {
      errorNotificationHandler('Error adding agents.');
    },
  });
};

