import { WorkflowMetadata } from "@model/application";
import { DatePicker, Space, Typography } from "antd";
import { UpdateApplicationPayload } from '@model/application';
import { Moment } from 'moment';
import moment from 'moment';
import { ActivitiesInfo, IField } from "../../fields-data";

const { Text } = Typography

interface Props<T extends string> {
  field: IField<T>;
  updateData: (payload: UpdateApplicationPayload, activitiesInfo: ActivitiesInfo, field: IField<T>) => void;
  isCompleted: boolean;
  activitiesInfo: ActivitiesInfo;
  defaultValue: moment.Moment | undefined;
  picker: 'date' | "month";
  getPayload: (workflowMetadata: WorkflowMetadata, data: Moment | null) => UpdateApplicationPayload;
  workflowMetadata: WorkflowMetadata | undefined;
}

export const DateField = <T extends string,>({
  field,
  updateData,
  isCompleted,
  activitiesInfo,
  defaultValue,
  picker = "date",
  getPayload,
  workflowMetadata,
}: Props<T>) => {

  return (
    <Space direction="vertical" key={field.field}>
      <Text strong className="initial_capital">
        {field.label}
      </Text>
      <DatePicker
        format="DD/MM/YYYY"
        placeholder="DD/MM/YYYY"
        defaultValue={defaultValue}
        picker={picker}
        onChange={(data: any) => {
          updateData(getPayload(workflowMetadata as WorkflowMetadata, data), activitiesInfo, field);
        }}
        allowClear={false}
        disabled={isCompleted}
      />
    </Space>
  )
}
