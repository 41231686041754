import React from 'react';
import { createContext, useState } from "react";
import { ApplicationDetail, SelectedStage } from "@model/application";
import { useApplicationDetails } from "./hooks/useApplicationDetails";
import { useParams } from "react-router-dom";
import { ApplicationWorkflowStageChecklist, ApplicationWorkflowStageDocument, WorkflowApplicationStage } from "@model/workflow";
import { useFetchChecklistDocs } from './hooks/useFetchChecklistDocs';
import { useFetchAllRequiredDocs } from './hooks/useFetchAllRequiredDocs';

interface Values {
  applicationData: ApplicationDetail;
  isLoadingApplication: boolean;
  isCompletedStage: (val: WorkflowApplicationStage) => boolean;
  isCurrentStage: (val: WorkflowApplicationStage) => boolean;
  isSelectedStage: (val: WorkflowApplicationStage) => boolean;
  setSelectedStage: React.Dispatch<React.SetStateAction<SelectedStage>>
  selectedStage: SelectedStage;
  stageDocs: ApplicationWorkflowStageChecklist[];
  isLoadingDocs: boolean,
  service?: string,
  allRequiredDocouments: ApplicationWorkflowStageDocument[];
  isLoadingRequiredDocuments: boolean;
  refetchApplicationDetails: () => void;

}
const ApplicationDetailContext = createContext<Values>({
  applicationData: {} as ApplicationDetail,
  isLoadingApplication: true,
  isCompletedStage: (val) => true,
  isCurrentStage: (val) => true,
  isSelectedStage: (val) => true,
  setSelectedStage: (val) => { return },
  selectedStage: {
    level: null,
    stage: null,
    wfAppStageId: null,
  },
  stageDocs: [],
  isLoadingDocs: false,
  service: "",
  allRequiredDocouments: [],
  isLoadingRequiredDocuments: false,
  refetchApplicationDetails: () => { return }
})

export const ApplicationDetailProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const {
    applicationId,
  }: { contactId: string; applicationId: string } = useParams();
  const { applicationData, isLoadingApplication, refetch } = useApplicationDetails(
    applicationId
  );
  const allworkflowStageIds: string[] = applicationData?.workflow?.workflowApplicationStages
    ? applicationData.workflow.workflowApplicationStages.map((stage) => stage.id)
    : [];

  const { allRequiredDocouments, isLoadingRequiredDocuments } = useFetchAllRequiredDocs(
    allworkflowStageIds
  );

  const [selectedStage, setSelectedStage] = useState<SelectedStage>({
    stage: null,
    level: null,
    wfAppStageId: null,
  });

  const refetchApplicationDetails = () => {
    refetch();
  }

  const isCompletedStage = (stageInfo: WorkflowApplicationStage) => {
    return stageInfo.level < (applicationData?.activeStage?.level ?? 0);
  };

  const isCurrentStage = (stageInfo: WorkflowApplicationStage) => {
    return stageInfo.level === applicationData?.activeStage?.level;
  };

  const isSelectedStage = (stageInfo: WorkflowApplicationStage) => {
    return stageInfo.level === selectedStage?.level;
  };

  const { stageDocs, isLoadingDocs } = useFetchChecklistDocs(
    selectedStage?.wfAppStageId
  );

  return (
    <ApplicationDetailContext.Provider value={
      {
        applicationData: applicationData as ApplicationDetail,
        isCompletedStage,
        isLoadingApplication,
        isCurrentStage,
        isSelectedStage,
        setSelectedStage,
        selectedStage,
        stageDocs,
        isLoadingDocs,
        service: applicationData?.workflow.workflowType.service.name,
        allRequiredDocouments,
        isLoadingRequiredDocuments,
        refetchApplicationDetails
      }
    }>
      {children}
    </ApplicationDetailContext.Provider>
  )
}


export default ApplicationDetailContext;
