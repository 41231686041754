import { importContacts } from "@crm/services.api";
import { successNotificationHandler } from "@moxie/shared";
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { LEAD_IMPORT_MESSAGE } from "@moxie/constants";
import { ICrmContactImport } from "@model/index";

export const useContactImport = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: ICrmContactImport[]) => importContacts(body),
    onSuccess: () => {
      successNotificationHandler(LEAD_IMPORT_MESSAGE.IMPORT_SUCCESS);
      queryClient.invalidateQueries({
        queryKey: ['import-contacts'],
      })
    },
  })
}
