import { useAppSelector } from '@crm/core';
import { updateDeals } from '@crm/libs/services.api/lib/deals.api';
import { IAssignee, InitialServices } from '@model/crm/deal.model';
import {
  errorNotificationHandler,
  successNotificationHandler
} from '@moxie/shared';
import { useMutation, useQueryClient } from '@tanstack/react-query';

interface UpdatePayload {
  id: string;
  internalId?: string;
  data: {
    payload?: {
      countries?: string[];
      typeOfService?: string;
      services?: string[];
    };
    assignees?: { id: string }[];
    interestedServiceId?: string;
    name?: string;
    branchId?: any;
    dealInternalId?: string;
    status?: string;
    applicationIds?: string[];
  };
  contactId: string,
  assignees?: IAssignee[],
  previousAssignees?: IAssignee[],
  previousInterestedServices?: InitialServices
  interestedServices?: InitialServices
}

export const useUpdateDeals = (triggerRefresh?: () => any) => {
  const queryClient = useQueryClient();
  const socket = useAppSelector((state) => state.socket.wss);
  const user = useAppSelector((state) => state.auth.user);

  return useMutation({
    mutationFn: (payload: UpdatePayload) => updateDeals(payload),
    onSuccess: (data, variables) => {
      successNotificationHandler('Deals updated successfully.');
      triggerRefresh && triggerRefresh();

      const updatedData = variables.previousAssignees ? {
        previousAssignees: variables.previousAssignees,
        assignees: variables.data?.assignees?.map((assignee) => assignee.id),
        dealInternalId: variables.internalId,
      } : {
        previousInterestedServices: variables.previousInterestedServices,
        interestedServices: variables.data?.payload,
        dealInternalId: variables.internalId,
      }

      socket?.emit('server::deal-updated', {
        userId: user?.id as string,
        companyId: user?.companyId as string,
        contactId: variables.contactId,
        activitiesType: 'deal',
        activitiesAction: 'updated',
        activitiesTypeId: variables.id,
        data: updatedData,
      });

      queryClient.invalidateQueries({
        queryKey: ['deals-listing'],
      });
      queryClient.invalidateQueries({
        queryKey: ['deal-details', variables.id],
      });
    },
    onError: () => {
      errorNotificationHandler('Error updating deals.');
    },
  });
};


export const useEditDeals = (contactId: string, dealId?: string, triggerRefresh?: () => any) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (payload: UpdatePayload) => await updateDeals(payload),
    onSuccess: (data, variables) => {
      successNotificationHandler('Deals updated successfully.');
      triggerRefresh && triggerRefresh();
      queryClient.invalidateQueries({
        queryKey: ['deals-listing'],
      });
      queryClient.invalidateQueries({
        queryKey: ['deal-details', dealId],
      });
      queryClient.invalidateQueries({
        queryKey: ['contact-deals-activity', contactId],
      })
      queryClient.invalidateQueries({
        queryKey: ['deal-applications', dealId],
      })
      queryClient.invalidateQueries({
        queryKey: ['contact-application-assignee', contactId],
      });
    },
    onError: () => {
      errorNotificationHandler('Error updating deals.');
    },
  });
};
