import Modal from "antd/es/modal";
import Typography from "antd/es/typography";
import { Row } from "antd/es/grid";
import Button from "antd/es/button";

type Status = "Complete" | "In Progress";

const { Text } = Typography;

interface IProps {
  isOpen: boolean;
  status: Status;
  modalTitle: React.ReactNode;
  btnTitle: string;
  handleClose: () => void;
  handleDealMarkStatus: () => void;
  isSubmitting?: boolean;
}

export const MarkDealStatusConfirmModal = ({
  isOpen,
  status,
  handleClose,
  isSubmitting = false,
  handleDealMarkStatus,
  btnTitle,
  modalTitle,
}: IProps) => {
  return (
    <Modal
      title={modalTitle}
      width={500}
      visible={isOpen}
      closable={false}
      onCancel={handleClose}
      maskClosable={false}
      footer={
        <Row style={{ columnGap: '0.5rem' }} justify="end">
          {status !== "In Progress" ?
            (
              <Button htmlType="reset" onClick={handleClose} disabled={isSubmitting}>
                Cancel
              </Button>
            )
            : null}

          <Button
            type="primary"
            htmlType="button"
            onClick={() => handleDealMarkStatus()}
            loading={isSubmitting}
          >
            {btnTitle}
          </Button>
        </Row>
      }
      style={{ border: 'none', boxShadow: 'none' }}
      bodyStyle={{ padding: '16px', border: 'none' }}
      className="custom-modal"
    >
      {status === "In Progress" ?
        <Text>Deal status changed to In Progress.</Text>
        :
        <Text>Do you want to change the deal status to &quot; {status} &quot;?</Text>
      }
    </Modal>
  )
}
