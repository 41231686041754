import React, { useEffect, useState } from 'react';
import { DrawerElem } from '@shared-components/elements';
import {
  useAgentMutation,
  useAgentUpdateMutation
} from '../../hooks/useAgentMutation';
import { Button, Space } from 'antd';
import {
  ADD_PARTNER_AGENT,
  CANCEL_BTN,
  DRAWER_WIDTH,
  EDIT_PARTNER_AGENT,
  SAVE_BTN,
  UPDATE_BTN
} from '@moxie/constants';
import { useForm } from 'antd/lib/form/Form';
import AgentsForm, { AgentFormFields } from './form';
import { Agent } from '@model/data.model';

interface Props {
  showDrawer: boolean;
  handleDrawerVisibility: (bool: boolean) => void;
  resetPage: () => void;
  agent: Agent | undefined;
}

const Drawer: React.FC<Props> = ({
  showDrawer,
  handleDrawerVisibility,
  resetPage,
  agent,
}) => {
  const [form] = useForm<AgentFormFields>();
  const agentMutation = useAgentMutation();
  const updateAgentMutation = useAgentUpdateMutation();
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(true);
  const [deleteBranches, setDeleteBranches] = useState<string[]>([]);
  const [mainBranch, setMainBranch] = useState<number | undefined>();
  const isEdit = Boolean(agent);

  const getAgentTypeSelectValue = (agent: Agent) => {
    if (agent.isSubAgent && agent.isSuperAgent) {
      return 'both';
    }

    if (agent.isSuperAgent) {
      return 'isSuperAgent';
    }

    if (agent.isSubAgent) {
      return 'isSubAgent';
    }
    return null;
  };

  useEffect(() => {
    setSubmitBtnDisabled(true);
    if (agent) {
      const branches = agent.branches.map((branch: any) => {
        branch.branchIndex = Math.floor(Math.random() * 1000);
        return branch;
      });

      const main = branches.find((branch) => branch.isMainBranch);

      if (main) {
        setMainBranch(main?.branchIndex);
      }
      form.setFieldsValue({
        ...agent,
        branches,
        type: getAgentTypeSelectValue(agent),
      });
    } else {
      const branchIndex = Math.floor(Math.random() * 1000);
      form.resetFields();
      form.setFieldsValue({ branches: [{ branchIndex }] });
      setMainBranch(branchIndex);
    }
  }, [agent]);

  const handleSubmitBtnStatus = (status: boolean) =>
    setSubmitBtnDisabled(status);

  const drawerFormReset = () => {
    handleDrawerVisibility(false);
    form.resetFields();
    setMainBranch(0);
    form.setFieldsValue({ branches: [{ branchIndex: 0 }] });
  };

  const getAgentTypeInfo = (type: string) => ({
    isSubAgent: Boolean(['isSubAgent', 'both'].includes(type)),
    isSuperAgent: Boolean(['isSuperAgent', 'both'].includes(type)),
  });

  const handleFormSubmit = async (data: any) => {
    const { type } = data;
    const typeInfo = getAgentTypeInfo(type);
    delete data.type;
    const payload = { ...data, ...typeInfo };

    payload.branches = payload?.branches?.map(
      (branch: { isMainBranch: boolean; branchIndex: number }) => {
        const { branchIndex, ...rest } = branch;

        rest.isMainBranch = branchIndex === mainBranch;
        return rest;
      }
    );
    if (agent?.id) {
      payload.deleteBranches = deleteBranches;
      await updateAgentMutation.mutateAsync({ id: agent?.id, payload });
    } else {
      await agentMutation.mutateAsync(payload);
    }
    setDeleteBranches([]);
    drawerFormReset();
    resetPage();
  };

  const handleFormFieldsChange = (allFields: string | any[]) => {
    if (allFields.length === 1) {
      handleSubmitBtnStatus(false);
    }
  };
  return (
    <DrawerElem
      title={isEdit ? EDIT_PARTNER_AGENT : ADD_PARTNER_AGENT}
      width={DRAWER_WIDTH}
      data-testid="crm-addagentdrawer"
      onClose={() => drawerFormReset()}
      visible={showDrawer}
      footer={
        <div className="padding-bottom-2 text-align-right">
          <Space direction="horizontal">
            <Button
              onClick={() => drawerFormReset()}
              className="lead-margin-right"
            >
              {CANCEL_BTN}
            </Button>
            <Button
              onClick={() => form.submit()}
              type="primary"
              disabled={submitBtnDisabled}
            >
              {isEdit ? UPDATE_BTN : SAVE_BTN}
            </Button>
          </Space>
        </div>
      }
    >
      <AgentsForm
        form={form}
        formSubmit={handleFormSubmit}
        onFieldsChange={handleFormFieldsChange}
        removeBranch={setDeleteBranches}
        onBranchChange={(val: number) => {
          setMainBranch(val);
          setSubmitBtnDisabled(false);
        }}
        mainBranch={mainBranch}
      />
    </DrawerElem>
  );
};

export default Drawer;
