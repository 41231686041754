import { UserAvatarGroup } from '@crm/src/shared/user-avatar-group';
import UnassignedCard from '@crm/src/shared/user-card/unassigned-card';
import { Assignee, UpdateApplicationPayload, WorkflowMetadata } from '@model/application';
import {
  UserNameCard
} from '@shared-components/elements';
import { Select, Space, Typography } from 'antd';
import { useState } from 'react';
import { IField, ActivitiesInfo } from '../../fields-data';

interface Props<T extends string> {
  field: IField<T>;
  updateData: (payload: UpdateApplicationPayload, activitiesInfo: ActivitiesInfo, field: IField<T>) => void;
  isCompleted: boolean;
  activitiesInfo: ActivitiesInfo;
  workflowMetadata: WorkflowMetadata | undefined;
  applicationAssignees: Assignee[]
}

const { Text } = Typography

export const RmaField = <T extends string,>({
  field,
  updateData,
  workflowMetadata,
  isCompleted,
  activitiesInfo,
  applicationAssignees,
}: Props<T>) => {
  const [isSelected, setIsSelected] = useState(false);
  const [assigneeOptions, setAssigneeOptions] = useState(applicationAssignees);
  const rmaUserList = applicationAssignees
    ?.filter((assignee) => workflowMetadata?.RMA?.includes(assignee.id))
    .map((assignee) => ({
      firstName: assignee.firstName,
      lastName: assignee.lastName,
    }));

  const handleSearch = (value: string) => {
    if (value) {
      setAssigneeOptions(
        applicationAssignees.filter(
          (assignee) =>
            assignee.email?.toLowerCase().includes(value) ||
            assignee.firstName.toLowerCase().includes(value) ||
            assignee.lastName.toLowerCase().includes(value)
        )
      );
    } else {
      setAssigneeOptions(applicationAssignees);
    }
  };

  return (
    <Space
      direction="vertical"
      id="visa-country-list"
      key={field.field}
    >
      <Text strong>RMA</Text>
      {isSelected && !isCompleted ? (
        <Select
          style={{ width: '100%' }}
          defaultOpen={true}
          className="assignee-select"
          mode={'multiple'}
          maxTagCount={1}
          autoFocus
          optionLabelProp="label"
          optionFilterProp="label"
          filterOption={false}
          defaultValue={workflowMetadata?.RMA}
          onChange={(value) => {
            updateData(
              {
                workflowMetadata: {
                  ...workflowMetadata,
                  RMA: value,
                },
              },
              activitiesInfo,
              field
            );
            setAssigneeOptions(applicationAssignees);
          }}
          onBlur={() => {
            setIsSelected(false);
            setAssigneeOptions(applicationAssignees);
          }}
          onSearch={(value) => handleSearch(value)}
          disabled={isCompleted}
        >
          {assigneeOptions.map((assignee) => {
            return (
              <Select.Option
                key={assignee.id}
                value={assignee.id}
                label={`${assignee.firstName} ${assignee.lastName}`}
              >
                <UserNameCard
                  firstName={assignee.firstName}
                  lastName={assignee.lastName}
                  email={assignee.email}
                />
              </Select.Option>
            );
          })}
        </Select>
      ) : (
        <div
          onClick={() => {
            setIsSelected(true)
            setAssigneeOptions(applicationAssignees)
          }}>
          {rmaUserList.length ? (
            <UserAvatarGroup userList={rmaUserList} />
          ) : (
            <UnassignedCard className="padding-left-2" />
          )}
        </div>
      )}
    </Space>
  );
}
