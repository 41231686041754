import { FilterFilled, FilterOutlined } from '@ant-design/icons';
import { useAppSelector } from '@crm/core';
import { getContactsReport } from '@crm/services.api';
import { IContactsReportTable } from '@model/data.model';
import { TEXT } from '@moxie/constants';
import { Button, Pagination, Row, Table } from 'antd';
import { Excel } from 'antd-table-saveas-excel';
import React, { useEffect, useState } from 'react';
import { useGetContactsReport } from '../hooks/useGetContactsReport';
import ContactReportDrawer from './contact-report-drawer';
import { ContactTableColumns } from './contact-table-columns';
import { exportContactReportColumns } from './export-contact-report-columns';
import { useLocation } from 'react-router';

interface QueryFilter {
  assignees: string
  branch: string;
  country: string;
  state: string;
  countryOfPassport: string;
  page: number;
}
type QueryFilterKey = keyof QueryFilter

const fields = ['assignees', 'branch', 'country', 'state', 'countryOfPassport', 'page'];

const getQueryParamValue = (searchParams: URLSearchParams, field: QueryFilterKey) => {
  const fieldValue = searchParams?.getAll(field);
  const result = fieldValue.length ? fieldValue[0]?.split(',').filter(str => str.length) : [];
  return result;
}

function isShowingFilteredData(data: any): boolean {
  return Object.values(data).some((item) => {
    if (Array.isArray(item)) {
      return item.length > 0;
    }
    return false;
  });
}

const ContactReportTable: React.FC<IContactsReportTable> = ({
  openDrawer,
  closeDrawer,
  drawerVisibility,
  filter,
  setFilter,
  form,
  disabled,
  setDisabled,
}) => {
  const limit = 10;
  const pageSize = 10;
  const [page, setPage] = useState(1);
  const [reportParams, setReportParams] = useState<string>('');
  const location = useLocation();

  const userTimeZone =
    useAppSelector((store) => store?.auth?.user?.timeZone) || '';

  const {
    contactsReportData,
    isLoading,
    meta,
    hasMorePage,
  } = useGetContactsReport({ page, limit, reportParams });

  const handlePageChange = (page: number) => {
    setPage(page);
    setReportParams(val => `${val}`)
  };

  const toArray = (val: string) => {
    if (val === '') {
      return [];
    }
    return val.split(',')
  }

  useEffect(() => {
    const query = location.search.replace(/[?]/g, '')
    const data = Object.fromEntries(new URLSearchParams(query))

    setReportParams(query)
    form.setFieldsValue({
      assignees: toArray(data?.assignees ?? ''),
      branch: toArray(data?.branch ?? ''),
      countryOfPassport: toArray(data?.countryOfPassport ?? ''),
      country: toArray(data?.country ?? ''),
      state: toArray(data?.state ?? ''),
    })

    if (data.page) {
      setPage(Number(data.page));
    }
  }, [])

  const handleExport = async () => {
    const response = await getContactsReport(`${reportParams}&exportReport=true`);
    const excel = new Excel();
    excel
      .addSheet('data')
      .addColumns(exportContactReportColumns(userTimeZone))
      .addDataSource(response?.data?.data)
      .saveAs('contact_report.xlsx');
  };

  return (
    <div className="bg-white padding-top-2">
      <Row justify="space-between" className="margin-right-1 padding-bottom-2">
        <Button className="margin-left-1" onClick={openDrawer}>
          {!isShowingFilteredData(filter) ? (
            <FilterOutlined />
          ) : (
            <FilterFilled className="table-column-search-btn" />
          )}
          {TEXT.APPLY_FILTERS}
        </Button>
        <Button type="primary" onClick={() => handleExport()}>
          {TEXT.EXPORT}
        </Button>
      </Row>
      <Table
        className="padding-1 overflow-auto"
        pagination={false}
        loading={isLoading}
        scroll={{ y: '59vh' }}
        dataSource={contactsReportData}
        columns={ContactTableColumns(setFilter)}
        rowKey="id"
      />
      <Row justify="end">
        <Pagination
          className="margin-top-2 margin-bottom-2 padding-right-2"
          showSizeChanger={false}
          pageSizeOptions={['10', '25', '50', '100']}
          onChange={handlePageChange}
          hideOnSinglePage={hasMorePage}
          total={meta?.totalItems}
          pageSize={pageSize}
          current={page}
          showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
        />
      </Row>
      <ContactReportDrawer
        page={page}
        form={form}
        onClose={() => {
          closeDrawer();
          const searchParams = new URLSearchParams(reportParams);
          const fieldsValue = fields
            .reduce((obj: Record<string, string[]>, fieldName) => {
              obj[fieldName] = getQueryParamValue(searchParams, fieldName as QueryFilterKey)
              return obj;
            }, {});

          form.setFieldsValue(fieldsValue)
        }}
        disabled={disabled}
        setDisabled={setDisabled}
        drawerVisibility={drawerVisibility}
        setReportParams={setReportParams}
        setFilter={setFilter}
      />
    </div>
  );
};

export default ContactReportTable;
