import { PlusOutlined } from '@ant-design/icons';
import {
  ADD_LEAD,
  ASSIGNEE_LABEL,
  LABEL,
  TEXT,
  URL_DETAIL_LINK
} from '@moxie/constants';
import { AuthUser, IOfficeBranch, IUser } from '@shared-components/models';
import { Button, Col, Row, Typography } from 'antd';
import { RootState } from 'apps/crm/src/core/store';
import React, { ReactElement, ReactNode, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useCanAccess } from '@crm/libs/privilege/useCanAccess';
import { getcontactList } from '@crm/services.api';
import { IUserData } from 'libs/shared/src/crm-modules/user/user.model';
import { ContactTableNameCard } from '../contact/components/contact-add-edit/contact-table-name-card';
import { UserCard } from '../../shared/user-card/user-card';
import { UnassignedCard } from '../contact/components/assignee-card/user-name-card';
import { DateFormat } from '@crm/shared';
import { colSorter } from 'libs/shared/src/elements/crm-table/colSorter';
import { CrmTable } from '@shared-components/elements';
import { useAssignedBranch } from '@crm/libs/hooks/contacts';
import { IContactCrm } from '@model/crm/contact.model';
import SelectBranch from '../contact/select-branch';

interface ContactColProps {
  currentUser: IUserData | (IUser & AuthUser) | null;
  triggerRefresh: () => void;
  setInitialData: React.SetStateAction<IContactCrm | undefined>;
  setIsDrawerOpen: React.SetStateAction<boolean>;
  setIsEditMode: React.SetStateAction<boolean>;
  canAccess: any;
}

interface IContactTypeMap {
  [key: string]: string;
}

const { Text } = Typography;

const useColumns = ({ currentUser, triggerRefresh, setInitialData, setIsDrawerOpen, setIsEditMode, canAccess }: ContactColProps) => {
  const cols = [
    {
      title: TEXT.INTERNAL_ID,
      dataIndex: 'internalId',
      key: 'internalId',
      render: (value: string, data: IContactCrm): ReactNode => {
        return (
          //   // TODO:: ADD COMPANY CODE
          <Link to={`${URL_DETAIL_LINK}${data?.id}/deals`}>
            <Text className="text-primary-color initial_capital">
              {data?.company?.companyCode ? data?.company?.companyCode + '-' + value : value}
            </Text>
          </Link>
        );
      },
    },
    {
      title: LABEL.NAME,
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: () => 0,
      render: (_text: string, row: IContactCrm, index: number): ReactElement => (
        <ContactTableNameCard
          id={row.id}
          firstName={row.firstName || ''}
          applicationData={row.applications}
          email={row.email || ''}
          lastName={row.lastName}
          data={row}
          index={index.toString()}
          userProp={currentUser}
          triggerRefresh={triggerRefresh}
          setInitialData={setInitialData}
          setIsDrawerOpen={setIsDrawerOpen}
          setIsEditMode={setIsEditMode}
          hideMenu={!canAccess('contact', 'update')}
        />
      ),
    },
    {
      title: LABEL.PHONE_NUMBER,
      dataIndex: 'phone',
      key: 'phone',
      render: (_text: string, row: IContactCrm): ReactNode => {
        const phoneNumber = row?.countryCode && row?.phone ? `${row?.countryCode} ${row?.phone}` : '-';
        return (
          <Row justify={phoneNumber === '-' ? 'center' : 'start'} className="lead-table__phone">
            <Col>
              {phoneNumber}
            </Col>
          </Row>
        );
      },
    },
    {
      title: LABEL.PRIMARY_BRANCH,
      dataIndex: 'branch',
      key: 'branch',
      render: (branch: IOfficeBranch): ReactNode => {
        return (
          <div className="lead-table__city">
            <span>{branch ? branch?.name : '-'}</span>
          </div>
        );
      },
    },
    {
      title: ASSIGNEE_LABEL,
      dataIndex: 'assignee',
      key: 'assigneeId',
      width: 300,
      render: (text: string, data: IContactCrm): ReactElement => {
        return data?.assigneeId !== null ? (
          <UserCard
            firstName={data?.assignee?.firstName || ''}
            lastName={data?.assignee?.lastName || ''}
            email={data?.assignee?.email || ''}
            showEmailAsLink={true}
          />
        ) : (
          <UnassignedCard />
        )
      },
    },
    {
      title: LABEL.LAST_UPDATED,
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sorter: () => 0,
      render: (text: string) => {
        return <DateFormat date={text} />
      },
    },
  ];

  return React.useMemo(() => {
    return cols.map((c) => colSorter(c as any))
  }, [])
}

const ProspectTableList = ({
  refresh,
  handleAddContact,
  triggerRefresh,
  setInitialData,
  setIsDrawerOpen,
  setIsEditMode,
}: any) => {
  const currentUser = useSelector((store: RootState) => store.auth.user);
  const [branch, setBranch] = useState<string>();
  const canAccess = useCanAccess();

  const { name } = useParams<{ name: string; }>();

  const modifiedObj: IContactTypeMap = {
    "all-prospects": "all-contacts",
    "my-prospects": "my-contacts",
    "archived": "archived-contacts",
  }

  const filter = useMemo(() => {
    return { branch, status: 'Prospect' };
  }, [branch, name])

  const { branches } = useAssignedBranch();

  const location = useLocation();

  useEffect(() => {
    if (branches.length > 0 && branches.find(b => b.id === currentUser?.branchId)) {
      const params = new URLSearchParams(location.search.replace('?', ''));
      const preSelectedBranch = params.get('branch');
      if (preSelectedBranch) {
        setBranch(preSelectedBranch);
      } else {
        setBranch(currentUser?.branchId)
      }
    }
  }, [branches, currentUser])

  const columns = useColumns({ currentUser, triggerRefresh, setInitialData, setIsDrawerOpen, setIsEditMode, canAccess });

  return (
    <div className="bg-white padding-top-2">
      <CrmTable
        key={name}
        tableName={TEXT.PROSPECTS}
        filterContent={
          <SelectBranch
            branch={branch as string}
            handleBranchChange={setBranch}
          />
        }
        filter={filter as Record<string, unknown>}
        action={getcontactList}
        type={modifiedObj[name] as string}
        columns={columns}
        size={'small'}
        refresh={refresh}
        tableButtons={
          <>
            <Button
              type="primary"
              onClick={handleAddContact}
              data-testid="crm-addleadbutton"
            >
              <PlusOutlined />
              {ADD_LEAD}
            </Button>
          </>
        }
      />
    </div>
  );
};

export { ProspectTableList };
