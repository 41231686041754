import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  errorNotificationHandler,
  successNotificationHandler
} from '../../../../../../libs/shared/src';
import {
  getAllCRMProductLanguageRequirements,
  getCRMProductLanguageRequirements,
  postCRMProductLanguageRequirements
} from '@crm/services.api';
import { ITestTypeForForm } from '@model/data.model';

export const usePostCRMProductLanguageRequirements = (id: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: ITestTypeForForm) =>
      postCRMProductLanguageRequirements(body),
    onSuccess: async () => {
      successNotificationHandler('Language score added successfully');
      queryClient.invalidateQueries({
        queryKey: ['CRMProductLanguageRequirements'],
      });
    },
    onError: () => {
      errorNotificationHandler('Error posting test score');
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['CRMProductLanguageRequirements'],
      });
    },
  });
};

export const useGetCRMProductLanguageRequirements = (
  id: string | undefined
) => {
  const { isLoading, data } = useQuery({
    queryKey: ['CRMProductLanguageRequirements', id],
    queryFn: () => getCRMProductLanguageRequirements(id!),
    enabled: id !== undefined,
  });
  return {
    languageRequirementIsLoading: isLoading,
    languageData: data?.data?.data,
  };
};

export const useGetAllCRMProductLanguageRequirements = (
  id: string | undefined
) => {
  const { isLoading, data } = useQuery({
    queryKey: ['CRMProductLanguageRequirements', id],
    queryFn: () => getAllCRMProductLanguageRequirements(id!),

    enabled: id !== undefined,
  });
  return {
    isLoading,
    data: data?.data?.data,
  };
};
