import React from 'react';
import { Card, Col, Empty, Row, Tooltip, Typography } from 'antd';
import { useAppSelector } from '@crm/core';
import { RootState } from 'apps/crm/src/core/store';
import {
  MailOutlined,
  PhoneOutlined,
  UserOutlined,
  EnvironmentOutlined
} from '@ant-design/icons';
import { TEXT } from '@moxie/constants';
import { EditEmergencyContactInfoDrawer } from './edit-emergency-contact-info-drawer';

const { Text } = Typography;

const EmergencyContactDetailsPanel = ({
  showDrawer,
  setShowDrawer,
}: {
  showDrawer: boolean;
  setShowDrawer: any;
}) => {
  const data = useAppSelector(
    (state: RootState) => state?.contact?.singleData?.emergencyContact
  );

  return (
    <>
      <div className="profile-panel-content">
        {data!.length > 0 ? (
          data?.map((contact: any) => (
            <Card
              key={contact?.id}
              className="emergency-contact-card margin-bottom-1"
            >
              <Row className="full-width">
                <span>
                  <h4 className="text-primary initial_capital">
                    {contact?.relationship}
                  </h4>
                </span>
                <span className="margin-left-1-half-px">:</span>
              </Row>
              <Row className="full-width">
                <Col span={1}>
                  <UserOutlined className="table-mail-icon font-weight-500" />
                </Col>
                <Col span={23} className="padding-left-1 initial_capital">
                  {contact?.contactName &&
                    contact?.contactName?.length > 30 ? (
                      <Tooltip title={contact?.contactName} placement="bottom">
                        <Text
                          className="contact-profile-email font-weight-500 initial_capital"
                          ellipsis={true}
                        >
                          {contact?.contactName}
                        </Text>
                      </Tooltip>
                    ) : (
                      <Text className="contact-profile-text font-weight-500 initial_capital">
                        {contact?.contactName}
                      </Text>
                    )}
                </Col>
              </Row>

              <Row>
                <Col span={1}>
                  <PhoneOutlined className="table-mail-icon" rotate={90} />
                </Col>
                <Col span={21} className="padding-left-1">
                  <Text className="contact-profile-text">{contact?.countryCode ? `${contact.countryCode}  ` : ''}{contact?.phone}</Text>
                </Col>
              </Row>

              {contact?.email ? (
                <Row>
                  <Col span={1}>
                    <a href={`mailto:${contact?.email}`}>
                      <MailOutlined className="table-mail-icon" />
                    </a>
                  </Col>
                  <Col span={23} className="padding-left-1">
                    {contact?.email!.length > 30 ? (
                      <Tooltip title={contact?.email} placement="bottom">
                        <Text className="contact-profile-email" ellipsis={true}>
                          {contact?.email?.toLocaleLowerCase()}
                        </Text>
                      </Tooltip>
                    ) : (
                      <Text className="contact-profile-text">
                        {contact?.email?.toLocaleLowerCase()}
                      </Text>
                    )}
                  </Col>
                </Row>
              ) : null}

              <Row className="full-width">
                <Col span={24}>
                  <Row>
                    <Col span={1}>
                      <EnvironmentOutlined className="table-mail-icon" />
                    </Col>
                    <Col span={23} className="padding-left-1">
                      <Text className="contact-profile-text initial_capital">
                        <Row>
                          {contact?.address?.postalCode && (
                            <span>
                              <span>{contact?.address?.postalCode}</span>
                              <span className="margin-right-2-half-px">,</span>
                            </span>
                          )}

                          {contact?.address?.street && (
                            <span>
                              <span>{contact?.address?.street}</span>
                              <span className="margin-right-2-half-px">,</span>
                            </span>
                          )}

                          {contact?.address?.city && (
                            <span>
                              <span>{contact?.address?.city}</span>
                              <span className="margin-right-2-half-px">,</span>
                            </span>
                          )}

                          {contact?.address?.state && (
                            <span>
                              <span>{contact?.address?.state}</span>
                              <span className="margin-right-2-half-px">,</span>
                            </span>
                          )}
                          <span>{contact?.address?.country}</span>
                        </Row>
                      </Text>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          ))
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={TEXT.NO_EMERGENCY_CONTACTS_AVAILABLE}
          />
        )}

        <EditEmergencyContactInfoDrawer
          showDrawer={showDrawer}
          setShowDrawer={setShowDrawer}
        />
      </div>
    </>
  );
};
export { EmergencyContactDetailsPanel };
