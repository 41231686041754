import { FilterFilled, FilterOutlined } from '@ant-design/icons';
import { TEXT } from '@moxie/constants';
import { Button, Empty, Pagination, Row, Table } from 'antd';
import React from 'react';
import { ApplicationReportColumns } from './application-table-columns';
import { IApplicationReportCrm } from '@model/crm/application-report.model';
import { PaginationMeta } from '@model/api-response.model';

interface IApplicationReportTable {
  openDrawer: () => void;
  tableData: IApplicationReportCrm[];
  handleExport: () => Promise<void>;
  isLoading: boolean;
  page: number;
  hasMorePage: boolean;
  filter: Record<string, unknown>;
  meta?: PaginationMeta;
  handlePageChange: (page: number) => void;
}

const ApplicationReportTable: React.FC<IApplicationReportTable> = ({
  openDrawer,
  tableData,
  handleExport,
  isLoading,
  handlePageChange,
  page,
  filter,
  meta,
  hasMorePage,
}) => {
  const pageSize = 10;

  const filterData = Object.entries(filter);

  return (
    <div className="bg-white padding-top-2">
      <Row justify="space-between" className="margin-right-1 padding-bottom-2">
        <Button className="margin-left-1" onClick={openDrawer}>
          {filterData.length === 0 ? (
            <FilterOutlined />
          ) : (
            <FilterFilled className="table-column-search-btn" />
          )}
          {TEXT.APPLY_FILTERS}
        </Button>
        <Button type="primary" onClick={() => handleExport()}>
          {TEXT.EXPORT}
        </Button>
      </Row>
      <Table
        className="padding-1 overflow-auto"
        pagination={false}
        loading={isLoading}
        scroll={{ y: '59vh' }}
        dataSource={tableData}
        locale={{
          emptyText: (
            <Empty
              className="application-report-table"
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No Data"
            />
          ),
        }}
        columns={ApplicationReportColumns()}
        rowKey="id"
      />
      <Row justify="end">
        <Pagination
          className="margin-top-2 margin-bottom-2 padding-right-2"
          showSizeChanger={false}
          pageSizeOptions={['10', '25', '50', '100']}
          onChange={handlePageChange}
          hideOnSinglePage={hasMorePage}
          total={meta?.totalItems}
          showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
          pageSize={pageSize}
          current={page}
        />
      </Row>
    </div>
  );
};
export default ApplicationReportTable;
