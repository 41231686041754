import { useQuery } from '@tanstack/react-query';
import { errorNotificationHandler } from '@moxie/shared';
import { getDealDetails } from '@crm/libs/services.api/lib/deals.api';
import { useDispatch } from 'react-redux';
import { dealActions } from '@crm/core';

export const useFetchDealDetails = (dealId: string) => {
  const dispatch = useDispatch();

  const { data, isLoading } = useQuery({
    queryKey: ['deal-details', dealId],
    queryFn: async () => getDealDetails(dealId),
    enabled: true,
    keepPreviousData: true,
    onError: () => errorNotificationHandler('Error fetching deal details.'),
    onSuccess: ({ data }) => {
      dispatch(dealActions.getDealDetailsSuccess(data));
    },
  });

  const detail = data?.data;

  return { detail, isLoading };
};
