import { SLUG_ALL_CLIENTS, SLUG_MY_CLIENTS, TEXT } from '@moxie/constants';
import { PageHeaderTitle } from '@shared-components/elements';
import { IContactCrm } from '@shared-components/models';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useAssignedBranch, useAssigneeChanged } from '@crm/libs/hooks/contacts';
import { PageHeaderMenu } from '../../shared/page-header-menu';
import { LeadAddEdit } from '../contact/components/contact-add-edit';
import { ClientTableList } from './client-table-list';
import { useAppSelector } from '@crm/core';
import ForbiddenAccess from '../forbidden-access/forbidden-access';
import { BranchPermission } from '@model/permission.model';

const useMenus = (branchPermission: BranchPermission | null) => {
  const menus = [
    {
      name: 'All Clients',
      id: SLUG_ALL_CLIENTS,
      url: `clients/${SLUG_ALL_CLIENTS}`,
    },
    {
      name: 'My Clients',
      id: SLUG_MY_CLIENTS,
      url: `clients/${SLUG_MY_CLIENTS}`,
      canAccess: !branchPermission?.payload.canAccessAllBranches,
    },
  ]

  return menus.filter(menu => !menu.canAccess)
}
const ClientComponent: React.FC = () => {
  const { name } = useParams<{ name: string }>();
  const [branch, setBranch] = useState<string>();
  const [disabled, setDisabled] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [initialData, setInitialData] = useState<IContactCrm>();

  const { refresh, triggerRefresh } = useAssigneeChanged();
  const currentUser = useAppSelector(store => store.auth.user);
  const branchPermission = useAppSelector(store => store.auth.authUserPermission);

  const onClose = () => {
    setIsDrawerOpen(false);
    setDisabled(true);
    setIsSubmitted(false);
  };

  const { branches } = useAssignedBranch();

  const location = useLocation()
  const menus = useMenus(branchPermission)
  useEffect(() => {
    if (branches.length > 0 && branches.find(b => b.id === currentUser?.branchId)) {
      const params = new URLSearchParams(location.search.replace('?', ''));
      const preSelectedBranch = params.get('branch');
      if (preSelectedBranch) {
        setBranch(preSelectedBranch);
      } else {
        setBranch(currentUser?.branchId)
      }
    }
  }, [branches, currentUser])

  if (!branchPermission?.payload.permissions.contact?.length) {
    return <ForbiddenAccess />
  }
  return (
    <div className="full-height">
      <PageHeaderTitle title={TEXT.CLIENTS} />
      <PageHeaderMenu menu={menus} defaultActiveKey={name} />
      <LeadAddEdit
        isOpen={isDrawerOpen}
        onClose={onClose}
        initialData={initialData}
        triggerRefresh={triggerRefresh}
        setDisabled={setDisabled}
        disabled={disabled}
        submitted={isSubmitted}
        setSubmitted={setIsSubmitted}
        isEdit={isEditMode}
      />

      <ClientTableList
        branch={branch}
        setBranch={setBranch}
        refresh={refresh}
        triggerRefresh={triggerRefresh}
        setInitialData={setInitialData}
        setIsDrawerOpen={setIsDrawerOpen}
        setIsEditMode={setIsEditMode}
      />
    </div>
  );
};

export { ClientComponent };
