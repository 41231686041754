import { Application } from "@model/application";
import Card from "antd/es/card";
import { Col, Row } from "antd/es/grid";
import Tag from "antd/es/tag";
import Typography from "antd/es/typography";
import { BankOutlined, EnvironmentOutlined, CalendarOutlined } from '@ant-design/icons';
import { UserAvatarGroup } from 'apps/crm/src/shared/user-avatar-group';
import { TEXT } from "@moxie/constants";
import Progress from "antd/es/progress";

const { Text, Title, Paragraph } = Typography;

interface IProps {
  data: Application;
}

export const ApplicationCard = ({ data }: IProps) => {
  return (
    <Card
      key={data?.id}
      className="crm-application-card margin-bottom-1"
    >
      <Row align="middle" className="application-list-card-head">
        <Col>
          <Title
            level={4}
            className="initial_capital ellipsis-text"
            ellipsis={{ tooltip: true }}
          >
            {data?.product?.name}
          </Title>
        </Col>
        <Col offset={1}>
          <Tag>{data?.appIdentifier}</Tag>
        </Col>
      </Row>
      <div className="application-list-card-body">
        <div className="left-section">
          <Row align="top">
            <Col>
              <div className="bank_icon_container">
                <BankOutlined className="crm_application_bank_icon" />
              </div>
            </Col>
            <Col className="margin-left-1">
              <Text
                className="font-bold font-size-1-rem initial_capital institution-ellipsis"
                ellipsis
              >
                {data?.institution?.name ?? 'N/A'}
              </Text>

              <div className="display-flex gap-8px">
                <div className="display-flex align-items-center gap-4px">
                  <EnvironmentOutlined className="crm_app_card_inst_info_color" />
                  <Text
                    ellipsis={data?.institutionBranch?.name?.length > 100}
                    className="crm_app_card_inst_info_text"
                    style={{ maxWidth: "300px" }}
                  >
                    {data?.institutionBranch?.name}
                  </Text>
                </div>

                {data.intakeMonth && (
                  <div className="display-flex align-items-center gap-4px">
                    <CalendarOutlined className="crm_app_card_inst_info_color" />
                    <Text
                      ellipsis={true}
                      className="crm_app_card_inst_info_text"
                    >
                      {data?.intakeMonth + ' ' + data?.intakeYear}
                    </Text>
                  </div>
                )}

              </div>
            </Col>
          </Row>
          <Row className="margin-top-2 gap80">
            <Col
              span={4}
              className="crm_app_branch_info_container"
            >
              <Text className="crm_app_prod_info_text">
                Workflow
              </Text>
              <Typography.Paragraph className="crm_app_prod_info_text crm_app_strong" style={{ width: "155px" }} ellipsis={{ tooltip: true }}>
                {data?.workflow?.workflowType?.name}
              </Typography.Paragraph>
            </Col>

            {data?.processingBranch ? (
              <Col
                span={4}
                className="crm_app_branch_info_container"
              >
                <Text className="crm_app_prod_info_text">
                  {TEXT.PROCESSING_BRANCH}
                </Text>
                <Paragraph className="crm_app_prod_info_text w-100 crm_app_strong">
                  {data?.processingBranch?.name}
                </Paragraph>
              </Col>
            ) : null}

            {data?.assignees ? (
              <Col
                span={4}
                className="crm_app_branch_info_container"
              >
                <Text className="crm_app_prod_info_text">
                  {TEXT.ASSIGNEES}
                </Text>
                <div className="profile__user">
                  <UserAvatarGroup
                    userList={data?.assignees ?? []}
                  />
                </div>
              </Col>
            ) : null}

          </Row>
        </div>

        <StatusSection application={data} />

      </div>
    </Card>
  )
}

const StatusSection = ({ application }: { application: Application }) => {
  const applicationStages = application?.workflow?.workflowApplicationStages;
  const step =
    (applicationStages?.find((a) => a.stage === application.activeStage.stage)?.level as number) +
    1;
  const steps = (applicationStages?.length as number) + 1;

  return (
    <div className="status-section">
      <div className="status-section-container">
        {application.status === 'In Progress' ? (
          <Progress
            type="circle"
            width={50}
            percent={(step / steps) * 100}
            format={() => <span style={{ color: 'blue' }}>{step + '/' + steps}</span>}
            strokeColor="blue"
          />
        ) : (
          <Progress
            type="circle"
            width={50}
            percent={application.status === 'Completed' ? 100 : (step / steps) * 100}
            status={application.status === 'Discontinued' ? 'exception' : undefined}
          />
        )}
        <p className={`status-section-text-${application.status.split(' ').join('-').toLowerCase()}`}>
          {application.status}
        </p>
      </div>
      <div className="margin-top-1rem crm_app_branch_info_container">
        <Text className="crm_app_prod_info_text">Current Stage</Text>
        <Paragraph className="crm_app_prod_info_text crm_app_strong">
          {application.status === 'Completed' ? 'Completed' : application.activeStage.stage}
        </Paragraph>
      </div>
    </div>
  );
};
