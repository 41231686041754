import React from 'react';
import { contactActions, useAppSelector } from '@crm/core';
import { CONFIRM_MESSAGE, DELETE_BTN, EDIT_BTN, TEXT } from '@moxie/constants';
import { capitalizeFirstLetter, popConfirm } from '@moxie/shared';
import {
  Button,
  Card,
  Col,
  Dropdown,
  Empty,
  Menu,
  Row,
  Space,
  Spin,
  Tooltip,
  Typography
} from 'antd';
import { RootState } from 'apps/crm/src/core/store';
import moment from 'moment';
import {
  MoreOutlined,
  EditOutlined,
  DeleteOutlined,
  UpOutlined,
  DownOutlined,
  MailOutlined,
  PhoneOutlined
} from '@ant-design/icons';
import { ProfessionalExperienceDrawer } from './drawer/professional-experience-drawer';
import { useDispatch } from 'react-redux';
import { ICrmProfessionalExperience } from '@model/crm/professional-experience.model';

const { Text } = Typography;

const ProfessionalExperienceContent: React.FC = () => {
  const profExpData = useAppSelector(
    (state: RootState) => state?.contact?.profExpData
  );
  const dataLoading = useAppSelector(
    (state: RootState) => state?.contact?.profExpLoading
  );

  return (
    <>
      <Spin spinning={dataLoading}>
        {profExpData?.length > 0 ? (
          profExpData.map((item: any, index: any) => {
            return (
              <Row key={item?.id} className="full-width">
                <ProfessionalExperienceCard key={index} item={item} />
              </Row>
            );
          })
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={TEXT.NOT_AVAILABLE}
          />
        )}
      </Spin>
    </>
  );
};

const cardMenu = ({
  handleEdit,
  handleDelete,
}: {
  handleEdit: any;
  handleDelete: any;
}) => {
  return (
    <Menu>
      <Menu.Item
        key={1}
        icon={<EditOutlined />}
        onClick={handleEdit}
        data-testid="professionalexperience-editicon"
      >
        {EDIT_BTN}
      </Menu.Item>
      <Menu.Item
        key={2}
        icon={<DeleteOutlined />}
        danger
        onClick={handleDelete}
        data-testid="professionalexperience-deleteicon"
      >
        {DELETE_BTN}
      </Menu.Item>
    </Menu>
  );
};

const ProfessionalExperienceCard = ({
  item,
}: {
  item: ICrmProfessionalExperience;
}) => {
  const [expand, setExpand] = React.useState<boolean>(false);
  const [editMode, setEditMode] = React.useState<boolean>(false);
  const [showDrawer, setShowDrawer] = React.useState<boolean>(false);

  const socket = useAppSelector((state) => state.socket.wss);
  const user = useAppSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  const screenWidth = window.innerWidth;

  const handleExpand = (id: string, status: boolean) => {
    if (id) {
      setExpand(!status);
    }
  };

  const handleEdit = () => {
    setEditMode(true);
    setShowDrawer(true);
  };
  const handleDelete = () => {
    const dataToBeDeleted = {
      contactId: item?.contactId,
      professionalExperienceId: item?.id,
    };
    popConfirm(
      DELETE_BTN,
      `${CONFIRM_MESSAGE.DELETE_PROFESSIONAL_EXPERIENCE}`,
      () => {
        dispatch(
          contactActions.deleteContactProfessionalExperienceRequest(
            dataToBeDeleted,
            (response) => {
              socket?.emit('server::profile-updated', {
                activitiesTypeId: item.contactId,
                activitiesType: 'professional-experience',
                activitiesAction: 'deleted',
                userId: user?.id,
                contactId: item.contactId,
                companyId: user?.companyId as string,
                data: response,
              });
            }
          )
        );
      },
      true
    );
  };

  const menuActions = {
    handleEdit,
    handleDelete,
  };

  return (
    <>
      {expand ? (
        <Card className="full-width margin-bottom-2">
          <Card.Grid
            hoverable={false}
            className="full-width prof-exp-card-container"
          >
            <Row>
              <Col span={24}>
                <Row align="middle" className="prof-exp-line-height">
                  <Col span={23}>
                    <Text
                      className="initial_capital text-bold ellipsis-text"
                      ellipsis={
                        String(item?.jobTitle).length >= 30
                          ? { tooltip: capitalizeFirstLetter(item?.jobTitle) }
                          : false
                      }
                    >
                      {item?.jobTitle}
                    </Text>
                  </Col>
                  <Col span={1}>
                    <Dropdown overlay={cardMenu(menuActions)}>
                      <Button
                        type="text"
                        className="table-cell-action-button edu-bg-icon"
                        icon={<MoreOutlined />}
                      />
                    </Dropdown>
                  </Col>
                </Row>
                <Row align="middle" className="prof-exp-line-height">
                  <Space direction="horizontal" size={3}>
                    <Text className="prof-exp-sec-title initial_capital">
                      {item?.jobType === 'full_time'
                        ? 'Full Time'
                        : item?.jobType === 'part_time'
                          ? 'Part Time'
                          : item?.jobType === 'self_employed'
                            ? 'Self Employed'
                            : item?.jobType}
                    </Text>
                    <Text className="prof-exp-sec-title initial_capital">
                      |
                    </Text>
                    <Text className="prof-exp-sec-title initial_capital">
                      {moment(item.startDate).format('MMM YYYY').toString()}
                    </Text>
                    <Text className="prof-exp-sec-title initial_capital">
                      -
                    </Text>
                    {item?.isStillWorking ? (
                      <Text className="prof-exp-sec-title initial_capital">
                        Present
                      </Text>
                    ) : (
                      <Text className="prof-exp-sec-title initial_capital">
                        {moment(item?.endDate).format('MMM YYYY').toString()}
                      </Text>
                    )}
                  </Space>
                </Row>

                <Row align="middle" className="prof-exp-line-height">
                  <Col span={6} xl={8} xxl={6}>
                    <Text className="prof-exp-label-title">
                      {TEXT.ORGANIZATION}
                    </Text>
                  </Col>
                  <Col span={17} xl={15} xxl={17}>
                    {screenWidth >= 1200 && screenWidth < 1600 ? (
                      String(item?.organizationName).length >= 16 ? (
                        <Tooltip
                          title={capitalizeFirstLetter(item?.organizationName)}
                        >
                          <Text
                            ellipsis
                            className="prof-exp-value-ellipsis-text initial_capital"
                          >
                            {item?.organizationName}
                          </Text>
                        </Tooltip>
                      ) : (
                        <Text className="initial_capital">
                          {item?.organizationName}
                        </Text>
                      )
                    ) : screenWidth >= 1600 &&
                      String(item?.organizationName).length >= 24 ? (
                        <Tooltip
                          title={capitalizeFirstLetter(item?.organizationName)}
                        >
                          <Text
                            ellipsis
                            className="prof-exp-value-ellipsis-text initial_capital"
                          >
                            {item?.organizationName}
                          </Text>
                        </Tooltip>
                      ) : (
                        <Text className="initial_capital">
                          {item?.organizationName}
                        </Text>
                      )}
                  </Col>
                </Row>

                {item.city || item.country ? (
                  <Row align="middle" className="prof-exp-line-height">
                    <Col span={6} xl={8} xxl={6}>
                      <Text className="prof-exp-label-title">
                        {TEXT.LOCATION}
                      </Text>
                    </Col>
                    <Col span={17} xl={15} xxl={17}>
                      {screenWidth >= 1200 && screenWidth < 1600 ? (
                        String(item?.city).length >= 9 ? (
                          <>
                            <Tooltip
                              title={capitalizeFirstLetter(
                                item?.city as string
                              )}
                            >
                              <Text
                                ellipsis
                                className="prof-exp-city-ellipsis initial_capital"
                              >
                                {item?.city}
                              </Text>
                            </Tooltip>
                            {item?.country ? (
                              <>
                                {item?.city ? <Text>,</Text> : null}

                                <Text className="initial_capital">
                                  {item?.country}
                                </Text>
                              </>
                            ) : null}
                          </>
                        ) : (
                          <>
                            <Text className="initial_capital">
                              {item?.city}
                            </Text>
                            {item?.country ? (
                              <Space direction="horizontal" size={1}>
                                {item?.city ? <Text>,</Text> : null}
                                <Text className="initial_capital">
                                  {item?.country}
                                </Text>
                              </Space>
                            ) : null}
                          </>
                        )
                      ) : screenWidth >= 1600 &&
                        String(item?.city).length >= 24 ? (
                          <>
                            <Tooltip
                              title={capitalizeFirstLetter(item?.city as string)}
                            >
                              <Text
                                ellipsis
                                className="prof-exp-city-ellipsis initial_capital"
                              >
                                {item?.city}
                              </Text>
                            </Tooltip>
                            {item?.country ? (
                              <>
                                {item?.city ? <Text>,</Text> : null}
                                <Text className="initial_capital">
                                  {item?.country}
                                </Text>
                              </>
                            ) : null}
                          </>
                        ) : (
                          <>
                            <Text className="initial_capital">{item?.city}</Text>
                            {item?.country ? (
                              <Space direction="horizontal" size={1}>
                                {item?.city ? <Text>,</Text> : null}
                                <Text className="initial_capital">
                                  {item?.country}
                                </Text>
                              </Space>
                            ) : null}
                          </>
                        )}
                    </Col>
                  </Row>
                ) : null}
              </Col>
            </Row>
            {item?.referenceName && item?.referencePosition ? (
              <div className="margin-top-1">
                <Row align="middle" className="prof-exp-line-height">
                  <Text className="prof-exp-label-title">Reference</Text>
                </Row>

                <Row align="middle" className="prof-exp-line-height">
                  <Col span={23}>
                    <Space direction="horizontal" size={4}>
                      <Col flex="none">
                        {screenWidth >= 1200 && screenWidth < 1600 ? (
                          String(item?.referenceName).length >= 22 ? (
                            <Tooltip title={item?.referenceName}>
                              <Text
                                ellipsis
                                className="prof-exp-reference-ellipsis initial_capital"
                              >
                                {item?.referenceName}
                              </Text>
                            </Tooltip>
                          ) : (
                            <Text className="font-weight-500 initial_capital">
                              {item?.referenceName}
                            </Text>
                          )
                        ) : (
                          screenWidth >= 1600 &&
                          (String(item?.referenceName).length >= 26 ? (
                            <Tooltip title={item?.referenceName}>
                              <Text
                                ellipsis
                                className="prof-exp-reference-ellipsis initial_capital"
                              >
                                {item?.referenceName}
                              </Text>
                            </Tooltip>
                          ) : (
                            <Text className="font-weight-500 initial_capital">
                              {item?.referenceName}
                            </Text>
                          ))
                        )}
                      </Col>
                      <Col flex="none">
                        <Text className="prof-exp-reference-sec-title initial_capital">
                          |
                        </Text>
                      </Col>
                      <Col flex="none" className="reference-position">
                        {screenWidth >= 1200 && screenWidth < 1600 ? (
                          String(item?.referencePosition).length >= 22 ? (
                            <Tooltip title={item?.referencePosition}>
                              <Text
                                ellipsis
                                className="prof-exp-reference-ellipsis initial_capital"
                              >
                                {item?.referencePosition}
                              </Text>
                            </Tooltip>
                          ) : (
                            <Text className="prof-exp-reference-sec-title initial_capital">
                              {item?.referencePosition}
                            </Text>
                          )
                        ) : (
                          screenWidth >= 1600 &&
                          (String(item?.referencePosition).length >= 23 ? (
                            <Tooltip title={item?.referencePosition}>
                              <Text
                                ellipsis
                                className="prof-exp-reference-ellipsis initial_capital"
                              >
                                {item?.referencePosition}
                              </Text>
                            </Tooltip>
                          ) : (
                            <Text className="prof-exp-reference-sec-title initial_capital">
                              {item?.referencePosition}
                            </Text>
                          ))
                        )}
                      </Col>
                    </Space>
                  </Col>
                  {!item?.referenceEmail && !item?.referencePhone ? (
                    <Col span={1}>
                      <UpOutlined
                        onClick={() => handleExpand(item?.id, expand)}
                      />
                    </Col>
                  ) : null}
                </Row>

                {item?.referenceEmail ? (
                  <Row align="middle" className="prof-exp-line-height">
                    <Col span={1} className="prof-exp-mail-icon-position">
                      <a href={`mailto:${item?.referenceEmail}`}>
                        <MailOutlined className="table-mail-icon" />
                      </a>
                    </Col>
                    <Col span={22} className="padding-left-1">
                      <Text
                        className="contact-professional-email"
                        ellipsis={
                          String(item?.referenceEmail).length >= 17
                            ? {
                              tooltip: capitalizeFirstLetter(
                                item?.referenceEmail
                              ),
                            }
                            : false
                        }
                      >
                        {item?.referenceEmail?.toLocaleLowerCase()}
                      </Text>
                    </Col>
                    {!item?.referencePhone ? (
                      <Col span={1}>
                        <UpOutlined
                          onClick={() => handleExpand(item?.id, expand)}
                        />
                      </Col>
                    ) : null}
                  </Row>
                ) : null}
                {item?.referencePhone ? (
                  <Row align="middle" className="prof-exp-line-height">
                    <Col span={1}>
                      <PhoneOutlined className="table-mail-icon" rotate={90} />
                    </Col>
                    <Col span={22} className="padding-left-1">
                      <Text className="contact-profile-text">
                        {item?.referencePhone}
                      </Text>
                    </Col>
                    <Col span={1}>
                      <UpOutlined
                        onClick={() => handleExpand(item?.id, expand)}
                      />
                    </Col>
                  </Row>
                ) : null}
              </div>
            ) : null}
          </Card.Grid>
        </Card>
      ) : (
        <Card className="margin-bottom-1 full-width">
          <Card.Grid
            hoverable={false}
            className="full-width prof-exp-card-container"
          >
            <Row>
              <Col span={24}>
                <Row align="middle" className="prof-exp-line-height">
                  <Col span={23}>
                    <Text
                      className="initial_capital text-bold ellipsis-text"
                      ellipsis={
                        String(item?.jobTitle).length >= 30
                          ? { tooltip: capitalizeFirstLetter(item?.jobTitle) }
                          : false
                      }
                    >
                      {item?.jobTitle}
                    </Text>
                  </Col>
                  <Col span={1}>
                    <Dropdown overlay={cardMenu(menuActions)}>
                      <Button
                        type="text"
                        className="table-cell-action-button edu-bg-icon"
                        icon={<MoreOutlined />}
                      />
                    </Dropdown>
                  </Col>
                </Row>
                <Row align="middle" className="prof-exp-line-height">
                  <Space direction="horizontal" size={3}>
                    <Text className="prof-exp-sec-title initial_capital">
                      {item?.jobType === 'full_time'
                        ? 'Full Time'
                        : item?.jobType === 'part_time'
                          ? 'Part Time'
                          : item?.jobType === 'self_employed'
                            ? 'Self Employed'
                            : item?.jobType}
                    </Text>
                    <Text className="prof-exp-sec-title initial_capital">
                      |
                    </Text>
                    <Text className="prof-exp-sec-title initial_capital">
                      {moment(item.startDate).format('MMM YYYY').toString()}
                    </Text>
                    <Text className="prof-exp-sec-title initial_capital">
                      -
                    </Text>
                    {item?.isStillWorking ? (
                      <Text className="prof-exp-sec-title initial_capital">
                        Present
                      </Text>
                    ) : (
                      <Text className="prof-exp-sec-title initial_capital">
                        {moment(item?.endDate).format('MMM YYYY').toString()}
                      </Text>
                    )}
                  </Space>
                </Row>

                <Row align="middle" className="prof-exp-line-height">
                  <Col span={6} xl={8} xxl={6}>
                    <Text className="prof-exp-label-title">
                      {TEXT.ORGANIZATION}
                    </Text>
                  </Col>
                  <Col span={18} xl={16} xxl={18}>
                    <Row>
                      <Col span={23}>
                        {screenWidth >= 1200 && screenWidth < 1600 ? (
                          String(item?.organizationName).length >= 16 ? (
                            <Tooltip
                              title={capitalizeFirstLetter(
                                item?.organizationName
                              )}
                            >
                              <Text
                                ellipsis
                                className="prof-exp-value-ellipsis-text initial_capital"
                              >
                                {item?.organizationName}
                              </Text>
                            </Tooltip>
                          ) : (
                            <Text className="initial_capital">
                              {item?.organizationName}
                            </Text>
                          )
                        ) : screenWidth >= 1600 &&
                          String(item?.organizationName).length >= 24 ? (
                            <Tooltip
                              title={capitalizeFirstLetter(
                                item?.organizationName
                              )}
                            >
                              <Text
                                ellipsis
                                className="prof-exp-value-ellipsis-text initial_capital"
                              >
                                {item?.organizationName}
                              </Text>
                            </Tooltip>
                          ) : (
                            <Text className="initial_capital">
                              {item?.organizationName}
                            </Text>
                          )}
                      </Col>
                      {!item?.city && !item?.country ? (
                        <Col span={1}>
                          <DownOutlined
                            onClick={() => handleExpand(item?.id, expand)}
                          />
                        </Col>
                      ) : null}
                    </Row>
                  </Col>
                </Row>

                {item.city || item.country ? (
                  <Row align="middle" className="prof-exp-line-height">
                    <Col span={6} xl={8} xxl={6}>
                      <Text className="prof-exp-label-title">
                        {TEXT.LOCATION}
                      </Text>
                    </Col>
                    <Col span={18} xl={16} xxl={18}>
                      <Row>
                        <Col span={23}>
                          {screenWidth >= 1200 && screenWidth < 1600 ? (
                            String(item?.city).length >= 9 ? (
                              <Space direction="horizontal" size={1}>
                                <Tooltip
                                  title={capitalizeFirstLetter(
                                    item?.city as string
                                  )}
                                >
                                  <Text
                                    ellipsis
                                    className="prof-exp-city-ellipsis initial_capital"
                                  >
                                    {item?.city}
                                  </Text>
                                </Tooltip>
                                {item?.country ? (
                                  <>
                                    {item?.city ? <Text>,</Text> : null}

                                    <Text className="initial_capital">
                                      {item?.country}
                                    </Text>
                                  </>
                                ) : null}
                              </Space>
                            ) : (
                              <>
                                <Text className="initial_capital">
                                  {item?.city}
                                </Text>
                                {item?.country ? (
                                  <Space direction="horizontal" size={1}>
                                    {item?.city ? <Text>,</Text> : null}
                                    <Text className="initial_capital">
                                      {item?.country}
                                    </Text>
                                  </Space>
                                ) : null}
                              </>
                            )
                          ) : screenWidth >= 1600 &&
                            String(item?.city).length >= 24 ? (
                              <Space direction="horizontal" size={1}>
                                <Tooltip
                                  title={capitalizeFirstLetter(
                                  item?.city as string
                                  )}
                                >
                                  <Text
                                    ellipsis
                                    className="prof-exp-city-ellipsis initial_capital"
                                  >
                                    {item?.city}
                                  </Text>
                                </Tooltip>
                                {item?.country ? (
                                  <>
                                    {item?.city ? <Text>,</Text> : null}

                                    <Text className="initial_capital">
                                      {item?.country}
                                    </Text>
                                  </>
                                ) : null}
                              </Space>
                            ) : (
                              <>
                                <Text className="initial_capital">
                                  {item?.city}
                                </Text>
                                {item?.country ? (
                                  <Space direction="horizontal" size={1}>
                                    {item?.city ? <Text>,</Text> : null}
                                    <Text className="initial_capital">
                                      {item?.country}
                                    </Text>
                                  </Space>
                                ) : null}
                              </>
                            )}
                        </Col>
                        <Col span={1}>
                          <DownOutlined
                            onClick={() => handleExpand(item?.id, expand)}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                ) : null}
              </Col>
            </Row>
          </Card.Grid>
        </Card>
      )}
      <ProfessionalExperienceDrawer
        showDrawer={showDrawer}
        setShowDrawer={setShowDrawer}
        initialData={item}
        editMode={editMode}
        setEditMode={setEditMode}
      />
    </>
  );
};

export { ProfessionalExperienceContent };
