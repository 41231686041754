import React, { useState } from 'react';
import { Collapse, Tooltip } from 'antd';
import { CATEGORY_ITEMS, EDIT } from '@moxie/constants';
import { EditOutlined } from '@ant-design/icons';
import {
  AddressDetailsPanel,
  EmergencyContactDetailsPanel,
  PersonalDetailsPanel,
  VisaDetailsPanel
} from './profle-content-components';
import { useAppSelector } from '@crm/core';
import { RootState } from 'apps/crm/src/core/store';
// import Can, { ContactsEntity, PermissionAction } from '@crm/privilege';

const { Panel } = Collapse;

const ProfileContent: React.FC = () => {
  const [panelKey, setPanelKey] = useState(CATEGORY_ITEMS[0].key);
  const [showExtraIcon, setShowExtraIcon] = useState<boolean>(true);

  const [showPersonalInfoDrawer, setShowPersonalInfoDrawer] = useState<boolean>(
    false
  );
  const [showAddressInfoDrawer, setShowAddressInfoDrawer] = useState<boolean>(
    false
  );

  const [
    showPassportVisaInfoDrawer,
    setShowPassportVisaInfoDrawer,
  ] = useState<boolean>(false);

  const [
    showEditEmergencyContactDrawer,
    setShowEditEmergencyContactDrawer,
  ] = useState<boolean>(false);

  // const loggedUser = useAppSelector((state: RootState) => state?.auth?.user);
  const data = useAppSelector((state: RootState) => state?.contact?.singleData);

  const handlePanelChange = (panelSelected: any) => {
    if (panelSelected !== undefined) {
      setPanelKey(() => {
        setShowExtraIcon(true);
        return panelSelected;
      });
    } else {
      setShowExtraIcon(false);
    }
  };

  const genExtra = (panelKey: string) => {
    if (
      // Can(
      //   loggedUser,
      //   PermissionAction.Update,
      //   new ContactsEntity({
      //     assignee_id: data.assignee_id,
      //     followers: data.followers?.map((item: any) => item.id),
      //   })
      // ) &&
      data?.archived === false
    ) {
      return (
        <Tooltip title={EDIT}>
          <div
            className="extra-icon-container"
            onClick={(event: any) => {
              event.stopPropagation();
              switch (panelKey) {
                case CATEGORY_ITEMS[0].key:
                  setShowPersonalInfoDrawer(true);
                  break;

                case CATEGORY_ITEMS[1].key:
                  setShowAddressInfoDrawer(true);
                  break;

                case CATEGORY_ITEMS[2].key:
                  setShowPassportVisaInfoDrawer(true);
                  break;

                case CATEGORY_ITEMS[3].key:
                  setShowEditEmergencyContactDrawer(true);
                  break;

                default:
                  setShowPersonalInfoDrawer(false);
                  setShowAddressInfoDrawer(false);
                  setShowPassportVisaInfoDrawer(false);
                  setShowEditEmergencyContactDrawer(false);
              }
            }}
          >
            <EditOutlined
              className="text-primary"
              data-testid="crm-contactprofile-editicon"
            />
          </div>
        </Tooltip>
      );
    }
    return null;
  };

  return (
    <div className="profile-content-margin">
      <Collapse
        defaultActiveKey={panelKey}
        accordion={true}
        onChange={handlePanelChange}
      >
        <Panel
          key={CATEGORY_ITEMS[0].key}
          header={
            <span className="text-primary profile-panel-header">
              {CATEGORY_ITEMS[0].name}
            </span>
          }
          extra={
            showExtraIcon && CATEGORY_ITEMS[0].key === panelKey
              ? genExtra(CATEGORY_ITEMS[0].key)
              : false
          }
        >
          <PersonalDetailsPanel
            showDrawer={showPersonalInfoDrawer}
            setShowDrawer={setShowPersonalInfoDrawer}
          />
        </Panel>
        <Panel
          key={CATEGORY_ITEMS[1].key}
          header={
            <span className="text-primary profile-panel-header">
              {CATEGORY_ITEMS[1].name}
            </span>
          }
          extra={
            showExtraIcon && CATEGORY_ITEMS[1].key === panelKey
              ? genExtra(CATEGORY_ITEMS[1].key)
              : false
          }
        >
          <AddressDetailsPanel
            showDrawer={showAddressInfoDrawer}
            setShowDrawer={setShowAddressInfoDrawer}
          />
        </Panel>
        <Panel
          key={CATEGORY_ITEMS[2].key}
          header={
            <span className="text-primary profile-panel-header">
              {CATEGORY_ITEMS[2].name}
            </span>
          }
          extra={
            showExtraIcon && CATEGORY_ITEMS[2].key === panelKey
              ? genExtra(CATEGORY_ITEMS[2].key)
              : false
          }
        >
          <VisaDetailsPanel
            showDrawer={showPassportVisaInfoDrawer}
            setShowDrawer={setShowPassportVisaInfoDrawer}
          />
        </Panel>
        <Panel
          key={CATEGORY_ITEMS[3].key}
          header={
            <span className="text-primary profile-panel-header">
              {CATEGORY_ITEMS[3].name}
            </span>
          }
          extra={
            showExtraIcon && CATEGORY_ITEMS[3].key === panelKey
              ? genExtra(CATEGORY_ITEMS[3].key)
              : false
          }
        >
          <EmergencyContactDetailsPanel
            showDrawer={showEditEmergencyContactDrawer}
            setShowDrawer={setShowEditEmergencyContactDrawer}
          />
        </Panel>
      </Collapse>
    </div>
  );
};
export { ProfileContent };
