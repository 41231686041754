import { notification, Progress } from "antd";
import { TEXT } from "@moxie/constants";
import { errorNotificationHandler, successNotificationHandler } from "@moxie/shared";
import { DataType } from '../contact-bulk-document-upload';
import { IUser } from '@model/data.model';
import { IContactCrm } from '@model/index';
import { Socket } from 'socket.io-client';
import { Application } from '@model/application';

interface Props {
  files: DataType[];
  application: Application;
  addMedia: (media: any) => Promise<any>;
  requiredDocuments: { workflowApplicationStageId: string, name: string, stage: string, checkListId: number }[] | null;
  socket: Socket | null;
  user: IUser | null;
  contact: IContactCrm | null;
  queryClient: any;
  setClassifiedFileList: (fileList: any[]) => void;
  setFileList: (fileList: any[]) => void;
  setIsLoading: any
}

export const BulkApplicationDocumentUploader = async ({
  files,
  application,
  addMedia,
  requiredDocuments,
  socket,
  user,
  contact,
  queryClient,
  setClassifiedFileList,
  setFileList,
  setIsLoading,
}: Props) => {
  const key = 'uploadProgress';

  if (files.length > 20) {
    return errorNotificationHandler(TEXT.MAX_TASK_DOCUMENTS_COUNT);
  }

  setIsLoading(true);

  const selectedChecklists: { id: number | undefined; name: string | undefined }[] = [];

  let totalSize = 0;
  let uploadedSize = 0;

  files.forEach((file) => {
    totalSize += file.originFileObj.size;
  });

  notification.open({
    key,
    message: 'Uploading Documents.',
    description: (
      <div>
        <span>Application documents...</span>
        <Progress percent={0} size="small" />
      </div>
    ),
    duration: 0,
    placement: 'bottomRight',
  });

  const updateProgress = (increment: number) => {
    uploadedSize += increment;
    const percent = Math.round((uploadedSize / totalSize) * 100);

    notification.open({
      key,
      message: 'Uploading Documents.',
      description: (
        <div>
          <span>Application documents...</span>
          <Progress percent={percent} size="small" />
        </div>
      ),
      duration: 0,
      placement: 'bottomRight',
    });
  };

  for (const file of files) {
    const path = `contacts/contact__${contact?.internalId}/applications/application__${application.appIdentifier}/stage__application/${file.documentTypeLabel[0].toLowerCase().replace(' ', '-')}`;

    try {
      if (file.documentType === "combined") {
        for (const label of file.documentTypeLabel) {
          selectedChecklists.push({
            id: requiredDocuments?.find((doc: { name: any; }) => doc.name === label)?.checkListId,
            name: requiredDocuments?.find((doc: { name: any; }) => doc.name === label)?.name,
          });

          await addMedia({
            documentType: label,
            documentTypeCategory: file.documentTypeCategory,
            path,
            file: file.originFileObj,
            subjectId: application.id,
            subjectType: 'application',
            name: file?.name,
            stageId: requiredDocuments?.find((doc: { name: any; }) => doc.name === label)?.workflowApplicationStageId,
          });
          updateProgress(file.originFileObj.size / files.length);
        }
      } else {
        selectedChecklists.push({
          id: requiredDocuments?.find((doc: { name: any; }) => doc.name === file.documentTypeLabel[0])?.checkListId,
          name: requiredDocuments?.find((doc: { name: any; }) => doc.name === file.documentTypeLabel[0])?.name,
        });

        await addMedia({
          documentType: file.documentTypeLabel[0],
          documentTypeCategory: file.documentTypeCategory,
          path,
          file: file.originFileObj,
          subjectId: application.id,
          subjectType: 'application',
          name: file?.name,
          stageId: requiredDocuments?.find((doc: { name: any; }) => doc.name === file.documentTypeLabel[0])?.workflowApplicationStageId,
        });
        updateProgress(file.originFileObj.size / files.length);
      }
    } catch (error) {
      errorNotificationHandler('Failed to upload document(s).');
      return;
    }
  }

  try {
    socket?.emit('server::bulk-application-document-updated', {
      activitiesTypeId: application.id,
      activitiesType: "bulk-application-document",
      activitiesAction: 'updated',
      userId: user?.id,
      contactId: contact?.id,
      companyId: user?.company_id as string,
    });

    queryClient.invalidateQueries({ queryKey: ['docs-count', application.id] });
    queryClient.invalidateQueries({ queryKey: ['application-log', application.id] });

    selectedChecklists.forEach((checklist) => {
      queryClient.invalidateQueries({ queryKey: ['stage-documents', checklist.id] });
      queryClient.invalidateQueries({ queryKey: ['application-checklist-docs', application.id, checklist.name] });
    });

    successNotificationHandler('Bulk application document(s) uploaded successfully.');
  } catch (error) {
    errorNotificationHandler('Failed to emit socket or invalidate queries.');
  } finally {
    notification.close(key);
    setClassifiedFileList([]);
    setFileList([]);
    setIsLoading(false)
  }
};
