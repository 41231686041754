import { useMemo, useState } from 'react';
import { FormInstance } from 'antd';
import { useFetchSubAgents } from './useFetchPartnerAgents';
import { capitalizeFirstLetter } from '@moxie/shared';
import { AgentPartnerBranch } from '@model/agent-partner';

const getMainBranchId = (data: AgentPartnerBranch[]) => {
  const res = data?.find((d) => d.isMainBranch);
  return res?.id;
};
export const useFetchSubAgentBranches = (form: FormInstance<any>) => {
  const [selectedSubAgent, setSelectedSubAgent] = useState<string | any>();
  const [subAgentStatus, setSubAgentStatus] = useState(true);
  const { subAgents, isSubAgentsLoading } = useFetchSubAgents();

  const subAgentBranches = useMemo(() => {
    if (selectedSubAgent === 'false') {
      form.setFieldsValue({
        subAgentBranch: null,
      })
      return []
    }
    const subAgentData = subAgents.find((item) => item.id === selectedSubAgent);
    const mainBranchId = getMainBranchId(subAgentData?.branches ?? []);
    form.setFieldsValue({
      subAgentBranch: mainBranchId,
    });
    return subAgentData?.branches
      .map(branch => ({
        label: capitalizeFirstLetter(branch.name),
        value: branch.id,
      })) ?? [];
  }, [selectedSubAgent, subAgents, form]);


  const handleSubAgentChange = async (value: string) => setSelectedSubAgent(value);

  return {
    subAgents,
    isSubAgentsLoading,
    selectedSubAgent,
    setSelectedSubAgent,
    subAgentStatus,
    setSubAgentStatus,
    handleSubAgentChange,
    subAgentBranches,
  };
};
