import React, { useState } from 'react';
import { contactActions, useAppSelector } from '@crm/core';
import { RootState } from 'apps/crm/src/core/store';
import {
  Row,
  Card,
  Col,
  Spin,
  Typography,
  Tooltip,
  Dropdown,
  Button,
  Menu,
  Space,
  Empty
} from 'antd';
import {
  MoreOutlined,
  DownOutlined,
  EditOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import moment from 'moment';
import {
  CONFIRM_MESSAGE,
  DELETE_BTN,
  EDIT_BTN,
  LABEL,
  TEXT
} from '@moxie/constants';
import { capitalizeFirstLetter, popConfirm } from '@moxie/shared';
import { EducationBackgroundDrawer } from './drawer/education-background-drawer';
import { useDispatch } from 'react-redux';
import { ICrmEducation, ISubjectArea } from '@model/crm/education.model';

const { Text } = Typography;

const EducationBackgroundContent: React.FC = () => {
  const educationBgData = useAppSelector(
    (state: RootState) => state?.contact?.educationBackground
  );

  const dataloading = useAppSelector(
    (state: RootState) => state?.contact?.eduBackgroundLoading
  );

  return (
    <>
      <Spin spinning={dataloading}>
        {educationBgData?.length > 0 ? (
          educationBgData?.map((item: ICrmEducation, index: any) => {
            return (
              <Row key={item?.id} className="full-width">
                <EducationBackgroundCard
                  key={index}
                  id={item?.id}
                  item={item}
                />
              </Row>
            );
          })
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={TEXT.NOT_AVAILABLE}
          />
        )}
      </Spin>
    </>
  );
};

const cardMenu = ({
  handleEdit,
  handleDelete,
}: {
  handleEdit: () => void;
  handleDelete: () => void;
}) => {
  return (
    <Menu>
      <Menu.Item
        key={1}
        icon={<EditOutlined />}
        onClick={handleEdit}
        data-testid="crm-editeducationbackground-edit"
      >
        {EDIT_BTN}
      </Menu.Item>
      <Menu.Item
        key={2}
        icon={<DeleteOutlined />}
        danger
        onClick={handleDelete}
        data-testid="crm-deleteeducationbackground-delete"
      >
        {DELETE_BTN}
      </Menu.Item>
    </Menu>
  );
};

const EducationBackgroundCard = ({
  id,
  item,
}: {
  id: string | number;
  item: ICrmEducation;
}) => {
  const dispatch = useDispatch();
  const [expand, setExpand] = useState<boolean>(false);
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);

  const subjectArea = item.discipline?.subjectAreas?.find(
    (data: ISubjectArea) => data.id === item.subjectId
  );

  const socket = useAppSelector((state) => state.socket.wss);
  const user = useAppSelector((state) => state.auth.user);

  const screenWidth = window.innerWidth;

  const handleExpand = (id: string | number, status: boolean) => {
    if (id) {
      setExpand(!status);
    }
  };

  const handleEdit = () => {
    setEditMode(true);
    setShowDrawer(true);
  };

  const handleDelete = () => {
    const dataToBeDeleted = {
      contactId: item?.contactId,
      educationBackgroundId: item?.id,
    };
    popConfirm(
      DELETE_BTN,
      `${CONFIRM_MESSAGE.DELETE_EDUCATION_BACKGROUND} ${item.degreeTitle}`,
      () => {
        dispatch(
          contactActions.deleteContactEducationBackgroundRequest(
            dataToBeDeleted,
            (response) => {
              socket?.emit('server::profile-updated', {
                activitiesTypeId: item?.contactId,
                activitiesType: 'education',
                activitiesAction: 'deleted',
                userId: user?.id,
                contactId: item?.contactId,
                companyId: user?.companyId as string,
                data: response,
              });
            }
          )
        );
      },
      true
    );
  };

  const menuActions = {
    handleEdit: handleEdit,
    handleDelete: handleDelete,
  };

  return (
    <>
      {expand ? (
        <Card className="full-width margin-bottom-2">
          <Card.Grid
            className="full-width education-bg-card-container"
            hoverable={false}
          >
            <Row align="middle" className="edu-bg-line-height">
              <Col span={23}>
                {screenWidth >= 1200 && screenWidth < 1600 ? (
                  String(item?.degreeTitle).length >= 20 ? (
                    <Tooltip title={capitalizeFirstLetter(item?.degreeTitle)}>
                      <Text
                        className="text-bold edu-bg-primary-title initial_capital edu-bg-ellipsis-text"
                        ellipsis
                      >
                        {item?.degreeTitle}
                      </Text>
                    </Tooltip>
                  ) : (
                    <Text className="text-bold edu-bg-primary-title initial_capital">
                      {item?.degreeTitle}
                    </Text>
                  )
                ) : screenWidth >= 1600 &&
                  String(item?.degreeTitle).length >= 27 ? (
                    <Tooltip title={capitalizeFirstLetter(item?.degreeTitle)}>
                      <Text
                        className="text-bold edu-bg-primary-title initial_capital edu-bg-ellipsis-text"
                        ellipsis
                      >
                        {item?.degreeTitle}
                      </Text>
                    </Tooltip>
                  ) : (
                    <Text className="text-bold edu-bg-primary-title initial_capital">
                      {item?.degreeTitle}
                    </Text>
                  )}
              </Col>
              <Col span={1}>
                <Dropdown overlay={cardMenu(menuActions)}>
                  <Button
                    type="text"
                    className="table-cell-action-button edu-bg-icon"
                    icon={<MoreOutlined />}
                  />
                </Dropdown>
              </Col>
            </Row>
            <Row align="middle" className="edu-bg-line-height">
              <Col span={24}>
                <Space direction="horizontal" size={3}>
                  <Text className="edu-bg-sec-title initial_capital">
                    {item?.degreeLevel?.name}
                  </Text>
                  {item?.courseStart ? (
                    <>
                      <Text className="edu-bg-sec-title initial_capital">
                        |
                      </Text>
                      <Text className="edu-bg-sec-title initial_capital">
                        {moment(item.courseStart)
                          .format('MMM YYYY ')
                          .toString()}
                      </Text>
                      <Text className="edu-bg-sec-title initial_capital">
                        -
                      </Text>
                      <Text className="edu-bg-sec-title initial_capital">
                        {moment(item.courseEnd).format('MMM YYYY ').toString()}
                      </Text>
                    </>
                  ) : null}
                </Space>
              </Col>
            </Row>
            <Row align="middle" className="edu-bg-line-height">
              <Col span={6} xl={8} xxl={6}>
                <Text className="edu-bg-label-title">{TEXT.INSTITUTION}</Text>
              </Col>
              <Col span={17} xl={15} xxl={17}>
                {screenWidth >= 1200 && screenWidth < 1600 ? (
                  String(item?.institution).length >= 26 ? (
                    <Tooltip title={capitalizeFirstLetter(item?.institution)}>
                      <Text
                        ellipsis
                        className="edu-bg-value-ellipsis-text initial_capital"
                      >
                        {item?.institution}
                      </Text>
                    </Tooltip>
                  ) : (
                    <Text className="initial_capital">{item?.institution}</Text>
                  )
                ) : screenWidth >= 1600 &&
                  String(item?.institution).length >= 27 ? (
                    <Tooltip title={capitalizeFirstLetter(item?.institution)}>
                      <Text
                        ellipsis
                        className="edu-bg-value-ellipsis-text initial_capital"
                      >
                        {item?.institution}
                      </Text>
                    </Tooltip>
                  ) : (
                    <Text className="initial_capital">{item?.institution}</Text>
                  )}
              </Col>
            </Row>
            {item?.securedGpa || item?.percentage ? (
              <Row align="middle" className="edu-bg-line-height">
                <Col span={6} xl={8} xxl={6}>
                  <Text className="edu-bg-label-title">
                    {item?.securedGpa ? LABEL.GPA : LABEL.PERCENTAGE}
                  </Text>
                </Col>
                <Col span={17} xl={15} xxl={17}>
                  <Text>
                    {item?.securedGpa
                      ? `${item?.securedGpa}/${item?.totalGpa}`
                      : item?.percentage}
                  </Text>
                </Col>
              </Row>
            ) : null}
            {item?.subjectId ? (
              <Row align="middle" className="margin-top-1">
                <Col span={6} xl={8} xxl={6}>
                  <Text className="edu-bg-label-title">
                    {LABEL.SUBJECT_AREA}
                  </Text>
                </Col>
                <Col span={17} xl={15} xxl={17}>
                  {screenWidth >= 1200 && screenWidth < 1600 ? (
                    String(subjectArea?.name).length >= 26 ? (
                      <Tooltip title={subjectArea?.name}>
                        <Text
                          ellipsis
                          className="edu-bg-value-ellipsis-text initial_capital"
                        >
                          {subjectArea?.name}
                        </Text>
                      </Tooltip>
                    ) : (
                      <Text className="initial_capital">
                        {subjectArea?.name}
                      </Text>
                    )
                  ) : screenWidth >= 1600 &&
                    String(subjectArea?.name).length >= 46 ? (
                      <Tooltip title={subjectArea?.name}>
                        <Text
                          ellipsis
                          className="edu-bg-value-ellipsis-text initial_capital"
                        >
                          {subjectArea?.name}
                        </Text>
                      </Tooltip>
                    ) : (
                      <Text className="initial_capital">{subjectArea?.name}</Text>
                    )}
                </Col>
              </Row>
            ) : null}
            {item?.discipline?.id ? (
              <Row align="middle" className="edu-bg-line-height">
                <Col span={23}>
                  <Row align="middle">
                    <Col
                      span={6}
                      xl={8}
                      xxl={6}
                      className="edu-discipline-value"
                    >
                      <Text className="edu-bg-label-title">
                        {TEXT.DISCIPLINE}
                      </Text>
                    </Col>
                    <Col span={17} xl={15} xxl={17}>
                      {screenWidth >= 1200 && screenWidth < 1600 ? (
                        String(item?.discipline?.discipline).length >= 26 ? (
                          <Tooltip title={item?.discipline?.discipline}>
                            <Text
                              ellipsis
                              className="edu-bg-value-ellipsis-text initial_capital"
                            >
                              {item?.discipline?.discipline}
                            </Text>
                          </Tooltip>
                        ) : (
                          <Text className="initial_capital">
                            {item?.discipline?.discipline}
                          </Text>
                        )
                      ) : screenWidth >= 1600 &&
                        String(item?.discipline?.discipline).length >= 46 ? (
                          <Tooltip title={item?.discipline?.discipline}>
                            <Text
                              ellipsis
                              className="edu-bg-value-ellipsis-text initial_capital"
                            >
                              {item?.discipline?.discipline}
                            </Text>
                          </Tooltip>
                        ) : (
                          <Text className="initial_capital">
                            {item?.discipline?.discipline}
                          </Text>
                        )}
                    </Col>
                  </Row>
                </Col>
                {item?.discipline?.id && item?.subjectId ? (
                  <Col span={1}>
                    <DownOutlined
                      className="card-icon-position"
                      rotate={expand ? 180 : 0}
                      onClick={() => handleExpand(id, expand)}
                    />
                  </Col>
                ) : null}
              </Row>
            ) : null}
          </Card.Grid>
        </Card>
      ) : (
        <Card className="full-width margin-bottom-1">
          <Card.Grid
            className="full-width education-bg-card-container"
            hoverable={false}
          >
            <Row align="middle" className="edu-bg-line-height">
              <Col span={23}>
                {screenWidth >= 1200 && screenWidth < 1600 ? (
                  String(item?.degreeTitle).length >= 20 ? (
                    <Tooltip title={capitalizeFirstLetter(item?.degreeTitle)}>
                      <Text
                        className="text-bold edu-bg-primary-title initial_capital edu-bg-ellipsis-text"
                        ellipsis
                      >
                        {item?.degreeTitle}
                      </Text>
                    </Tooltip>
                  ) : (
                    <Text className="text-bold edu-bg-primary-title initial_capital">
                      {item?.degreeTitle}
                    </Text>
                  )
                ) : screenWidth >= 1600 &&
                  String(item?.degreeTitle).length >= 27 ? (
                    <Tooltip title={capitalizeFirstLetter(item?.degreeTitle)}>
                      <Text
                        className="text-bold edu-bg-primary-title initial_capital edu-bg-ellipsis-text"
                        ellipsis
                      >
                        {item?.degreeTitle}
                      </Text>
                    </Tooltip>
                  ) : (
                    <Text className="text-bold edu-bg-primary-title initial_capital">
                      {item?.degreeTitle}
                    </Text>
                  )}
              </Col>
              <Col span={1}>
                <Dropdown overlay={cardMenu(menuActions)}>
                  <Button
                    type="text"
                    className="table-cell-action-button edu-bg-icon"
                    icon={<MoreOutlined />}
                  />
                </Dropdown>
              </Col>
            </Row>
            <Row align="middle" className="edu-bg-line-height">
              <Col span={24}>
                <Space direction="horizontal" size={3}>
                  <Text className="edu-bg-sec-title initial_capital">
                    {item?.degreeLevel?.name}
                  </Text>
                  {item?.courseStart ? (
                    <>
                      <Text className="edu-bg-sec-title initial_capital">
                        |
                      </Text>
                      <Text className="edu-bg-sec-title initial_capital">
                        {moment(item.courseStart)
                          .format('MMM YYYY ')
                          .toString()}
                      </Text>
                      <Text className="edu-bg-sec-title initial_capital">
                        -
                      </Text>
                      <Text className="edu-bg-sec-title initial_capital">
                        {moment(item.courseEnd).format('MMM YYYY ').toString()}
                      </Text>
                    </>
                  ) : null}
                </Space>
              </Col>
            </Row>
            <Row align="middle" className="edu-bg-line-height">
              {!item?.securedGpa && !item?.percentage ? (
                <>
                  <Col span={23}>
                    <Row align="middle">
                      <Col
                        span={6}
                        xl={8}
                        xxl={6}
                        className="edu-discipline-value"
                      >
                        <Text className="edu-bg-label-title">
                          {TEXT.INSTITUTION}
                        </Text>
                      </Col>
                      <Col span={17} xl={15} xxl={17}>
                        {screenWidth >= 1200 && screenWidth < 1600 ? (
                          String(item?.institution).length >= 26 ? (
                            <Tooltip
                              title={capitalizeFirstLetter(item?.institution)}
                            >
                              <Text
                                ellipsis
                                className="edu-bg-value-ellipsis-text initial_capital"
                              >
                                {item?.institution}
                              </Text>
                            </Tooltip>
                          ) : (
                            <Text className="initial_capital">
                              {item?.institution}
                            </Text>
                          )
                        ) : screenWidth >= 1600 &&
                          String(item?.institution).length >= 27 ? (
                            <Tooltip
                              title={capitalizeFirstLetter(item?.institution)}
                            >
                              <Text
                                ellipsis
                                className="edu-bg-value-ellipsis-text initial_capital"
                              >
                                {item?.institution}
                              </Text>
                            </Tooltip>
                          ) : (
                            <Text className="initial_capital">
                              {item?.institution}
                            </Text>
                          )}
                      </Col>
                    </Row>
                  </Col>
                  {item?.discipline?.id && item?.subjectId ? (
                    <Col span={1}>
                      <DownOutlined
                        className="card-icon-position"
                        rotate={expand ? 180 : 0}
                        onClick={() => handleExpand(id, expand)}
                      />
                    </Col>
                  ) : null}
                </>
              ) : (
                <>
                  <Col span={6} xl={8} xxl={6}>
                    <Text className="edu-bg-label-title">
                      {TEXT.INSTITUTION}
                    </Text>
                  </Col>
                  <Col span={17} xl={15} xxl={17}>
                    {String(item?.institution).length >= 27 ? (
                      <Tooltip title={capitalizeFirstLetter(item?.institution)}>
                        <Text
                          ellipsis
                          className="edu-bg-value-ellipsis-text initial_capital"
                        >
                          {item?.institution}
                        </Text>
                      </Tooltip>
                    ) : (
                      <Text className="initial_capital">
                        {item?.institution}
                      </Text>
                    )}
                  </Col>
                </>
              )}
            </Row>
            {item?.securedGpa || item?.percentage ? (
              <Row align="middle" className="edu-bg-line-height">
                <Col span={23}>
                  <Row align="middle">
                    <Col
                      span={6}
                      xl={8}
                      xxl={6}
                      className="edu-discipline-value"
                    >
                      <Text className="edu-bg-label-title">
                        {item?.securedGpa ? LABEL.GPA : LABEL.PERCENTAGE}
                      </Text>
                    </Col>
                    <Col span={17} xl={15} xxl={17}>
                      <Text>
                        {item?.securedGpa
                          ? `${item?.securedGpa}/${item?.totalGpa}`
                          : item?.percentage}
                      </Text>
                    </Col>
                  </Row>
                </Col>
                <Col span={1}>
                  {item?.discipline?.id ? (
                    <DownOutlined
                      className="card-icon-position"
                      rotate={expand ? 180 : 0}
                      onClick={() => handleExpand(id, expand)}
                    />
                  ) : null}
                </Col>
              </Row>
            ) : null}
          </Card.Grid>
        </Card>
      )}
      <EducationBackgroundDrawer
        showDrawer={showDrawer}
        setShowDrawer={setShowDrawer}
        initialData={item}
        editMode={editMode}
        setEditMode={setEditMode}
      />
    </>
  );
};
export { EducationBackgroundContent };
