import { fetchProductFees } from '@crm/libs/services.api/lib/product-fees.api';
import { useInfiniteQuery } from '@tanstack/react-query'
import { IProductFeeCrm } from '../product.model';
import { PaginationMeta } from '@model/api-response.model';

const useProductFees = (productId: string) => {

  const query = useInfiniteQuery<{ data: IProductFeeCrm[], meta: PaginationMeta }>({
    queryKey: ['product-fees', productId],
    queryFn:  (args) => fetchProductFees(productId, args.pageParam),
    getNextPageParam: (lastPage) => {
      return  lastPage.meta.currentPage === lastPage.meta.totalPages ? false : Number(lastPage.meta.currentPage) + 1},
  })
  return {
    fees: query.data?.pages.map(p => p.data).flat() ?? [],
    hasMore: Boolean(query.hasNextPage),
    next: query.fetchNextPage,
    currentPage: Number(query.data?.pages.at(-1)?.meta.currentPage),
    isLoading: query.isLoading,
  }
}

export default useProductFees;
