import React, { useContext, useState } from 'react';
import { CANCEL_BTN, DRAWER_WIDTH, SAVE_BTN, TEXT } from '@moxie/constants';
import { DrawerElem } from '@shared-components/elements';
import { Button, Space } from 'antd';
import ContactExistingDocumentSelector from './ContactExistingDocumentSelector';
import { useUploadExistingDocs } from 'apps/crm/src/pages/application-details/hooks/useDocumentMutation';
import { useAppSelector } from '@crm/core';
import ApplicationDetailContext from 'apps/crm/src/pages/application-details/application-detail-context';
import { Media, UploadExistingDocsPayload } from '@model/document';
import { Checklist } from '@model/workflow';
import { useQueryClient } from '@tanstack/react-query';

interface Props {
  applicationChecklistDocs: Media[];
  isOpen: boolean;
  onClose: () => void;
  contactDocs: Media[];
  isLoadingDocs: boolean;
  selectedChecklist: Checklist | undefined;
  contactId: string;
}
export const ContactExistingDocumentDrawer = ({
  isOpen,
  onClose,
  contactDocs,
  isLoadingDocs,
  applicationChecklistDocs,
  selectedChecklist,
  contactId,
}: Props) => {
  const queryClient = useQueryClient();
  const { selectedStage, applicationData } = useContext(ApplicationDetailContext);
  const applicationId = applicationData.id;

  const [selectedDocs, setSelectedDocs] = useState<Media[]>([]);
  const [selectedDocsRef, setSelectedDocsRef] = useState<React.Key[]>([]);
  const [submitLoading, setSubmitLoading] = useState(false);

  const { applicationData: application } = useContext(ApplicationDetailContext);

  const socket = useAppSelector(state => state.socket.wss);
  const user = useAppSelector(state => state.auth.user);

  const existingDocsMutation = useUploadExistingDocs()

  const handleClose = () => {
    setSelectedDocs([]);
    setSelectedDocsRef([])
    onClose();
  };

  const handleSubmit = () => {
    const documents: UploadExistingDocsPayload[] = selectedDocs.map(doc => ({
      bucketFileName: doc.bucketFileName as string,
      extension: doc.extension,
      mediaType: doc.mediaType,
      mediaTypeCategory: doc.mediaTypeCategory,
      mimetype: doc.mimetype,
      name: doc.name,
      path: doc.path,
      subjectId: applicationId,
      syncId: doc.id,
      size: doc.size,
      stageId: selectedStage.wfAppStageId as string,
    }))

    existingDocsMutation.mutate(documents, {
      async onSuccess() {
        socket?.emit('server::application-updated', {
          activitiesTypeId: applicationId,
          userId: user?.id as string,
          data: {
            data: {
              ...application,
              id: applicationId,
              currentStage: {
                stage: selectedStage.stage,
              },
              documentType: selectedChecklist?.name,
              documents,
            },
          },
          contactId: contactId,
          activitiesAction: 'created',
          activitiesType: 'application-document',
          companyId: user?.companyId as string,
        })
        await queryClient.invalidateQueries({ queryKey: ['stage-documents', selectedChecklist?.id] });
        await queryClient.invalidateQueries({ queryKey: ['application-checklist-docs', application.id, selectedChecklist?.name] });
        await queryClient.invalidateQueries({ queryKey: ['docs-count', application.id] });
      },
    });

    handleClose();
  }

  return (
    <DrawerElem
      title={TEXT.SELECT_EXISTING_DOCUMENTS}
      width={DRAWER_WIDTH}
      onClose={handleClose}
      visible={isOpen}
      closable={!isLoadingDocs}
      footer={
        <div className="padding-bottom-2 text-align-right">
          <Space direction="horizontal">
            <Button
              onClick={handleClose}
              className="lead-margin-right"
              disabled={isLoadingDocs}
            >
              {CANCEL_BTN}
            </Button>
            <Button type="primary" onClick={handleSubmit} disabled={selectedDocs.length === 0}>
              {SAVE_BTN}
            </Button>
          </Space>
        </div>
      }
    >
      <ContactExistingDocumentSelector
        applicationChecklistDocs={applicationChecklistDocs}
        contactDocs={contactDocs}
        isLoading={isLoadingDocs}
        selectedDocsRef={selectedDocsRef}
        selectedDocs={selectedDocs}
        setSelectedDocs={setSelectedDocs}
        setSelectedDocsRef={setSelectedDocsRef}
        submitLoading={submitLoading}
        setSubmitLoading={setSubmitLoading}
      />
    </DrawerElem>
  );
};
