import { useAppSelector } from '@crm/core';
import {
  CANCEL_BTN,
  CONTACT_DETAILS_TITLE,
  UPDATE_BTN
} from '@moxie/constants';
import { DrawerElem } from '@shared-components/elements';
import { Button, Space } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { RootState } from 'apps/crm/src/core/store';
import { EmergencyContactDetailsForm } from 'libs/shared/src/crm-modules/forms/contact-details';
import React, { useState } from 'react';

const EditEmergencyContactInfoDrawer = ({
  showDrawer,
  setShowDrawer,
}: {
  showDrawer: boolean;
  setShowDrawer: any;
}) => {
  const [form] = useForm();
  const initialData = useAppSelector(
    (state: RootState) => state?.contact?.singleData?.emergencyContact
  );

  const [disabled, setDisabled] = useState(true);

  const handleClose = () => {
    setDisabled(true);
    setShowDrawer(false);
    form.resetFields();
  };

  const handleSubmitBtnClick = () => {
    form.submit();
  };

  return (
    <DrawerElem
      title={CONTACT_DETAILS_TITLE.EDIT_EMERGENCY_CONTACT_INFO}
      width={760}
      onClose={handleClose}
      visible={showDrawer}
      footer={
        <div className="padding-bottom-2 text-align-right">
          <Space direction="horizontal">
            <Button onClick={handleClose} className="lead-margin-right">
              {CANCEL_BTN}
            </Button>
            <Button
              onClick={handleSubmitBtnClick}
              type="primary"
              disabled={disabled}
            >
              {UPDATE_BTN}
            </Button>
          </Space>
        </div>
      }
      data-testid="crm-emergencycontactdetails"
    >
      <EmergencyContactDetailsForm
        contactDetails={initialData}
        setDisabled={setDisabled}
        handleClose={handleClose}
        form={form}
      />
    </DrawerElem>
  );
};
export { EditEmergencyContactInfoDrawer };
