import React from 'react';
import * as ReactDOM from 'react-dom';
import App from './pages';
import '@moxie/styles';
import enUS from 'antd/lib/locale/en_US';
import { ConfigProvider } from 'antd';
import { Provider } from 'react-redux';
import { store } from './core';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import QueryDevTool from './query-dev-tool';
import './config/sentry.config';


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry(failureCount, error) {
        return failureCount > 3
      },
      staleTime: 3 * 60 * 1000, // 3 minutes
    },
  },
});

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <QueryDevTool />
    <Provider store={store}>
      <ConfigProvider locale={enUS}>
        <App />
      </ConfigProvider>
    </Provider>
  </QueryClientProvider>,
  document.getElementById('root')
);
