import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

export const InputButtonWrapper = ({
  children,
  onSubmit,
  onCancel,
  readOnly = false,
}: {
  children: ReactNode;
  onSubmit: () => any;
  onCancel?: () => any;
  readOnly?: boolean;
}) => {
  const ref = useRef<HTMLDivElement>(null!);
  const [isFocussed, setIsFocussed] = useState(false);

  const handleSuccess = useCallback(() => {
    onSubmit();
    setIsFocussed(false);
  }, [onSubmit]);

  const handleCancel = useCallback(() => {
    onCancel && onCancel();
    setIsFocussed(false);
  }, [onCancel]);

  useEffect(() => {
    const wrapper = ref.current;
    const inputElements = wrapper.querySelectorAll('input');

    const handleFocus = (e: FocusEvent) => {
      setTimeout(() => {
        setIsFocussed(true);
      }, 200);
    };
    const handleBlur = (e: FocusEvent) => {
      setTimeout(() => {
        setIsFocussed(false);
      }, 200);
    };
    const handlePress = (e: KeyboardEvent) => {
      const activeElement = document.activeElement as HTMLInputElement;
      if (e.key === 'Enter') {
        handleSuccess();
        activeElement?.blur();
      }
      if (e.key === 'Escape') {
        handleCancel();
        activeElement?.blur();
      }
    };

    inputElements.forEach((inputElement) => {
      inputElement?.addEventListener('focus', handleFocus);
      inputElement?.addEventListener('blur', handleBlur);
    });
    wrapper?.addEventListener('keydown', handlePress);

    return () => {
      inputElements.forEach((inputElement) => {
        inputElement?.removeEventListener('focus', handleFocus);
        inputElement?.removeEventListener('blur', handleBlur);
      });

      wrapper?.removeEventListener('keydown', handlePress);
    };
  }, [handleSuccess, handleCancel]);

  return (
    <div ref={ref} id="input-button-wrapper">
      {isFocussed && !readOnly && (
        <div className="button-container">
          <button aria-label="submit" onClick={handleSuccess}>
            <CheckOutlined />
          </button>
          <button aria-label="cancel" onClick={handleCancel}>
            <CloseOutlined />
          </button>
        </div>
      )}
      {children}
    </div>
  );
};
